import React from 'react';
import { Sidebar } from '@fingo/lib/components/layout';
import { Check, DescriptionOutlined, PaidOutlined } from '@mui/icons-material';
import { useSelectedCompany } from '@fingo/lib/hooks';
import { Redirect } from 'react-router-dom';
import { FakeSales } from './App/sales';
import { StepperRegisterForm } from '../components/userActions';

const QuickRegistration = () => {
  const company = useSelectedCompany();
  if (company) {
    return (
      <Redirect to={{
        pathname: '/app/sales/accountable',
        search: '?referrer=quick-registration-logged',
      }}
      />
    );
  }
  return (
    <>
      <StepperRegisterForm />
      <Sidebar
        menuOptions={[
          { label: 'Cuentas por cobrar', path: '', icon: <DescriptionOutlined />, component: <FakeSales />, id: 'quick-sales' },
          { label: 'Anticipa tus facturas', path: ' ', icon: <PaidOutlined />, component: <></>, id: 'quick-anticipate' },
          { label: 'Anticipa tus OC', path: ' ', icon: <Check />, component: <></>, id: 'quick-anticipate-oc' },
        ]}
      />
    </>
  );
};

export default QuickRegistration;
