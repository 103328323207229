import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableBody, TableRow } from '@mui/material';
import FingoTableRow from './FingoTableRow';
import FingoTableLoader from './FingoTableLoader';

const FingoTableBody = (props) => {
  const {
    headers,
    rows,
    setRows,
    loading,
    selectable,
    collapsible,
    page,
    rowsPerPage,
  } = props;

  const noRowsCollspan = useMemo(
    () => ((selectable || collapsible) ? headers.length + 1 : headers.length),
    [selectable, collapsible, headers],
  );
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  if (loading) return <FingoTableLoader />;

  return (
    <TableBody>
      {!rows.length && <TableCell colSpan={noRowsCollspan}>No hay filas</TableCell>}
      {rows.map((row) => (
        <FingoTableRow
          key={row.id}
          headers={headers}
          row={row}
          setRows={setRows}
        />
      ))}
      {emptyRows > 0 && rows.length < rowsPerPage && (
        // TODO: Change height to available paper height
        <TableRow style={{ height: 20 * emptyRows, backgroundColor: 'transparent' }} />
      )}
    </TableBody>
  );
};

FingoTableBody.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string,
      sortable: PropTypes.bool,
      component: PropTypes.func.isRequired,
    }),
  ).isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      selectable: PropTypes.bool,
      selected: PropTypes.bool,
      selectDisabled: PropTypes.bool,
      collapsible: PropTypes.bool,
      open: PropTypes.bool,
      collapseComponent: PropTypes.element,
    }),
  ).isRequired,
  setRows: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  selectable: PropTypes.bool,
  collapsible: PropTypes.bool,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
};

FingoTableBody.defaultProps = {
  selectable: false,
  collapsible: false,
  page: 0,
  rowsPerPage: 10,
};

export default FingoTableBody;
