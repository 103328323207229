import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Collapse, IconButton, TableCell, TableRow } from '@mui/material';
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';

const FingoTableRow = ({ headers, row, setRows }) => {
  const collapseOpenHandler = () => {
    setRows((prevState) => prevState.map((r) => {
      if (r.id === row.id) return { ...r, open: !r.open };

      return r;
    }));
  };

  const selectClickHandler = (event) => {
    setRows((prevState) => prevState.map((r) => {
      if (r.id === row.id) return { ...r, selected: event.target.checked };

      return r;
    }));
  };

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        {(row.collapsible || row.selectable) && (
          <TableCell padding="checkbox">
            {row.selectable && (
              <Checkbox
                color="primary"
                checked={row.selected}
                indeterminate={row.selectDisabled}
                disabled={row.selectDisabled}
                onChange={selectClickHandler}
              />
            )}
            {row.collapsible && (
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={collapseOpenHandler}
              >
                {row.open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              </IconButton>
            )}
          </TableCell>
        )}
        {headers.map(({ id, component }) => <TableCell key={`${row.id}-${id}`}>{component(row)}</TableCell>)}
      </TableRow>
      {row.collapsible && row.open && (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={headers.length + 1}>
            <Collapse in={row.open} timeout="auto" unmountOnExit>
              {row.collapseComponent}
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

FingoTableRow.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string,
      sortable: PropTypes.bool,
      component: PropTypes.func.isRequired,
    }),
  ).isRequired,
  row: PropTypes.shape({
    id: PropTypes.string.isRequired,
    selectable: PropTypes.bool,
    selected: PropTypes.bool,
    selectDisabled: PropTypes.bool,
    collapsible: PropTypes.bool,
    open: PropTypes.bool,
    collapseComponent: PropTypes.element,
  }).isRequired,
  setRows: PropTypes.func.isRequired,
};

export default FingoTableRow;
