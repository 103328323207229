import React from 'react';
import { DOCUMENTS, EXPORT_INVOICES } from '@fingo/lib/graphql';
import { DocumentList } from '@fingo/lib/components/lists';
import { useInvoicePreColumns } from '@fingo/lib/constants';

const MyPurchases = () => (
  <DocumentList
    trackerId="MY_PURCHASES"
    type="my-purchases"
    headerTitle="Cuentas por pagar"
    queryDocument={DOCUMENTS}
    showFilters
    emitter={false}
    includeHeaders={[
      'folio',
      'dteType_Code',
      'dateIssued',
      'company_MasterEntity_Name',
      'company_MasterEntity_Rut',
      'amountWithIva',
      'siiStatus',
      'chat',
    ]}
    mobileHeaders={[
      'folio',
      'company_MasterEntity_Name',
      'amountWithIva',
      'siiStatus',
    ]}
    allCompaniesOptions
    showExportInvoice
    queryExport={EXPORT_INVOICES}
    preColumns={useInvoicePreColumns()}
  />
);

export default MyPurchases;
