import React from 'react';
import PropTypes from 'prop-types';
import { useIsMobile } from '@fingo/lib/hooks';
import { LoadingButton } from '@mui/lab';
import { Button, Grid, Skeleton, Stack, Tooltip, Typography } from '@mui/material';
import { formatMoney } from '@fingo/lib/helpers';

const BaseSummaryCart = ({
  cartType,
  summaryCart,
  cleanFunction,
  principalActionFunction,
  disabledPrincipalAction,
  loadingButton,
  tooltipDisabledTitle,
  loading,
  principalActionTitle,
  principalActionId,
}) => {
  const isMobile = useIsMobile();
  if (loading) return <Skeleton variant="rectangular" width="100%" height={100} />;
  return (
    <Stack spacing={2} width="100%" mt={2}>
      <Grid container rowSpacing={isMobile ? 1 : 2}>
        {summaryCart.map(({ title, value, color, money }) => (
          <React.Fragment key={title}>
            <Grid item xs={8}>
              <Typography
                variant="h6"
                color={color}
                sx={{
                  fontSize: { xs: 12, md: 14 },
                }}
              >{title}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="h6"
                color={color}
                sx={{
                  fontSize: { xs: 12, md: 14 },
                }}
              >
                {money ? `$${formatMoney(value)}` : value}
              </Typography>
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
      <Stack
        width="100%"
        justifyContent="center"
        alignItems="center"
        direction="row"
        spacing={4}
      >
        <Button
          variant="link"
          color="primary"
          onClick={cleanFunction}
          id={`cart-${cartType}-clean`}
        >
          Limpiar
        </Button>
        <Tooltip title={tooltipDisabledTitle}>
          <span>
            <LoadingButton
              variant="contained"
              size="small"
              color="primary"
              onClick={principalActionFunction}
              loading={loadingButton}
              disabled={disabledPrincipalAction}
              id={principalActionId}
            >
              {principalActionTitle}
            </LoadingButton>
          </span>
        </Tooltip>
      </Stack>
    </Stack>
  );
};

BaseSummaryCart.propTypes = {
  cartType: PropTypes.string.isRequired,
  summaryCart: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    value: PropTypes.number,
    color: PropTypes.string.isRequired,
    money: PropTypes.bool.isRequired,
  })).isRequired,
  cleanFunction: PropTypes.func.isRequired,
  principalActionFunction: PropTypes.func.isRequired,
  disabledPrincipalAction: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  loadingButton: PropTypes.bool.isRequired,
  tooltipDisabledTitle: PropTypes.string,
  principalActionTitle: PropTypes.string.isRequired,
  principalActionId: PropTypes.string.isRequired,
};

BaseSummaryCart.defaultProps = {
  tooltipDisabledTitle: '',
};

export default BaseSummaryCart;
