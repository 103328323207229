import React, { useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { FingoPaper } from '@fingo/lib/components/layout';
import { PDFIcon } from '@fingo/lib/assets';
import { FingoSnackBar } from '@fingo/lib/components/snackBars';
import { GET_INVOICE, FETCH_TRACE, INVOICE_URL } from '@fingo/lib/graphql';
import { formatRut, formatMoney, openUrlAndDownload, formatDateWordsWithYear } from '@fingo/lib/helpers';
import { useBooleanState } from '@fingo/lib/hooks';
import CardActions from '@mui/material/CardActions';
import { Grid, Tooltip, Button, Link as MuiLink, Icon, Typography } from '@mui/material';
import { Cached } from '@mui/icons-material';
import TraceStatus from '../../../components/supplierAutomate/traceStatus';
import ProfileCard from '../../../components/supplierAutomate/ProfileCards';
import ProfileTable from '../../../components/supplierAutomate/ProfileTable';
import CessionTable from './CessionTable';
import ChatDialog from '../../../components/supplierAutomate/ChatDialog';

const InvoiceProfile = () => {
  const [snackBarOpen, toggleSnackBar] = useBooleanState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const invoiceId = searchParams.get('invoiceId');
  const [chatInvoice, setChatInvoice] = useState();
  const [chatOpen, toggleChat] = useBooleanState(Boolean(searchParams.get('chatOpen')));
  const { data } = useQuery(
    GET_INVOICE,
    {
      variables: { invoiceId },
      onCompleted: (_data) => {
        setChatInvoice(_data.invoice);
      },
    },
  );
  const [getInvoicePdf, { loading: loadingPdf }] = useMutation(INVOICE_URL, {
    variables: { invoiceId: data?.invoice?.id },
    onCompleted: (_data) => {
      if (_data.invoicePdfUrl && _data.invoicePdfUrl.url) {
        openUrlAndDownload(_data.invoicePdfUrl.url, 'pdf');
      }
    },
    onError: () => {
      toggleSnackBar();
    },
  });
  const [fetchTrace, { loading: loadingTraces }] = useMutation(FETCH_TRACE, {
    onError: () => {},
    refetchQueries: [GET_INVOICE],
    awaitRefetchQueries: true,
  });
  if (!data) { return null; }
  const summaryHeaders = [
    { label: 'Emisor', getValue: (invoice) => invoice.company.masterEntity.name },
    { label: 'Rut', getValue: (invoice) => formatRut(invoice.company.masterEntity.rut) },
    { label: 'Deudor', getValue: (invoice) => invoice.receiver.name },
    { label: 'Rut', getValue: (invoice) => formatRut(invoice.receiver.rut) },
    { label: 'Monto', getValue: (invoice) => `$${formatMoney(invoice.amountWithIva)}` },
    { label: 'Fecha emisión', getValue: (invoice) => `${formatDateWordsWithYear(invoice.dateIssued)}`, lightHeader: true },
    { label: 'Fecha recepción SII',
      getValue: (invoice) => {
        if (invoice.receptionDate) {
          return `${formatDateWordsWithYear(invoice.receptionDate)}`;
        }
        return `${formatDateWordsWithYear(invoice.dateIssued)}`;
      },
    },
    { label: 'Pago', getValue: (invoice) => invoice.paymentMethod },
    { label: 'Comentarios último estado', getValue: (invoice) => (invoice.uniqueDocumentFinanceState.length ? invoice.uniqueDocumentFinanceState[0]?.comments : 'Sin Comentarios') },
  ];
  const referencesHeaders = [
    { label: 'Folio',
      getValue: (reference) => (
        <Link
          component={MuiLink}
          underline="hover"
          to={{
            pathname: '/app/invoice',
            search: `?invoiceId=${reference.id}`,
          }}
          color="primary"
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            '&:hover': {
              color: (theme) => `${theme.palette.primary.main} !important`,
            },
          }}
        >{reference.folio}
        </Link>
      ) },
    { label: 'Tipo', getValue: (reference) => reference.dteType.name },
    { label: 'Monto', getValue: (reference) => `$${formatMoney(reference.amountWithIva)}` },
  ];
  const needsCreditNote = (invoice) => {
    if (!invoice) { return null; }
    const rejected = invoice.siiStatus.includes('REJECTED');
    const totalCredit = invoice.creditNotes.map(
      (cn) => (cn.amountWithIva),
    ).reduce((a, b) => a + b, 0);
    return rejected && totalCredit < invoice.amountWithIva;
  };
  const pdfDisabled = (!data.invoice.company?.hasInvoiceProviderCredentials
    && !data.invoice.receiver?.company?.hasInvoiceProviderCredentials);
  const pdfDisabledTitle = (
    <>
      <Typography variant="body2" component="span">
        El pdf de esta factura no está disponible ya que no tenemos disponibles
        tus credenciales de facturador. Te invitamos a que pinches
      </Typography>
      <Link
        component={MuiLink}
        underline="hover"
        to={{ pathname: '/app/profile' }}
        color="primary"
        target="_blank"
        rel="noopener noreferrer"
        variant="body2"
        sx={{
          '&:hover': {
            color: (theme) => `${theme.palette.primary.main} !important`,
          },
        }}
      >{' '}aquí{' '}
      </Link>
      <Typography variant="body2" component="span">
        para que sincronices con nosotros tus credenciales y puedas vivir
        la full experiencia de Fingo.
      </Typography>
    </>
  );
  return (
    <>
      <FingoSnackBar
        open={snackBarOpen}
        setOpen={toggleSnackBar}
        message="No pudimos cargar el pdf de tu factura. Asegúrate que las credenciales de tu facturador estén correctas en tu perfil, e intente nuevamente"
        severity="error"
      />
      <ChatDialog
        open={chatOpen}
        onClose={toggleChat}
        invoice={chatInvoice}
      />
      <Grid
        justifyContent="center"
        container
        spacing={2}
        sx={() => ({
          py: { xs: 1, sm: 2, md: 3 },
        })}
      >
        <Grid item xs={12} md={6} lg={4}>
          <ProfileCard
            primaryTitle={data.invoice.dteType.name}
            subtitle={`Folio ${data.invoice.folio}`}
            titleActions={{
              id: 'invoice-export-pdf',
              icon: (
                <Icon>
                  <img
                    src={PDFIcon}
                    alt="pdf-icon"
                    style={{
                      height: '100%',
                      ...(pdfDisabled && { filter: 'opacity(0.3)' }),
                    }}
                  />
                </Icon>
              ),
              onClick: getInvoicePdf,
              loading: loadingPdf,
              disabled: pdfDisabled,
              tooltipTitle: pdfDisabled && pdfDisabledTitle,
            }}
          >
            <ProfileTable
              headers={summaryHeaders}
              data={[data.invoice]}
            />
          </ProfileCard>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <ProfileCard primaryTitle="Cesiones">
            <CessionTable
              cessions={data.invoice.cessions}
            />
          </ProfileCard>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <ProfileCard
            primaryTitle="Referencias"
            customActions={(
              <>
                { data && needsCreditNote(data.invoice)
                && (
                <Grid paddingX="40px">
                  <Typography
                    variant="caption"
                    color="text.main"
                  >
                    <span role="img" aria-label="eyes">👀</span> Recuerda <b>emitir la nota de crédito</b> asociada a esta
                    factura y evitar el cobro del iva
                  </Typography>
                </Grid>
                )}
              </>
            )}
          >
            <ProfileTable
              data={[...data.invoice.references, ...data.invoice.reverseReferences]}
              headers={referencesHeaders}
            />
          </ProfileCard>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <ProfileCard
            primaryTitle="Traza"
            titleActions={{
              id: 'invoice-refresh-trace',
              icon: <Cached fontSize="large" />,
              onClick: () => fetchTrace({ variables: { invoiceId: data.invoice.id } }),
              loading: loadingTraces,
            }}
            customActions={data.invoice.receiver.company
               && data.invoice.receiver.company.instructions && (
               <CardActions>
                 <Tooltip title={`Instructivo de ${data.invoice.receiver.name}`}>
                   <Button
                     variant="text"
                     color="primary"
                     onClick={() => (
                       openUrlAndDownload(data.invoice.receiver.company.instructions))}
                     id="invoice-show-instructions"
                   >
                     ¿Problemas con la facturación?
                   </Button>
                 </Tooltip>
               </CardActions>
            )}
          >
            <TraceStatus
              invoice={data.invoice}
            />
          </ProfileCard>
        </Grid>
        <Grid container item xs={12} justifyContent="center">
          <Button
            variant="text"
            color="primary"
            onClick={toggleChat}
            id="chat"
          >
            Ir al chat
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
export default () => (
  <FingoPaper
    menuOptions={[
      { label: 'Documento', path: '', component: <InvoiceProfile /> },
    ]}
  />
);
