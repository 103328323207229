import React from 'react';
import { MobileStepper, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { Stack } from '@mui/system';

const BaseShoppingCart = ({ stepElements, title, id, selected, step }) => (
  <Stack
    sx={{
      boxShadow: 0,
      '&:before': {
        opacity: 0,
      },
      width: '100%',
    }}
  >
    <Stack
      sx={{
        bgcolor: 'background.light',
        '& .MuiAccordionSummary-content': {
          justifyContent: 'space-between',
        },
        py: 3,
        px: 2,
      }}
      id={id}
      direction="row"
      justifyContent="space-between"
    >
      <Typography variant="h6">
        {title}
      </Typography>
      <Typography variant="body2" color="primary">
        {selected} elegidas
      </Typography>
    </Stack>
    <Stack
      sx={{
        py: 2,
        px: 2.5,
      }}
    >
      <MobileStepper
        steps={stepElements.length}
        activeStep={step}
        backButton={<></>}
        nextButton={<></>}
        position="static"
        variant="dots"
        sx={{ justifyContent: 'center' }}
      />
      {stepElements[step]}
    </Stack>
  </Stack>
);

BaseShoppingCart.propTypes = {
  stepElements: PropTypes.arrayOf(PropTypes.element).isRequired,
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  selected: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
};

export default BaseShoppingCart;
