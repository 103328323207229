import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { orderingInvoiceIssuedSelected, orderingSelected } from '@fingo/lib/apollo/reactive-variables';
import { orderingSimulationSelection } from '@fingo/lib/apollo/cache/localQuery';
import { DocumentList } from '@fingo/lib/components/lists';
import { FingoSnackBar } from '@fingo/lib/components/snackBars';
import { ORDERING_PURCHASE_ORDERS } from '@fingo/lib/graphql';
import { DocumentFilters } from '@fingo/lib/components/filters';
import { useBooleanState, useSelectedCompany } from '@fingo/lib/hooks';
import { Button, Typography } from '@mui/material';
import useOrderingPreColumns from '../../../../components/invoice/useOrderingPreColumns';
import UploadPurchaseOrderDialog from '../../../../components/ordering/UploadPurchaseOrderDialog';

const AvailableOrdering = () => {
  const selectedCompany = useSelectedCompany();
  const { search } = useLocation();
  const [snackBarOpen, toggleSnackBar] = useBooleanState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const preColumns = useOrderingPreColumns();
  const searchParams = new URLSearchParams(search);
  const purchaseId = searchParams.get('purchaseOrderId');
  const { data: { selectedOrderingIds } } = useQuery(orderingSimulationSelection);
  const dateToPayOnChange = (row, newValue) => orderingInvoiceIssuedSelected(
    { ...orderingInvoiceIssuedSelected(), [row.id]: newValue },
  );
  const [openUpload, toggleUpload] = useBooleanState(false);
  const { loading } = useQuery(ORDERING_PURCHASE_ORDERS, {
    variables: {
      id_In: [purchaseId],
    },
    skip: !purchaseId,
    onCompleted: (_data) => {
      const purchaseOrder = _data.purchaseOrders.edges[0]?.node;
      if (!purchaseOrder) {
        setSnackBarMessage('No existe esa orden de compra en Fingo');
        toggleSnackBar();
        return;
      }
      if (purchaseOrder.nonAvailableReason) {
        setSnackBarMessage(purchaseOrder.nonAvailableReason);
        toggleSnackBar();
        return;
      }
      orderingSelected([purchaseOrder.id]);
    },
  });
  if (loading) return null;
  return (
    <>
      <DocumentList
        trackerId="ORDERING_PREOFFER"
        type="ordering"
        checkboxSelection
        shoppingCartPreview
        headerTitle="Anticipa tus órdenes de compra"
        queryDocument={ORDERING_PURCHASE_ORDERS}
        emitter
        customVariables={{
          companyId_In: selectedCompany ? [selectedCompany.rut] : null,
          inSimulation: true,
          id_In: purchaseId ? [purchaseId] : null,
        }}
        includeHeaders={[
          'orderNumber',
          'purchaser_Name',
          'publicationDate',
          'totalAmount',
          'orderingsimulation_OrderingPaymentAmount',
          'orderingsimulation_OrderingMonthlyRate',
          'orderingsimulation_OrderingRetentionRate',
          'orderingStatus',
          'orderingInvoiceDate',
        ]}
        mobileHeaders={[
          'orderNumber',
          'purchaser_Name',
          'totalAmount',
          'orderingsimulation_OrderingMonthlyRate',
          'orderingInvoiceDate',
        ]}
        onCompletedSetLength={(data) => data.purchaseOrders.totalCount}
        onCompletedSetRows={(data) => data.purchaseOrders.edges.map((edge) => (edge.node))}
        onSelectionModelChange={(ids) => orderingSelected(ids)}
        selectionModel={selectedOrderingIds}
        orderingDateToPayOnChange={dateToPayOnChange}
        initialOrderBy="-publicationDate"
        isRowSelectable={({ row }) => row.simulationSelectable.selectable}
        keepNonExistentRowsSelected
        preColumns={preColumns}
        noRowsMessage={(
          <>
            <Typography align="center" variant="h6" paragraph>
              No posees órdenes de compra disponibles.
            </Typography>
            <Typography align="center" variant="body1" paragraph>
              ¡Contáctate con nosotros para entender este
              producto y puedas financiar tus OC con Fingo!
            </Typography>
          </>
        )}
        customSummaryElement={(
          <DocumentFilters
            showStatesFilter={false}
            showDatesFilter={false}
            flexEndButtons={(
              <Button
                id="upload-external-purchase-order"
                size="small"
                onClick={toggleUpload}
                color="primary"
                variant="contained"
              >
                Subir tu OC
              </Button>
            )}
          />
        )}
      />
      <UploadPurchaseOrderDialog open={openUpload} onClose={toggleUpload} />
      <FingoSnackBar
        open={snackBarOpen}
        setOpen={toggleSnackBar}
        message={snackBarMessage}
        autoHideDuration={30000}
      />
    </>
  );
};

export default AvailableOrdering;
