import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import FingoDialog from '@fingo/lib/components/dialogs/FingoDialog';
import { ORDERING_PURCHASE_ORDERS, SEND_ORDER_TO_COLLECTION } from '@fingo/lib/graphql';
import { useBooleanState } from '@fingo/lib/hooks';
import { formatMoney } from '@fingo/lib/helpers';
import { LoadingButton } from '@mui/lab';
import { Checkbox, FormControlLabel, Stack, Typography } from '@mui/material';

const OrderingOperationTransferBankDialog = ({ open, toggleOpen, purchaseOrders }) => {
  const [checked, toggleChecked, , setCheckedFalse] = useBooleanState(false);

  const handleClose = () => { setCheckedFalse(); toggleOpen(); };

  const debt = purchaseOrders?.reduce((acc, cur) => acc + (cur.orderingDebt.debt || 0), 0);
  const orderingFinancedAmount = purchaseOrders?.reduce(
    (acc, cur) => acc + (cur.orderingoffer.orderingFinancedAmount || 0),
    0,
  );
  const depositAmount = debt + orderingFinancedAmount;

  // TODO: Adapt mutation to new flow
  const [sendOrderToCollection, { loading }] = useMutation(
    SEND_ORDER_TO_COLLECTION,
    {
      variables: {
        orderIds: purchaseOrders?.map((oc) => oc.id),
      },
      refetchQueries: [ORDERING_PURCHASE_ORDERS],
      awaitRefetchQueries: true,
    },
  );

  return (
    <FingoDialog
      open={open}
      handleClose={handleClose}
      title="Datos bancarios"
      buttons={<></>}
    >
      <Stack width="100%" spacing={1} alignItems="flex-start" justifyContent="center">
        <Typography variant="body2">Nombre: Fingo SPA</Typography>
        <Typography variant="body2">Banco Santander</Typography>
        <Typography variant="body2">Rut: 77.201.556-9</Typography>
        <Typography variant="body2">Número de cuenta: 000076829241</Typography>
        <Typography variant="body2">Email: operaciones@fingo.cl</Typography>
      </Stack>
      <Stack width="100%" spacing={1} alignItems="center" justifyContent="center" mt={3}>
        <Typography variant="h6" fontWeight={600} align="center">Monto a depositar</Typography>
        <Typography variant="h6" fontWeight={600} align="center">
          ${formatMoney(depositAmount)}
        </Typography>
        <FormControlLabel
          label={`Ya realicé la transferencia por $${formatMoney(depositAmount)} a la cuenta solicitada`}
          control={<Checkbox checked={checked} onChange={toggleChecked} />}
        />
        <LoadingButton
          disabled={!checked}
          variant="contained"
          color="primary"
          size="small"
          onClick={sendOrderToCollection}
          loading={loading}
        >
          Confirmar pago
        </LoadingButton>
      </Stack>
    </FingoDialog>
  );
};

OrderingOperationTransferBankDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
  purchaseOrders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      orderingDebt: PropTypes.shape({
        id: PropTypes.string,
        debt: PropTypes.number,
      }),
      orderNumber: PropTypes.string.isRequired,
      orderingoffer: PropTypes.shape({
        id: PropTypes.string.isRequired,
        orderingAmount: PropTypes.number.isRequired,
        orderingRetentionRate: PropTypes.number.isRequired,
        orderingPaymentAmount: PropTypes.number.isRequired,
        orderingCommission: PropTypes.number.isRequired,
        orderingMonthlyRate: PropTypes.number.isRequired,
        orderingInterest: PropTypes.number.isRequired,
        factoringMonthlyRate: PropTypes.number.isRequired,
        factoringDefaultRate: PropTypes.number.isRequired,
        factoringRetentionRate: PropTypes.number.isRequired,
        orderingFinancedAmount: PropTypes.number.isRequired,
        factoringCommission: PropTypes.number.isRequired,
      }),
    }).isRequired,
  ).isRequired,
};

export default OrderingOperationTransferBankDialog;
