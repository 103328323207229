import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useBooleanState, useSelectedCompany } from '@fingo/lib/hooks';
import { Button, Divider, ListItemIcon, ListItemText, Menu, MenuItem, Stack } from '@mui/material';
import { Check, Send } from '@mui/icons-material';
import { orderStatus } from '../../../helpers/orderingStatus';
import OrderingOperationDetailDialog from './dialogs/OrderingOperationDetailDialog';
import AddInvoicesToPurchaseOrdersDialog from './dialogs/AddInvoicesToPurchaseOrdersDialog';
import TransferBankDialog from './dialogs/TransferBankDialog';

const OrderingOperationActionButton = ({ purchaseOrder, showPaymentOption }) => {
  const [openDetail, toggleBoolean] = useBooleanState(false);
  const [openExternalCession, toggleExternalCession] = useBooleanState(false);
  const [openTransfer, toggleTransfer] = useBooleanState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const selectedCompany = useSelectedCompany();
  const PAYABLE_STATUS = [
    orderStatus.PendingCollection,
    orderStatus.PendingInvoices,
  ];

  const menuItemPush = useCallback((runFunction) => () => {
    setAnchorEl(null);
    runFunction();
  }, [setAnchorEl]);

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
        <Button
          variant="containedReverse"
          color="primary"
          size="small"
          onClick={toggleBoolean}
          id="purchaseOrderDetails"
        >
          Detalle
        </Button>
        {showPaymentOption && (
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={({ currentTarget }) => setAnchorEl(currentTarget)}
            id="purchaseOrderPay"
            disabled={!PAYABLE_STATUS.includes(purchaseOrder.status)}
          >
            Pagar
          </Button>
        )}
      </Stack>
      <Menu
        onClose={() => setAnchorEl(null)}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        elevation={1}
        keepMounted={false}
      >
        <MenuItem
          onClick={menuItemPush(toggleExternalCession)}
          id="orderingPayWithInvoice"
        >
          <ListItemIcon>
            <Send fontSize="medium" color="primary" />
          </ListItemIcon>
          <ListItemText>
            Pagar cediendo factura
          </ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={menuItemPush(toggleTransfer)}
          id="orderingPayWithTransfer"
        >
          <ListItemIcon>
            <Check fontSize="medium" color="primary" />
          </ListItemIcon>
          <ListItemText>
            Pagar con transferencia bancaria
          </ListItemText>
        </MenuItem>
      </Menu>
      <AddInvoicesToPurchaseOrdersDialog
        open={openExternalCession}
        setOpen={toggleExternalCession}
        selectedCompany={selectedCompany}
        orderData={purchaseOrder}
      />
      <TransferBankDialog
        open={openTransfer}
        closeSelf={toggleTransfer}
        purchaseOrder={purchaseOrder}
      />
      <OrderingOperationDetailDialog
        purchaseOrder={purchaseOrder}
        open={openDetail}
        onClose={toggleBoolean}
      />
    </>
  );
};

OrderingOperationActionButton.propTypes = {
  purchaseOrder: PropTypes.shape({
    status: PropTypes.string.isRequired,
  }).isRequired,
  showPaymentOption: PropTypes.bool,
};
OrderingOperationActionButton.defaultProps = {
  showPaymentOption: true,
};

export default OrderingOperationActionButton;
