export const calculateInvoiceAmounts = (invoiceData) => {
  const { days, retentionRate, monthlyRate, amountWithIva } = invoiceData;
  const effectiveRate = (monthlyRate / 100) * (days / 30);
  const retentionAmount = parseInt((retentionRate / 100) * amountWithIva, 10);
  const advanceAmount = amountWithIva - retentionAmount;
  const amountMinusInterests = parseInt(advanceAmount * (1 - effectiveRate), 10);
  const priceDifference = advanceAmount - amountMinusInterests;
  return { effectiveRate, retentionAmount, advanceAmount, amountMinusInterests, priceDifference };
};

export const calculateCommission = (commissionRules, accumulatedAmountWithIva) => {
  if (accumulatedAmountWithIva <= 0) return 0;
  const fixed = commissionRules.filter((rule) => rule.variant === 'FIXED');
  if (fixed.length > 0) {
    return fixed[0].value * 1.19; // Consider VAT
  }
  const percentage = commissionRules.filter((rule) => rule.variant === 'PERCENTAGE');
  let commission = 0;
  if (percentage.length > 0) {
    commission = percentage[0].value * accumulatedAmountWithIva;
  }
  const mins = commissionRules.filter((rule) => rule.variant === 'MIN').sort((a, b) => b.threshold - a.threshold);
  for (let i = 0; i < mins.length; i += 1) {
    const min = mins[i];
    if (accumulatedAmountWithIva >= min.threshold) {
      commission = Math.max(commission, min.value);
      break;
    }
  }
  const maxes = commissionRules.filter((rule) => rule.variant === 'MAX').sort((a, b) => a.threshold - b.threshold);
  for (let i = 0; i < maxes.length; i += 1) {
    const max = maxes[i];
    if (accumulatedAmountWithIva <= max.threshold) {
      commission = Math.min(commission, max.value);
      break;
    }
  }
  return commission * 1.19;
};

export const calculateDealOffersTotals = (
  offers,
  debtorsCreditToUse,
  commissionRules,
  negativeBalance,
) => {
  let newDebtorsCredit = {};
  let accumulatedCompanyCreditUsed = 0;
  let accumulatedAmountWithIvaFactoring = 0;
  let accumulatedAmountWithIva = 0;
  let interestAmount = 0;
  let rateSum = 0;
  let accumulatedAdvanceAmount = 0;
  let accumulatedRetentionAmount = 0;
  let accumulatedPriceDifference = 0;
  let noRatificationAmount = 0;
  let withRatificationAmount = 0;
  offers.forEach((offer) => {
    const {
      amountWithIva,
      amounts: {
        amountMinusInterests,
        advanceAmount,
        retentionAmount,
        priceDifference,
      },
      offer: {
        monthlyRate,
        transferCondition,
      },
      nameReceiver,
    } = offer;
    if (transferCondition !== 'WHITHOUT_RATIFICATION_CLIENT') {
      newDebtorsCredit = {
        ...newDebtorsCredit, [nameReceiver]: (newDebtorsCredit[nameReceiver] || 0) + amountWithIva,
      };
    } else {
      accumulatedCompanyCreditUsed += amountWithIva;
    }
    if (transferCondition === 'WITH_RATIFICATION') {
      withRatificationAmount += amountMinusInterests;
    } else {
      noRatificationAmount += amountMinusInterests;
    }
    accumulatedAmountWithIvaFactoring += offer.confirming ? 0 : amountWithIva;
    accumulatedAmountWithIva += amountWithIva;
    accumulatedAdvanceAmount += advanceAmount;
    accumulatedRetentionAmount += retentionAmount;
    interestAmount += amountMinusInterests;
    accumulatedPriceDifference += priceDifference;
    rateSum += (monthlyRate) * advanceAmount;
  });
  const commission = calculateCommission(commissionRules, accumulatedAmountWithIvaFactoring);
  const averageRate = rateSum / accumulatedAdvanceAmount;
  const approvedAmount = interestAmount - commission - negativeBalance;
  if (noRatificationAmount > commission) {
    noRatificationAmount -= commission;
  } else {
    withRatificationAmount -= commission;
  }
  return {
    commission,
    interestAmount,
    averageRate,
    approvedAmount,
    accumulatedAmountWithIva,
    accumulatedAdvanceAmount,
    accumulatedRetentionAmount,
    accumulatedPriceDifference,
    newDebtorsCredit,
    accumulatedCompanyCreditUsed,
    noRatificationAmount,
    withRatificationAmount,
  };
};

export const calculateDealPreOffersTotals = (preOffers, selectedCompany) => {
  let accumulatedAmountWithIva = 0;
  let rateSum = 0;
  let advanceSum = 0;
  let interestAmountSum = 0;
  let finalPriceEstimate = 0;
  let retentionSum = 0;
  preOffers.forEach((preOffer) => {
    const { amountWithIva, dateExpiration, preoffer: { monthlyRate, retentionRate } } = preOffer;
    accumulatedAmountWithIva += amountWithIva;
    rateSum += (monthlyRate) * amountWithIva;
    advanceSum += (100 - retentionRate) * amountWithIva;
    const days = Math.ceil((dateExpiration - Date.now()) / (1000 * 3600 * 24));
    const retention = Math.trunc(amountWithIva * (retentionRate / 100));
    retentionSum += retention;
    const interestAmount = Math.trunc(
      (amountWithIva - retention) * ((monthlyRate) / 100) * (days / 30),
    );
    interestAmountSum += interestAmount;
    finalPriceEstimate += amountWithIva - retention - interestAmount;
  });
  const averageRate = rateSum / accumulatedAmountWithIva;
  const averageAdvancePercentage = advanceSum / accumulatedAmountWithIva;
  const commission = calculateCommission(
    selectedCompany.currentCommissionRules,
    accumulatedAmountWithIva,
  );
  finalPriceEstimate -= commission;
  return {
    averageRate: averageRate || 0,
    accumulatedAmountWithIva,
    averageAdvancePercentage: averageAdvancePercentage || 0,
    interestAmountSum,
    finalPriceEstimate,
    retentionSum,
    commission,
    advanceSum: advanceSum / 100,
  };
};

export const invoicesMoneyAmountReducer = (acc, invoice) => acc + invoice.amountWithIva;

export const invoicesDebtReducer = (acc, invoice) => acc + invoice.debt;

export const invoicesRetentionReducer = (acc, invoice) => acc + invoice.retentionAmount;

export const invoicesSurplusReducer = (acc, invoice) => acc + invoice.surplus;
