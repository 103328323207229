import { useCallback, useEffect } from 'react';
import { useTracking } from 'react-tracking';

// eslint-disable-next-line react/prop-types
const ButtonTrackerApp = ({ children: originalChildren }) => {
  const { trackEvent } = useTracking();
  const handleButtonClick = useCallback((pointerEvent) => {
    const button = pointerEvent.path.find((path) => {
      const buttonClass = 'MuiButtonBase-root';
      return path.classList?.contains(buttonClass);
    });
    if (button?.id) {
      trackEvent({
        action: 'CLICK',
        button: button.id,
      });
    }
  }, [trackEvent]);
  useEffect(() => {
    window.addEventListener('click', handleButtonClick, false);
    return () => window.removeEventListener('click', handleButtonClick, false);
  }, [handleButtonClick]);
  return originalChildren;
};

export default ButtonTrackerApp;
