import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useMutation } from '@apollo/client';
import { PaperHeader } from '@fingo/lib/components/headers';
import { FingoPaper } from '@fingo/lib/components/layout';
import { useFilteredQuery } from '@fingo/lib/hooks';
import { NOTIFICATION_LIST, READ_NOTIFICATIONS } from '@fingo/lib/graphql';
import { IconButton, Tooltip } from '@mui/material';
import { AccountBalance, Chat, Computer, ErrorOutline, EventNote, Info, NewReleases, Today } from '@mui/icons-material';
import { Table } from '../../../components/tables';
import NotificationText from './NotificationText';
import NotificationFilters from './NotificationFilters';

const headers = [
  { id: 'level', label: 'Tipo', type: 'string', align: 'center', isSearchable: true, mobile: true },
  { id: 'message', label: 'Contenido', type: 'string', align: 'left', isSearchable: true, mobile: true },
];

function NotificationList() {
  const [isReadButtonSelected, setIsReadButtonSelected] = useState(false);
  const [dates, setDates] = useState({
    dateIssued_Gte: moment().subtract(1, 'months'),
    dateIssued_Lte: moment(),
  });
  const history = useHistory();
  const [page, setPage] = useState(0);
  const [rowNumber] = useState(50);
  const { usableData: data, loading } = useFilteredQuery(
    NOTIFICATION_LIST,
    {
      variables: {
        first: rowNumber,
        offset: page * rowNumber,
        unread: isReadButtonSelected,
        timestamp_Lte: dates.dateIssued_Lte,
        timestamp_Gte: dates.dateIssued_Gte,
      },
      notifyOnNetworkStatusChange: true,
    },
  );
  const [markAllAsRead] = useMutation(
    READ_NOTIFICATIONS,
  );
  const typeButton = useCallback((text, Icon, node) => (
    <Tooltip title={text}>
      <IconButton
        onClick={() => {
          history.push({
            pathname: node.redirectionPath,
            search: node.redirectionSearch });
          markAllAsRead({ variables: { notificationIds: [node.id] } });
        }}
        size="large"
      >
        <Icon color={node.unread ? 'primary' : 'disabled'} />
      </IconButton>
    </Tooltip>
  ), [history, markAllAsRead]);
  const TypeIcons = useCallback((node) => ({
    INFO: typeButton('Notificación informativa', Info, node),
    SYSTEM: typeButton('Notificación de Sistema', Computer, node),
    MENTION: typeButton('Notificación de mención el chat', Chat, node),
    CESSION: typeButton('Notificación de factura cedida', AccountBalance, node),
    STATUS_CHANGE: typeButton('Notificación de cambio de estado', NewReleases, node),
    EXPIRATION: typeButton('Notificación de vencimiento', EventNote, node),
    EXECUTIVE_MERIT: typeButton('Notificación de mérito ejecutivo', Today, node),
    REJECTION: typeButton('Notificación de rechazo', ErrorOutline, node),
  }), [typeButton]);
  const markEveryNotificationAsRead = () => {
    markAllAsRead({
      variables: { notificationIds: [] },
      refetchQueries: [{ query: NOTIFICATION_LIST }] });
  };
  const dataToRows = useCallback((_data) => _data.map((notification) => {
    const { node } = notification;
    return ({
      id: node.id,
      level: {
        size: 'small',
        align: 'center',
        value: node.level,
        component: (
          <>
            {TypeIcons(node)[node.level]}
          </>
        ),
      },
      message: {
        size: 'small',
        align: 'left',
        value: node.timestamp,
        component: (
          <NotificationText notificationObject={node} />
        ),
      },
    });
  }), [TypeIcons]);
  if (!data) return null;
  return (
    <>
      <PaperHeader
        viewTitle="Notificaciones"
        endComponent={(
          <NotificationFilters
            dates={dates}
            setDates={setDates}
            isReadButtonSelected={isReadButtonSelected}
            setIsReadButtonSelected={setIsReadButtonSelected}
            markAllAsRead={markEveryNotificationAsRead}
          />
)}
      />
      <Table
        headers={headers}
        data={data.getNotifications.edges}
        dataToRows={dataToRows}
        controlled={{
          isControlled: true,
          totalPages: data.getNotifications.totalPages,
          page,
          setPage,
        }}
        loading={loading}
      />
    </>
  );
}

export default () => (
  <FingoPaper
    menuOptions={[
      { label: 'Notificaciones', path: '', component: <NotificationList /> },
    ]}
  />
);
