import React from 'react';
import PropTypes from 'prop-types';
import { Box, Stack, Typography } from '@mui/material';

const OrderingOperationPaymentSummary = ({ purchaseOrder }) => {
  // eslint-disable-next-line no-console
  console.log(purchaseOrder);
  return (
    <Box sx={{ margin: 3, ml: 7, mr: 7, bgColor: 'white' }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="body2">Pagado con: <strong>Transferencia bancaria</strong></Typography>
        <Typography variant="body2">Estado: <strong>En revisión</strong></Typography>
      </Stack>
      <h3>Acá va una tabla con las facturas asociadas si el pago es con facturas</h3>
    </Box>
  );
};

OrderingOperationPaymentSummary.propTypes = {
  purchaseOrder: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default OrderingOperationPaymentSummary;
