import { useMutation } from '@apollo/client';
import { token } from '../apollo/reactive-variables';
import { USER_INTERACTION_REGISTER } from '../graphql/user';

const useReactTrackingLayer = () => {
  const [registerAction] = useMutation(USER_INTERACTION_REGISTER, {
    onError: () => {},
  });
  const dispatcher = (trackingEvent) => {
    const {
      button,
      action,
      route,
      searchParams,
      company,
      isMobile,
    } = trackingEvent;
    if (token()) {
      registerAction({
        variables: {
          action: {
            route,
            searchParams,
            company: company?.rut,
            isMobile,
            action,
            button,
          },
        },
      });
    }
  };
  return dispatcher;
};

export default useReactTrackingLayer;
