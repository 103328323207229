import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import {
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
} from '@mui/material';

const useStyles = makeStyles(() => ({
  select: {
    paddingTop: 14,
    paddingBottom: 14,
  },
}));

const SelectBiller = ({
  onChange,
  error,
  style,
  invoiceProviderList,
  actualInvoiceProvider,
  disable,
}) => {
  const classes = useStyles();
  const [selectedValue, setSelectedValue] = useState('choose');
  const [invoiceProviderListOrder, setInvoiceProviderListOrder] = useState(invoiceProviderList);
  const handleChange = (e) => {
    const { value } = e.target;
    setSelectedValue(value);
    onChange(e);
  };

  useEffect(() => {
    setInvoiceProviderListOrder(invoiceProviderList.filter((ip) => ip.value !== '')
      // eslint-disable-next-line
      .sort((a, _) => {
        if (a.integrated) return -1;
        return 1;
      }));
  }, [invoiceProviderList]);

  useEffect(() => {
    if (actualInvoiceProvider) {
      setSelectedValue(actualInvoiceProvider.provider);
    }
  }, [actualInvoiceProvider]);

  return (
    <FormControl variant="outlined" fullWidth error={Boolean(error)}>
      <Select
        classes={{
          select: classes.select,
        }}
        type="select"
        name="invoiceProvider"
        value={selectedValue}
        onChange={handleChange}
        error={Boolean(error)}
        helperText={error}
        style={style}
        disabled={disable}
      >
        <MenuItem value="choose" disabled>Elige tu facturador</MenuItem>
        {invoiceProviderListOrder.map((invoiceProvider) => (
          <MenuItem
            key={invoiceProvider.value}
            value={invoiceProvider.value}
          >
            {invoiceProvider.label}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );
};

SelectBiller.propTypes = {
  onChange: PropTypes.func.isRequired,
  error: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  invoiceProviderList: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
    integrated: PropTypes.bool,
  })).isRequired,
  actualInvoiceProvider: PropTypes.string,
  disable: PropTypes.bool,
};

SelectBiller.defaultProps = {
  error: '',
  style: { width: '100%' },
  actualInvoiceProvider: '',
  disable: false,
};

export default SelectBiller;
