import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import StepperDialog from '@fingo/lib/components/dialogs/StepperDialog';
import { GET_OFFERS } from '@fingo/lib/graphql';
import { formatMoney, toPercentage } from '@fingo/lib/helpers';
import AddInvoicesToPurchaseOrdersDialogStepOne from './AddInvoicesToPurchaseOrdersDialogStepOne';
import AddInvoicesToPurchaseOrdersDialogStepTwo from './AddInvoicesToPurchaseOrdersDialogStepTwo';

const AddInvoicesToPurchaseOrdersDialog = ({ open, setOpen, purchaseOrders, companyRut }) => {
  const [invoices, setInvoices] = useState([]);
  const [selectedInvoiceIds, setSelectedInvoiceIds] = useState([]);

  const { loading } = useQuery(
    GET_OFFERS,
    {
      variables: { ruts: companyRut },
      onCompleted: (data) => { setInvoices(data.offers || []); },
    },
  );

  const selectedInvoices = useMemo(
    () => invoices.filter((invoice) => selectedInvoiceIds.includes(invoice.id)),
    [selectedInvoiceIds],
  );

  const totalAmountToPay = purchaseOrders?.reduce((acc, cur) => acc + (cur.totalAmount || 0), 0);

  const currentAmountToPay = selectedInvoices.reduce(
    (acc, cur) => acc + (cur.totalAmount || 0),
    0,
  );

  const statistics = [
    {
      label: 'Cantidad de OCs',
      value: purchaseOrders?.length,
    },
    {
      label: 'Monto total',
      value: `$${formatMoney(totalAmountToPay)}`,
    },
    {
      label: 'Monto acumulado',
      value: `$${formatMoney(currentAmountToPay)}`,
    },
    {
      label: 'Monto restante',
      value: `$${formatMoney(totalAmountToPay - currentAmountToPay)}`,
    },
    {
      label: 'Porcentaje restante',
      value: toPercentage(1 - (currentAmountToPay / totalAmountToPay)),
      color: 'primary.main',
    },
  ];

  const steps = [
    {
      title: 'Selecciona tus facturas disponibles que quieres usar para pagar tus OCs',
      component: (
        <AddInvoicesToPurchaseOrdersDialogStepOne
          statistics={statistics}
          invoices={invoices}
          loading={loading}
          selectedInvoiceIds={selectedInvoiceIds}
          setSelectedInvoiceIds={setSelectedInvoiceIds}
        />
      ),
      backButton: {
        label: 'Cancelar',
        handler: () => {},
        disabled: false,
      },
      nextButton: {
        label: 'Siguiente',
        handler: () => {},
        disabled: !selectedInvoiceIds.length || totalAmountToPay !== currentAmountToPay,
      },
    },
    {
      title: 'Detalle simulación pago OCs con facturas',
      component: (
        <AddInvoicesToPurchaseOrdersDialogStepTwo
          purchaseOrders={purchaseOrders}
          statistics={statistics}
        />
      ),
      backButton: {
        label: 'Atrás',
        handler: () => {},
        disabled: false,
      },
      nextButton: {
        label: 'Aceptar operación',
        handler: () => {},
        disabled: false,
      },
    },
  ];

  return <StepperDialog open={open} setOpen={setOpen} steps={steps} />;
};

AddInvoicesToPurchaseOrdersDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  purchaseOrders: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  companyRut: PropTypes.number.isRequired,
};

export default AddInvoicesToPurchaseOrdersDialog;
