import { Grid, Icon, Paper, Stack, Typography } from '@mui/material';
import React, { useRef } from 'react';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { useMidPosition } from '@fingo/lib/hooks';
import { Rose, HomeBackground } from '@fingo/lib/assets';
import PropTypes from 'prop-types';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined';
import { Link } from 'react-router-dom';
import LoadSrcBefore from '../../components/utils/LoadSrcBefore';

const HomeCard = ({ CardIcon, title, text, link }) => (
  <Grid
    item
    xs={12}
    lg={3.8}
    sx={{
      zIndex: 4,
      textDecoration: 'none',
    }}
    component={Link}
    to={link}
  >
    <Paper sx={{ borderRadius: 4, height: '100%' }}>
      <Stack p={4} spacing={2}>
        <CardIcon
          fontSize="large"
          sx={{
            borderRadius: 10000,
            p: 1,
            backgroundColor: 'text.main',
            color: 'background.light',
            alignSelf: 'center',
          }}
        />
        <Typography variant="h5" align="center">
          {title}
        </Typography>
        <Typography variant="body1" align="center">
          {text}
        </Typography>
      </Stack>
    </Paper>
  </Grid>
);
HomeCard.propTypes = {
  CardIcon: PropTypes.shape({
    $$typeof: PropTypes.symbol.isRequired,
  }).isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};
const cards = [
  {
    id: 'home-card-factoring',
    title: 'Anticipar facturas',
    text: 'Adelanta el pago de tus facturas en menos de 24 horas',
    link: '/app/sales/factoring',
    CardIcon: DescriptionOutlinedIcon,
  },
  {
    id: 'home-card-ordering',
    title: 'Anticipar órdenes de compra',
    text: 'Adelanta el pago de tus órdenes de compra en menos de un día',
    link: '/app/sales/ordering',
    CardIcon: HandshakeOutlinedIcon,
  },
  {
    id: 'home-card-purchases',
    title: 'Gestiona tus compras a través de Fingo',
    text: 'Revisa tu facturación recibida, en un solo lugar',
    link: '/app/purchases',
    CardIcon: EngineeringOutlinedIcon,
  },
];
const Home = () => {
  const paperRef = useRef(null);
  const iconRef = useRef(null);
  const midStackPosition = useMidPosition(paperRef);
  return (
    <>
      <Icon
        ref={iconRef}
        sx={{
          position: 'absolute',
          top: midStackPosition - 50,
          width: '100%',
          height: 'fit-content',
          display: 'flex',
          alignItems: 'center',
          zIndex: 1,
        }}
      >
        <img src={Rose} alt="rose" style={{ width: '100%' }} />
      </Icon>
      <Grid
        container
        minHeight="100%"
        p={8}
        justifyContent="center"
        sx={{
          backgroundImage: `url(${HomeBackground})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <Stack
          ref={paperRef}
          bgcolor="background.dark"
          borderRadius={4}
          py={6}
          sx={(theme) => ({
            px: 16,
            maxHeight: 450,
            [theme.breakpoints.down('lg')]: {
              px: 10,
              maxHeight: 'inherit',
            },
            [theme.breakpoints.down('md')]: {
              px: 4,
              maxHeight: 'inherit',
            },
            [theme.breakpoints.down('sm')]: {
              px: 2,
              maxHeight: 'inherit',
            },
          })}
          spacing={2}
          width="100%"
          maxWidth={1085}
        >
          <Typography
            width="100%"
            align="center"
            variant="h1"
            color="primary"
          >
            Bienvenidos
          </Typography>
          <Typography
            width="100%"
            align="center"
            variant="h2"
          >
            Selecciona la operación que deseas realizar
          </Typography>
          <Grid container alignItems="center" height="100%">
            <Grid
              container
              item
              justifyContent="space-between"
              rowSpacing={4}
            >
              {cards.map((card) => <HomeCard {...card} key={card.title} id={card.id} />)}
            </Grid>
          </Grid>
        </Stack>
      </Grid>
    </>
  );
};

export default () => (
  <LoadSrcBefore src={HomeBackground}>
    <Home />
  </LoadSrcBefore>
);
