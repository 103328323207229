import React, { useState } from 'react';
import {
  Typography,
  TextField,
  Button,
  CircularProgress,
  Grid,
} from '@mui/material';
import PropTypes from 'prop-types';
import { CheckCircle, InsertDriveFileOutlined, Publish } from '@mui/icons-material';
import makeStyles from '@mui/styles/makeStyles';
import { useMutation, useQuery } from '@apollo/client';
import { GET_USER, UPLOAD_CERTIFICATE } from '@fingo/lib/graphql';
import AlertOneDialog from '@fingo/lib/components/dialogs/AlertOneDialog';
import { OrderingCredentialsConfirm } from '../dialogs';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: 'auto',
    padding: '10%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    marginBottom: '1rem',
    fontWeight: 500,
  },
  certificateMessage: {
    marginBottom: '1rem',
  },
  fieldContainer: {
    marginBottom: '2rem',
  },
  label: {
    fontWeight: 500,
    marginBottom: '0.5rem',
  },
  input: {
    display: 'none',
  },
  uploadButton: {
    display: 'flex',
    justifyContent: 'space-between',
    color: theme.palette.text.secondary,
  },
  uploadStatus: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '0.5rem',
  },
  checkIcon: {
    color: theme.palette.success.main,
    marginRight: 12,
    fontSize: 16,
  },
  icon: {
    fontSize: '5rem',
    marginBottom: '1rem',
  },
}));

const INITIAL_CERTIFICATE_VALUES = {
  file: null,
  password: '',
};
const UploadCertificateForm = ({ showImage }) => {
  const classes = useStyles();
  const [uploadCertificate] = useMutation(
    UPLOAD_CERTIFICATE,
    { refetchQueries: [GET_USER], awaitRefetchQueries: true },
  );
  const [hasCertificate, setHasCertificate] = useState(false);
  const [certificate, setCertificate] = useState(INITIAL_CERTIFICATE_VALUES);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [formError, setFormError] = useState('');
  const [success, setSuccess] = useState(false);
  const [orderingConfirmOpen, setOrderingConfirmOpen] = useState(false);
  const [orderingConfirmShown, _setOrderingConfirmShown] = useState(false);
  const { data: { getUser: user } } = useQuery(GET_USER, {
    onCompleted: (data) => {
      setHasCertificate(
        data.getUser.selectedCompany.hasDigitalCertificate
        && data.getUser.selectedCompany.hasCertificateCredentials,
      );
    },
  });

  const handleCertificatePassword = (e) => {
    const { value } = e.target;
    setCertificate((oldCertificate) => (
      { ...oldCertificate, password: value }
    ));
  };

  const handleCertificateFile = (e) => {
    const { files } = e.target;
    setCertificate((oldCertificate) => (
      { ...oldCertificate, file: files[0] }
    ));
  };

  const handleAccept = async () => {
    if (!certificate.file) {
      setFormError('Debes subir tu certificado');
      return;
    }
    if (!certificate.password) {
      setFormError('La contraseña del certificado no puede estar vacía');
      return;
    }
    if (user.selectedCompany.isOrdering && !orderingConfirmShown) {
      setOrderingConfirmOpen(true);
      return;
    }
    setFormError('');
    setSuccess(false);
    setUploadLoading(true);
    try {
      await uploadCertificate({
        variables: {
          companyRut: user.selectedCompany.rut,
          certificatePassword: certificate.password,
          digitalCertificate: certificate.file,
          isOrdering: true,
        },
      });
      setUploadLoading(false);
      setSuccess(true);
      setCertificate(INITIAL_CERTIFICATE_VALUES);
    } catch (_error) {
      if (_error.message === 'uploaded Digital Certificate is not valid') {
        setFormError(`Certificado digital o contraseña del certificado incorrecta.
        Por favor verifique que el archivo subido es un certificado válido vigente o
        vuelva a intentar con una contraseña correcta`);
      } else setFormError('No pudimos subir tu certificado digital');
      setUploadLoading(false);
    }
  };

  const setOrderingConfirmShown = (orderingConfirmShownValue) => {
    _setOrderingConfirmShown(orderingConfirmShownValue);
    if (orderingConfirmShownValue) {
      handleAccept();
    }
  };

  const hasCertificateText = 'Ya tienes un certificado digital en nuestra plataforma, si deseas cambiarlo puedes subir uno nuevo';
  const doesNotHaveCertificateText = 'Todavía no tienes un certificado digital en nuestra plataforma, o el que tienes está sin su contraseña respectiva.';

  return (
    <Grid item xs={12} className={classes.container}>
      <div className={classes.form}>
        {showImage && (
          <InsertDriveFileOutlined className={classes.icon} />
        )}
        <Typography variant="h4" className={classes.title}>Administra tu certificado digital</Typography>
        <>
          {hasCertificate ? (
            <Typography className={classes.certificateMessage}>
              {hasCertificateText}
            </Typography>
          ) : (
            <Typography className={classes.certificateMessage}>
              {doesNotHaveCertificateText}
            </Typography>
          )}
        </>

        <div>
          <div className={classes.fieldContainer}>
            <Typography className={classes.label}>Sube tu certificado digital</Typography>
            <label htmlFor="contained-button-file">
              <input
                accept=".pem, .p12, .pfx"
                className={classes.input}
                id="contained-button-file"
                type="file"
                onChange={handleCertificateFile}
              />
              <Button
                fullWidth
                className={classes.uploadButton}
                variant="outlined"
                component="span"
                endIcon={
                  <Publish color="primary" />
                }
              >
                certificado_digital.cert
              </Button>
            </label>
            {certificate.file && (
              <div className={classes.uploadStatus}>
                <CheckCircle className={classes.checkIcon} />
                <Typography variant="caption">{certificate.file.name}</Typography>
              </div>
            )}
          </div>
          <div className={classes.fieldContainer}>
            <Typography className={classes.label}>Ingresa la clave del certificado</Typography>
            <TextField
              fullWidth
              variant="outlined"
              onChange={handleCertificatePassword}
              value={certificate.password}
              type="password"
            />
          </div>
          {Boolean(formError) && (
            <Typography
              variant="caption"
              component="div"
              color="error"
              align="center"
              gutterBottom
            >
              {formError}
            </Typography>
          )}
          <AlertOneDialog
            open={success}
            onClose={() => setSuccess(false)}
            message="Recibimos tu certificado digital, ya puedes financiar tus facturas"
            title="Certificado recibido con éxito"
          />
          <div>
            <Button
              className={classes.button}
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleAccept}
              disabled={uploadLoading}
              endIcon={uploadLoading ? <CircularProgress size={20} /> : null}
              id="save-certificate"
            >
              Guardar certificado
            </Button>
          </div>
          {user.selectedCompany.isOrdering
            && (
              <OrderingCredentialsConfirm
                open={orderingConfirmOpen}
                onClose={() => {
                  setOrderingConfirmOpen(false);
                }}
                onAccept={() => {
                  setOrderingConfirmOpen(false);
                  setOrderingConfirmShown(true);
                }}
              />
            )}
        </div>
      </div>
    </Grid>
  );
};

UploadCertificateForm.propTypes = {
  showImage: PropTypes.bool,
};
UploadCertificateForm.defaultProps = {
  showImage: true,
};

export default UploadCertificateForm;
