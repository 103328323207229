import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Drawer, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  drawerPaper: {
    width: '30%',
    padding: '2rem',
  },
}));

const CollectionActions = ({ invoice, open, onClose }) => {
  const classes = useStyles();
  if (!invoice.collectionManager) return null;
  return (
    <Drawer
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="right"
      open={open}
      onClose={onClose}
    >
      <Grid container direction="column" spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h4">
            Factura {invoice.folio} a {invoice.nameReceiver}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5">
            Gestiones de cobranza
          </Typography>
        </Grid>
        {invoice.collectionManager.actions.length > 0
          ? invoice.collectionManager.actions.map((action, i) => (
            <Grid item xs={12} key={action.id}>
              <Typography variant="h6">
                Gestión {i + 1} - {moment(action.createdAt).format('DD [de] MMMM [de] YYYY')}
              </Typography>
            </Grid>
          ))
          : (
            <Typography>
              Aún no se realizan gestiones de cobranza para esta factura
            </Typography>
          )}
      </Grid>
    </Drawer>
  );
};

CollectionActions.propTypes = {
  invoice: PropTypes.shape({
    folio: PropTypes.number,
    nameReceiver: PropTypes.string,
    collectionManager: PropTypes.shape({
      actions: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        createdAt: PropTypes.string,
      })),
    }),
  }).isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CollectionActions;
