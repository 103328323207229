import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import FingoDialog from './FingoDialog';

const SiiCredential = ({ open, handleClose }) => (
  <FingoDialog
    open={open}
    handleClose={handleClose}
    title="Ingresa tus credenciales"
  >
    <Stack alignItems="center" spacing={3}>
      <Typography width="100%" align="center" paragraph>
        Ingresa tus credenciales para disfrutar de una experiencia completa y personalizada.
      </Typography>
      <Button
        component={Link}
        onClick={handleClose}
        to="/app/profile"
        size="small"
        variant="contained"
        color="primary"
      >
        Ir a mis credenciales
      </Button>

    </Stack>
  </FingoDialog>
);

SiiCredential.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default SiiCredential;
