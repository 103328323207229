import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { AccountBalance } from '@mui/icons-material';
import { AccountsListWithAdd } from '../bank';

const useStyles = makeStyles(() => ({
  icon: {
    fontSize: '5rem',
    marginBottom: '1rem',
  },
  title: {
    marginBottom: '1rem',
    fontWeight: 500,
  },
  container: {
    margin: 'auto',
    padding: '10%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '80%',
  },
}));

const UploadBankInfo = ({ setSelectedAccount }) => {
  const classes = useStyles();
  const [creatingNewBankAccount, setCreatingNewBankAccount] = useState(false);
  return (
    <Box className={classes.container}>
      {!creatingNewBankAccount && (
        <>
          <AccountBalance className={classes.icon} />
          <Typography variant="h4" className={classes.title}>Administra tus cuentas bancarias</Typography>
        </>
      )}
      <AccountsListWithAdd
        xs={12}
        setSelectedAccountInParent={(_account) => setSelectedAccount(_account)}
        setCreatingNewAccount={setCreatingNewBankAccount}
      />
    </Box>
  );
};

UploadBankInfo.propTypes = {
  setSelectedAccount: PropTypes.func,
};
UploadBankInfo.defaultProps = {
  setSelectedAccount: () => { },
};

export default UploadBankInfo;
