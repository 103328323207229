import { Circle } from '@mui/icons-material';
import { Button, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography, Stack } from '@mui/material';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { GET_USER } from '@fingo/lib/graphql';
import UploadCertificateForm from '../../credentials/UploadCertificateForm';

const FINGO_CESSION_VALUE = '0';
const EXTERNAL_CESSION_VALUE = '1';
const UNSELECTED_VALUE = '';

const OfferShoppingCartStepTwo = ({ goToFingoCession, goToExternalCession, prevStep }) => {
  const [option, _setOption] = useState('');
  const setOption = (_, value) => _setOption(value);
  const handleSubmit = () => {
    if (option === FINGO_CESSION_VALUE) goToFingoCession();
    if (option === EXTERNAL_CESSION_VALUE) goToExternalCession();
  };
  const { data: { getUser: { selectedCompany } } } = useQuery(GET_USER);
  const disableForDigitalCertificate = !selectedCompany.hasDigitalCertificate
  && option === FINGO_CESSION_VALUE;
  return (
    <Stack spacing={2}>
      <Typography variant="h2" align="center" sx={{ width: '100%' }}>
        ¿Como quieres ceder tu(s) factura?
      </Typography>
      <FormControl
        fullWidth
        margin="dense"
        sx={{ mt: 2 }}
      >
        <RadioGroup
          value={option}
          name="cession-options"
          onChange={setOption}
        >
          <FormControlLabel
            value={FINGO_CESSION_VALUE}
            control={<Radio checkedIcon={<Circle />} />}
            label="Quiero que Fingo lo haga por mi"
          />
          <FormControlLabel
            value={EXTERNAL_CESSION_VALUE}
            control={<Radio checkedIcon={<Circle />} />}
            label="Prefiero hacerlo yo, manualmente, desde mi facturador"
          />
        </RadioGroup>
        {disableForDigitalCertificate && (
          <UploadCertificateForm showImage={false} />
        )}
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
        >
          <Button
            variant="link"
            color="primary"
            size="small"
            onClick={prevStep}
            id="cart-offer-back"
          >
            Atrás
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={handleSubmit}
            disabled={(option === UNSELECTED_VALUE || disableForDigitalCertificate) && false}
            id="cart-offer-cession-selected"
          >
            Siguiente
          </Button>
        </Grid>
      </FormControl>
    </Stack>
  );
};

OfferShoppingCartStepTwo.propTypes = {
  goToFingoCession: PropTypes.func.isRequired,
  goToExternalCession: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
};

export default OfferShoppingCartStepTwo;
