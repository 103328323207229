import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { SiiIcon } from '@fingo/lib/assets';
import { useIsMobile } from '@fingo/lib/hooks';
import { Stepper, Step, StepLabel, Typography, StepConnector, Tooltip } from '@mui/material';
import { Apartment } from '@mui/icons-material';
import InvoiceStatusStepper from './invoiceStatus';
import traceToLabel from './traceText';

const TraceStatus = (
  { invoice },
) => {
  const isMobile = useIsMobile();
  const { traces, providerTrace } = invoice;
  const steps = () => {
    let functionRun = 0;
    /* We sort the events by the moment of occurrence */
    const sortedEvents = traces.concat(providerTrace).sort(
      (a, b) => moment(a.eventTime) - moment(b.eventTime),
    );
    return sortedEvents.map((event) => {
      const trace = traceToLabel(invoice, event.eventCode || event.status.code, event);
      trace.time = moment(event.eventTime).format('DD [de] MMMM [a las] HH:mm');
      trace.comments = event.comments;
      if (typeof trace.label === 'function') {
        trace.label = trace.label(functionRun);
        functionRun += 1;
      }
      return trace;
    });
  };
  if (!traces.length && !providerTrace.length) {
    return (
      <InvoiceStatusStepper
        invoice={invoice}
      />
    );
  }
  return (
    <Stepper
      orientation={isMobile ? 'vertical' : 'horizontal'}
      sx={{ p: 0 }}
      alternativeLabel={!isMobile}
      connector={<StepConnector style={{ left: 'calc(-95% + 20px)', right: 'calc(100%)' }} />}
    >
      {steps().map(({ label, time, isProviderInfo, comments }) => (
        <Tooltip
          arrow
          title={comments || ''}
        >
          <Step completed key={label}>
            <StepLabel
              icon={isProviderInfo ? <Apartment color="gray" /> : <SiiIcon sx={{ color: 'gray' }} />}
              style={{
                alignItems: 'flex-start',
              }}
            >
              <Typography sx={{ fontSize: '14px !important', fontWeight: 'medium', textAlign: 'justify', pr: 4 }}>{time}</Typography>
              <Typography sx={{ fontSize: '14px !important', fontWeight: 'light', textAlign: 'justify', pr: 4 }}>- {label}</Typography>
            </StepLabel>
          </Step>
        </Tooltip>
      ))}
    </Stepper>
  );
};

TraceStatus.propTypes = {
  invoice: PropTypes.shape({
    siiStatus: PropTypes.string.isRequired,
    dateIssued: PropTypes.objectOf(moment).isRequired,
    receptionDate: PropTypes.objectOf(moment).isRequired,
    traces: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      eventCode: PropTypes.string,
    })).isRequired,
    creditNotes: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      folio: PropTypes.number,
    })),
    providerTrace: PropTypes.arrayOf(PropTypes.shape({
      code: PropTypes.string,
      status: PropTypes.string,
    })).isRequired,
    providerRejectionReasons: PropTypes.arrayOf(PropTypes.shape({
      code: PropTypes.string,
      comments: PropTypes.string,
      rejectionCode: PropTypes.shape({ reason: PropTypes.string, code: PropTypes.string }),
      reason: PropTypes.string,
    })).isRequired,
    folio: PropTypes.number.isRequired,
    loanedTo: PropTypes.string,
    binnacleSet: PropTypes.arrayOf(PropTypes.shape({
      message: PropTypes.string,
    })).isRequired,
    invoicedetail: PropTypes.shape({
      id: PropTypes.string,
      purchaseOrderFolio: PropTypes.string,
    }),
  }).isRequired,

};

export default TraceStatus;
