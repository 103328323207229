import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { LoadingIconButton, TextLoadingButton } from '@fingo/lib/components/buttons';
import { useAnchorElementState, useBooleanState, useGetUser } from '@fingo/lib/hooks';
import { Add, ArrowDropDown } from '@mui/icons-material';
import {
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Menu,
  Snackbar,
  SnackbarContent,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
import { ADD_COMPANY_TO_SUPER_USER, CHANGE_COMPANY, GET_USER } from '@fingo/lib/graphql';
import { useMutation } from '@apollo/client';
import { cleanAllShopping } from '@fingo/lib/apollo/reactive-variables/localUpdates';
import useTextFieldValue from '@fingo/lib/hooks/useTextFieldValue';
import { toggleSiiForm } from '@fingo/lib/apollo/reactive-variables';
import GetUserToken from './GetUserToken';

const SelectCompanyNavItem = ({ ml }) => {
  const user = useGetUser();
  const [snackBarOpen, toggleSnackbar] = useBooleanState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [addCompanyRut, setAddCompanyRut, cleanValue] = useTextFieldValue('');
  const [companiesAnchor, openCompaniesAnchor, closeCompaniesAnchor] = useAnchorElementState(null);
  const [changeCompany, { loading: loadingChangingCompany }] = useMutation(
    CHANGE_COMPANY,
    { refetchQueries: [GET_USER], awaitRefetchQueries: true },
  );
  const [mutationAddCompanySuperuser, { loading: loadingAddCompany }] = useMutation(
    ADD_COMPANY_TO_SUPER_USER,
    {
      refetchQueries: [GET_USER],
      awaitRefetchQueries: true,
      variables: { rut: addCompanyRut },
      onCompleted: () => {
        toggleSnackbar();
        setSnackBarMessage('Empresa añadida');
        cleanValue();
      },
      onError: (error) => {
        setSnackBarMessage(error.message);
        toggleSnackbar();
      },
    },
  );

  const handleSelectCompany = (company) => {
    cleanAllShopping();
    closeCompaniesAnchor();
    changeCompany({ variables: { companyId: company.rut } });
  };
  const openSii = useCallback(() => {
    closeCompaniesAnchor();
    toggleSiiForm(true);
  }, [closeCompaniesAnchor]);
  return (
    <>
      <Snackbar
        open={snackBarOpen}
        onClose={() => toggleSnackbar()}
        autoHideDuration={5000}
      >
        <SnackbarContent message={snackBarMessage} />
      </Snackbar>
      <TextLoadingButton
        sx={{
          ml,
          maxWidth: { xs: '100%', md: 300, lg: 700, xl: 1000 },
        }}
        size="small"
        onClick={openCompaniesAnchor}
        id="nav-company-selector"
        loading={loadingChangingCompany}
        endIcon={<ArrowDropDown color="primary" />}
        loadingPosition="end"
        typographyProps={{
          variant: 'h6',
          color: 'text.primary',
          noWrap: true,
        }}
      >
        {user.selectedCompany ? user.selectedCompany?.name : 'Mis empresas'}
      </TextLoadingButton>
      <Menu
        id="companies-menu"
        anchorEl={companiesAnchor}
        keepMounted
        open={Boolean(companiesAnchor)}
        onClose={closeCompaniesAnchor}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{ sx: {
          p: 1,
          maxWidth: { xs: 280, md: 'inherit' },
          maxHeight: { xs: '70%', md: '70%' },
        } }}
      >
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          <ListSubheader component="div" id="nested-list-subheader-add-company">
            Agregar Nuevo
          </ListSubheader>
          {user.isStaff
            ? (
              <Stack direction="row" alignItems="center" spacing={2}>
                <TextField
                  variant="outlined"
                  name="adCompanyRut"
                  placeholder="Agregar empresa"
                  onChange={setAddCompanyRut}
                  value={addCompanyRut}
                />
                <Tooltip title="Agregar rut sin dígito verificador">
                  <LoadingIconButton
                    onClick={mutationAddCompanySuperuser}
                    sx={{ p: 0 }}
                    size="small"
                    loading={loadingAddCompany}
                    color="primary"
                    id="nav-add-company"
                  >
                    <Add />
                  </LoadingIconButton>
                </Tooltip>
              </Stack>
            )
            : (
              <Grid style={{ width: '100%' }} container direction="row">
                <Button
                  color="primary"
                  id="nav-new-company"
                  onClick={openSii}
                >
                  +Agregar empresa
                </Button>
              </Grid>
            )}
          {user.isStaff && user.isSuperuser && (
            <GetUserToken />
          )}
          <ListSubheader component="div" id="nested-list-subheader">
            Cambiar de empresa
          </ListSubheader>
          {user.companies.map((company) => (
            <ListItem
              key={company.rut}
              button
              onClick={() => handleSelectCompany(company)}
            >
              <ListItemText
                primary={company.name}
                primaryTypographyProps={{ variant: 'h6', color: 'text.light', noWrap: true }}
              />
            </ListItem>
          ))}
        </List>
      </Menu>
    </>
  );
};

SelectCompanyNavItem.propTypes = {
  ml: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

SelectCompanyNavItem.defaultProps = {
  ml: 'auto !important',
};

export default SelectCompanyNavItem;
