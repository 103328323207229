import { CheckBoxOutlineBlank } from '@mui/icons-material';
import { Checkbox } from '@mui/material';
import React from 'react';

const FingoCheckBox = (props) => (
  <Checkbox
    size="small"
    color="primary"
    icon={<CheckBoxOutlineBlank sx={{ stroke: '#ffffff', strokeWidth: 1 }} />}
    sx={{ padding: '2px' }}
    {...props}
  />
);

export default FingoCheckBox;
