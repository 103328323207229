import { useEffect } from 'react';
import { hotjar } from 'react-hotjar';
import { useGetUser } from '@fingo/lib/hooks';

const HotjarProvider = ({ children }) => {
  const user = useGetUser();
  const isHotjarInitialized = hotjar.initialized();
  if (!isHotjarInitialized && process.env.NODE_ENV === 'production' && !user?.isStaff) {
    hotjar.initialize(2019010, 6);
  }
  useEffect(() => {
    if (hotjar.initialized() && user) {
      hotjar.identify(user.id, {
        companyId: user.selectedCompany?.rut,
        companyName: user.selectedCompany?.name,
        userCompleteName: user.completeName,
      });
    }
  }, [isHotjarInitialized, user]);
  return children;
};

export default HotjarProvider;
