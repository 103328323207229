import * as React from 'react';
import Chip from '@mui/material/Chip';
import { useMutation } from '@apollo/client';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';

export default function ChipsArray({ list, setList, eraseMutation }) {
  const [eraseElement] = useMutation(eraseMutation);
  const handleDelete = (chipToDelete) => () => {
    setList((chips) => chips.filter((chip) => chip.key !== chipToDelete.key));
    eraseElement({
      variables: {
        companyId: chipToDelete.key,
      } });
  };
  if (!list) return null;
  return (
    <Grid>
      {list.map((data) => (
        <Chip
          size="small"
          key={data.key}
          label={data.label}
          onDelete={handleDelete(data)}
          sx={{ margin: '0.5rem' }}
        />
      ))}
    </Grid>
  );
}

ChipsArray.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  list: PropTypes.array.isRequired,
  setList: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  eraseMutation: PropTypes.object.isRequired,
};
