import React from 'react';
import { render } from 'react-dom';
import { FingoLogo } from '@fingo/lib/assets';
import Game from './containers/Game';

const root = document.getElementById('root');

function FingoGame() {
  return render(
    <>
      <img src={FingoLogo} alt="logo" style={{ width: 180, marginTop: 24, marginLeft: 24 }} />
      <Game boardSize={11} playerSize={25} />
    </>,
    root,
  );
}
export default FingoGame;
