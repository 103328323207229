import React, { useState, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  FormControlLabel,
  Checkbox,
  TextField,
  Grid,
} from '@mui/material';
import moment from 'moment';
import { GET_USER, UPDATE_NPS, SKIP_NPS } from '@fingo/lib/graphql';
import { useLocation } from 'react-router-dom';
import SecondStepDialog from './NPSSecondStep';
import FirstStepDialog from './NPSFirstStep';

const NPS = () => {
  const [NPSId, setNPSId] = useState(null);
  const [open, setOpen] = useState(false);
  const [step, setStep] = useState(0);
  const [grade, setGrade] = useState(0);
  const [npsInfo, setNPSInfo] = useState({});
  const [shown, setShown] = useState(null);
  const [checkedReasons, setCheckedReasons] = useState();
  const [selectedReasonsAmount, setSelectedReasonsAmount] = useState(0);
  const [otherText, setOtherText] = useState('');
  const [comment, setComment] = useState('');
  const location = useLocation();

  const npsLocations = { '/app/home': 'NPS',
    '/app/analytics': 'sophia' };

  const { data: { getUser: user } } = useQuery(GET_USER);

  useEffect(() => {
    if (npsLocations[location.pathname] && user?.activeNpsResponse && !shown) {
      const currentNpsList = user?.activeNpsResponse;
      const locationNps = npsLocations[location.pathname];
      const currentNps = currentNpsList.find((nps) => nps.npsType?.name === locationNps);
      if (currentNps) {
        const { npsType } = currentNps;
        setNPSInfo(npsType);
        setNPSId(currentNps.id);
        const reasonsObject = npsType.reasons.reduce((p, r) => ({ ...p, [r.category]: false }), {});
        setCheckedReasons(reasonsObject);
        setTimeout(() => {
          setOpen(true);
        }, 5000);
      }
    }
  }, [location?.pathname]);

  const [updateNPS] = useMutation(UPDATE_NPS);
  const [skipNPS] = useMutation(SKIP_NPS);

  const reasonsToString = () => (
    Object.entries(checkedReasons)
      .filter(([, value]) => value)
      .map(([key]) => key.toString())
  );

  const handleShown = (date) => {
    updateNPS({
      variables: {
        lastShown: date,
        id: NPSId,
      },
    });
  };

  const handleNext = () => {
    updateNPS({
      variables: {
        grade,
        id: NPSId,
      },
    });
    setStep(1);
  };

  const handleFinish = () => {
    updateNPS({
      variables: {
        comment,
        id: NPSId,
        reasons: reasonsToString(),
        otherReason: otherText,
      },
    });
    setOpen(false);
  };

  const handleClose = () => {
    skipNPS({
      variables: { id: NPSId },
    });
    setOpen(false);
  };

  const secondStepTitle = () => {
    if (npsInfo.reasons.length === 0) {
      return '¿Tienes algún Comentario adicional?';
    }
    if (grade <= 6) return npsInfo.questions[0];
    if (grade <= 8) return npsInfo.questions[1];
    return npsInfo.questions[2];
  };

  const handleReasonChange = (event) => {
    const newCheckedReasons = { ...checkedReasons, [event.target.name]: event.target.checked };
    setCheckedReasons(newCheckedReasons);
    setSelectedReasonsAmount(Object.values(newCheckedReasons).reduce((acc, checkedReason) => {
      if (checkedReason) {
        return acc + 1;
      }
      return acc;
    }, 0));
  };

  const getFormItem = (reason, i) => {
    if (reason.category === 'OTHER' && checkedReasons.OTHER) {
      return (
        <Grid key={i}>
          <FormControlLabel
            control={(
              <Checkbox
                checked={checkedReasons[reason.category]}
                onChange={handleReasonChange}
                name={reason.category}
                disabled={(selectedReasonsAmount === 3 && !checkedReasons[reason.category])}
                indeterminate={(selectedReasonsAmount === 3 && !checkedReasons[reason.category])}
              />
            )}
            label={reason.categoryToEs}
          />
          <TextField
            value={otherText}
            onChange={(e) => setOtherText(e.target.value)}
            variant="outlined"
          />
        </Grid>
      );
    }
    return (
      <FormControlLabel
        key={i}
        control={(
          <Checkbox
            checked={checkedReasons[reason.category]}
            onChange={handleReasonChange}
            name={reason.category}
            disabled={(selectedReasonsAmount === 3 && !checkedReasons[reason.category])}
            indeterminate={(selectedReasonsAmount === 3 && !checkedReasons[reason.category])}
          />
        )}
        label={reason.categoryToEs}
      />
    );
  };

  function getKeyByValue(object, value) {
    return Object.keys(object).find((key) => object[key] === value);
  }

  const buttonDisabled = () => {
    const someChecked = getKeyByValue(checkedReasons, true);
    if (someChecked) {
      if (checkedReasons.Other && otherText === '') {
        return true;
      }
      return false;
    }
    return true;
  };

  if (!open) return null;
  if (!shown) {
    handleShown(moment());
    setShown(true);
  }

  return (
    <>
      {step === 0 && (

      <FirstStepDialog
        title={npsInfo.title}
        grade={grade}
        handleClose={handleClose}
        handleNext={handleNext}
        setGrade={setGrade}
      />
      )}
      {step === 1 && (

      <SecondStepDialog
        comment={comment}
        reasons={npsInfo.reasons}
        setComment={setComment}
        getFormItem={getFormItem}
        buttonDisabled={buttonDisabled}
        secondStepTitle={secondStepTitle}
        handleClose={handleClose}
        handleFinish={handleFinish}
      />
      )}
    </>
  );
};

export default NPS;
