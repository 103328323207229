import React, { useState } from 'react';
import { FingoDivider } from '@fingo/lib/components/dividers';
import { FingoPaper } from '@fingo/lib/components/layout';
import { PaperHeader } from '@fingo/lib/components/headers';
import { FingoSnackBar } from '@fingo/lib/components/snackBars';
import { ADD_BANNED_COMPANY, ERASE_BANNED_COMPANY, GET_PREFERENCES, GET_USER, SET_PREFERENCES } from '@fingo/lib/graphql';
import { useBooleanState } from '@fingo/lib/hooks';
import {
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useMutation, useQuery } from '@apollo/client';
import { Add, Save } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import ChipsArray from '../../../components/chips/chip_array';
import UploadInvoicingInstructions from '../supplierAutomate/UploadInvoicingInstructions';
import FingoCheckBox from '../../../components/checkbox/FingoCheckBox';

const Settings = () => {
  const { data: { getUser: { selectedCompany: { rut: company } } } } = useQuery(GET_USER);
  const mySalesRuts = [
    76003885,
    76787110,
    96949830,
    76005426,
    76090483,
    76787060,
    96524510,
    77104776,
    77104786,
    76899578,
    76003557,
  ];
  const [preferences, setPreferences] = useState({});
  const [bannedCompanies, setBannedCompanies] = useState([]);
  const [newBannedCompany, setNewBannedCompany] = useState('');
  const [preferenceChange, setPreferenceChange] = useState(false);
  const [openSnackbar, toggleSnackbar] = useBooleanState(false);
  const handlePreferenceChange = (event) => {
    setPreferences({ ...preferences, [event.target.name]: event.target.checked });
    setPreferenceChange(true);
  };
  useQuery(GET_PREFERENCES, {
    onCompleted: (data) => {
      setPreferences({ ...data.getUserPreferences, __typename: undefined });
      const newData = data ? data.getUserPreferences.supplierAutomate_BannedCompanies.map(
        (node) => ({
          key: node.rut,
          label: node.name,
        }),
      ) : [];
      setBannedCompanies(newData);
    } });
  const [setNewPreferences, { loading: loadingPreferences }] = useMutation(
    SET_PREFERENCES,
    {
      variables: {
        input: {
          ...preferences,
          supplierAutomate_BannedCompanies: undefined,
        },
      },
      onCompleted: () => {
        setPreferenceChange(false);
        toggleSnackbar();
      },
    },
  );
  const [addBannedCompany,
    { loading: addingNewCompany }] = useMutation(ADD_BANNED_COMPANY, {
    onCompleted: () => {},
    refetchQueries: [{ query: GET_PREFERENCES }],
  });
  const handleAddBannedCompany = () => {
    addBannedCompany({
      variables: {
        companyId: newBannedCompany,
      } });
    setNewBannedCompany('');
  };
  if (!preferences) return null;
  return (
    <>
      <Grid container rowSpacing={4} sx={{ mt: 0 }} justifyContent="space-between">
        <PaperHeader viewTitle="Configuraciones" />
        <FingoDivider />
        <Grid container sx={{ py: 2 }}>
          <Typography>
            ¡Configura la plataforma de Fingo cómo más te acomode!
            Recuerda que puedes cambiar las configuraciones en cualquier momento
          </Typography>
        </Grid>
        <Grid item container>
          <FormControl component="fieldset">
            <Typography variant="h6" fontWeight={700}>Notificaciones</Typography>
            <FormGroup>
              <FormControlLabel
                sx={{ margin: 0 }}
                control={(
                  <FingoCheckBox
                    checked={Boolean(preferences.notifications_MentionsMail)}
                    onChange={handlePreferenceChange}
                    name="notifications_MentionsMail"
                  />
                  )}
                label={(
                  <>
                    <Typography display="inline">Recibir correos con{' '}</Typography>
                    <Typography
                      component="span"
                      style={{ fontWeight: 'bold', whiteSpace: 'pre' }}
                      display="inline"
                    >
                      @menciones{' '}
                    </Typography>
                    <Typography display="inline">del chat</Typography>
                  </>
                    )}
              />
              {mySalesRuts.includes(company) && (
              <FormControlLabel
                sx={{ margin: 0 }}
                control={(
                  <FingoCheckBox
                    checked={Boolean(preferences.notifications_CessionMail)}
                    onChange={handlePreferenceChange}
                    name="notifications_CessionMail"
                  />
                    )}
                label="Recibir correos con cesiones en mis compras"
              />
              )}
              {!mySalesRuts.includes(company) && (
              <FormControlLabel
                sx={{ margin: 0 }}
                control={(
                  <FingoCheckBox
                    checked={Boolean(preferences.notifications_RejectionMail)}
                    onChange={handlePreferenceChange}
                    name="notifications_RejectionMail"
                  />
                      )}
                label="Recibir correos con los rechazos de mis facturas"
              />
              )}
              {mySalesRuts.includes(company) && (
              <FormControlLabel
                sx={{ margin: 0 }}
                control={(
                  <FingoCheckBox
                    checked={Boolean(preferences.notifications_AcceptanceMail)}
                    onChange={handlePreferenceChange}
                    name="notifications_AcceptanceMail"
                  />
                        )}
                label="Recibir correos con acuse de recibo en mis facturas"
              />
              )}
            </FormGroup>
            <LoadingButton
              loading={loadingPreferences}
              loadingPosition="start"
              startIcon={<Save />}
              variant="contained"
              color="primary"
              size="small"
              disabled={!preferenceChange}
              sx={{ width: '30%', marginTop: '8px', alignSelf: 'end', height: '24px' }}
              onClick={() => setNewPreferences()}
              id="settings-save-button"
            >
              Guardar
            </LoadingButton>
          </FormControl>
        </Grid>
        {mySalesRuts.includes(company) && (
          <Grid item container columnSpacing={16}>
            <Grid item>
              <FormControl component="fieldset" sx={{ paddingTop: 4 }}>
                <Typography variant="h6" fontWeight={700}>Lista de proveedores silenciados</Typography>
                {addingNewCompany ? <CircularProgress sx={{ alignSelf: 'center', padding: '0.5em' }} /> : (
                  <ChipsArray
                    list={bannedCompanies}
                    setList={setBannedCompanies}
                    eraseMutation={ERASE_BANNED_COMPANY}
                  />
                ) }
                <TextField
                  variant="outlined"
                  value={newBannedCompany}
                  onChange={(e) => setNewBannedCompany(e.target.value)}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">RUT</InputAdornment>,
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title="Silenciar empresa">
                          <span>
                            <IconButton
                              onClick={handleAddBannedCompany}
                              disabled={!newBannedCompany}
                              edge="end"
                              id="settings-ban-company"
                            >
                              <Add />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </InputAdornment>
                    ) }}
                />
                <FormHelperText>
                  Los proveedores que aparezcan en esta lista
                  no serán presentados en mis compras/mis ventas.
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl component="fieldset" sx={{ paddingY: 4 }}>
                <Typography variant="h6" fontWeight={700}>Instructivo de facturación</Typography>
                <UploadInvoicingInstructions receiverRut={company} />
                <FormHelperText>
                  El instructivo será actualizado automáticamente cuando subas el archivo
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        )}
      </Grid>
      <FingoSnackBar
        open={openSnackbar}
        setOpen={toggleSnackbar}
        message="Sus cambios fueron guardados exitosamente"
        severity="error"
      />
    </>
  );
};

export default () => (
  <FingoPaper menuOptions={[
    {
      label: 'Configuraciones',
      path: 'settings',
      component: <Settings />,
    },
  ]}
  />
);
