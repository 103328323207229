import React, { useCallback, useMemo } from 'react';
import MobileStepper from '@mui/material/MobileStepper';
import { FingoDialog } from '@fingo/lib/components/dialogs';
import { useGetUser, useIsLogged, useSnackBars } from '@fingo/lib/hooks';
import QuickRegisterForm from './QuickRegisterForm';
import QuickSiiForm from './QuickSiiForm';

const steps = [
  {
    title: '¡Regístrate en Fingo!',
    component: <QuickRegisterForm />,
  },
  {
    title: 'Ingresa tus credenciales para visualizar tus facturas',
    component: <QuickSiiForm />,
  },
];

const StepperRegisterForm = () => {
  const { addAlert } = useSnackBars();
  const user = useGetUser();
  const isLogged = useIsLogged();
  const onClose = useCallback(() => {
    addAlert({
      id: 0,
      color: 'text',
      severity: 'info',
      message: 'Continua con el registro para ver tus facturas rechazadas',
    });
  }, [addAlert]);
  const activeStep = useMemo(() => (user ? 1 : 0), [user]);
  const maxSteps = steps.length;
  const { component, title } = useMemo(() => steps[activeStep], [activeStep]);
  if (!user && isLogged) return null;
  return (
    <FingoDialog
      open
      title={title}
      handleClose={onClose}
      PaperProps={{ sx: { borderRadius: 4, maxWidth: 500 } }}
    >
      {component}
      <MobileStepper
        variant="dots"
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        sx={{ justifyContent: 'center' }}
      />
    </FingoDialog>
  );
};

export default StepperRegisterForm;
