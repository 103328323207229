import React, { useEffect, useState } from 'react';

import { Button, CircularProgress, Dialog, Stack, Typography } from '@mui/material';
import { HomeBackground } from '@fingo/lib/assets';
import { Check } from '@mui/icons-material';
import PropTypes from 'prop-types';
import ProgressiveImage from 'react-progressive-graceful-image';
import { Box } from '@mui/system';
import { useLocation } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { COMPANY_PROFILE_PICTURE } from '@fingo/lib/graphql';

const styles = {
  paperContainer: {
    backgroundImage: `url(${HomeBackground})`,
    justifyContent: 'center',
    minWidth: 640,
    height: 720,
  },
};
const Benefits = ({ title }) => (
  <Stack alignItems="center" direction="row">
    <Check sx={{ color: 'green' }} />
    <Typography variant="h2" justifySelf="center">
      {title}
    </Typography>
  </Stack>
);
Benefits.propTypes = {
  title: PropTypes.string.isRequired,
};
const SupplierWelcomeDialog = () => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const [open, setOpen] = useState(false);
  const [profilePic, setProfilePic] = useState('');
  const [companyProfilePicture] = useLazyQuery(
    COMPANY_PROFILE_PICTURE,
    {
      onCompleted: (
        { company: { companyfiles: { profilePictureUrl } } },
      ) => {
        setProfilePic(profilePictureUrl);
      } },
  );
  useEffect(() => {
    /* This useEffect acts as an automatic opener for the welcome dialog */
    if (location.state?.referrer === 'supplier-onboarding') {
      companyProfilePicture({ variables: { companyId: urlParams.get('company_id') } });
      setOpen(true);
      location.state.referrer = '';
    }
  }, []);
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      PaperProps={{ style: styles.paperContainer }}
    >
      <Stack
        spacing={4}
        height="90%"
        width="90%"
        justifyContent="center"
        alignSelf="center"
        sx={{ backgroundColor: 'white', p: 5 }}
      >
        <Stack direction="row" alignItems="space-between">
          <Typography variant="h2" fontSize={24}>
            ¡Bienvenido a tu <b>nueva plataforma</b> colaborativa de proveedores!
          </Typography>
          <ProgressiveImage
            src={profilePic}
            placeholder=""
          >
            {(src, loading) => {
              if (loading) {
                return <CircularProgress color="gray" />;
              }
              return (
                <Box
                  component="img"
                  sx={{
                    width: { xs: '100%', md: '60%' },
                    maxWidth: 120,
                    height: 'auto',
                  }}
                  src={src}
                  alt="alt"
                />
              );
            }}
          </ProgressiveImage>
        </Stack>
        <Typography variant="h2">
          Fingo en conjunto con Australis Seafoods ha desarrollado una nueva plataforma
          colaborativa donde podrás encontrar el estado de tus documentos emitidos a Australis Mar.
        </Typography>
        <Stack>
          <Typography variant="h2">
            Disfrutarás de:
          </Typography>
          <Benefits title="Beneficio 1" />
          <Benefits title="Beneficio 2" />
          <Benefits title="Beneficio 3" />
          <Benefits title="Beneficio 4" />
        </Stack>
        <Typography variant="h2">
          Para ver esta y más información con todas tus facturas ingresa tus claves del SII aquí.
        </Typography>
        <Button
          variant="contained"
          sx={{ width: '50%', alignSelf: 'center' }}
          onClick={() => setOpen(false)}
        > Continuar a Fingo
        </Button>
      </Stack>
    </Dialog>
  );
};
export default SupplierWelcomeDialog;
