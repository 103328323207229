import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import zxcvbn from 'zxcvbn';
import { List, ListItem as MuiIt, ListItemIcon as MuiLIc, ListItemText, Stack, Typography as MuiTy } from '@mui/material';
import styled from 'styled-components';
import { Check, Clear } from '@mui/icons-material';
import { passwordCheckers } from './passwordCheckers';

const useStyles = makeStyles((theme) => ({
  veryLow: {
    color: theme.palette.error.main,
  },
  low: {
    color: theme.palette.error.light,
  },
  medium: {
    color: theme.palette.warning.main,
  },
  high: {
    color: theme.palette.success.light,
  },
  veryHigh: {
    color: theme.palette.success.main,
  },
}));

const Typography = styled(MuiTy)({
  width: '100%',
  align: 'left',
});
const ListItem = styled(MuiIt)({
  padding: 0,
});
const ListItemIcon = styled(MuiLIc)({
  padding: 0,
  minWidth: 0,
});

const PasswordStrength = ({ password, userInput }) => {
  const classes = useStyles();

  const getComponent = (score) => {
    if (!password) {
      return (
        <Typography variant="caption">
          Escribe tu contraseña para obtener una calificación de seguridad
        </Typography>
      );
    }
    switch (score) {
      case 0:
        return <Typography className={classes.veryLow}><b>Muy baja</b></Typography>;
      case 1:
        return <Typography className={classes.low}><b>Baja</b></Typography>;
      case 2:
        return <Typography className={classes.medium}><b>Media</b></Typography>;
      case 3:
        return <Typography className={classes.high}><b>Alta</b></Typography>;
      case 4:
        return <Typography className={classes.veryHigh}><b>Excelente</b></Typography>;
      default:
        return null;
    }
  };

  const { score } = zxcvbn(password, userInput);
  const getIcon = (isTrueCallback) => {
    if (isTrueCallback(password)) {
      return <Check size="small" color="success" />;
    }
    return <Clear color="error" size="small" />;
  };
  return (
    <Stack width="100%" alignItems="flex-start">
      {getComponent(score)}
      <List>
        {passwordCheckers.map(({ label, regexChecker }) => (
          <ListItem key={label}>
            <ListItemIcon>
              {getIcon(regexChecker)}
            </ListItemIcon>
            <ListItemText primary={label} />
          </ListItem>
        ))}
      </List>
    </Stack>
  );
};

PasswordStrength.propTypes = {
  password: PropTypes.string.isRequired,
  userInput: PropTypes.arrayOf(PropTypes.string),
};

PasswordStrength.defaultProps = {
  userInput: [],
};

export default PasswordStrength;
