import React from 'react';
import { FingoPaper } from '@fingo/lib/components/layout';
import { MyProviders } from './providersRoutes';

const Providers = () => (
  <FingoPaper
    menuOptions={[
      {
        label: 'Proveedores',
        path: 'contacts',
        component: <MyProviders />,
      },
    ]}
  />
);

export default Providers;
