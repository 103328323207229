import React from 'react';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import CheckIcon from '@mui/icons-material/Check';
import { Sidebar } from '@fingo/lib/components/layout';
import { useGetUser } from '@fingo/lib/hooks';
import Home from '../views/App/Home';
import { Purchase, Providers } from '../views/App/purchase';
import { Sales } from '../views/App/sales';
import Analytics from '../views/App/analytics/Analytics';
import Factoring from '../views/App/sales/Factoring';
import Ordering from '../views/App/sales/Ordering';

const salesRouteList = (user) => [
  { id: 'sales-side-factoring', label: 'Anticipa tus facturas', path: 'factoring', icon: <PaidOutlinedIcon />, component: <Factoring />, disabled: !user.companies.length },
  { id: 'sales-side-ordering', label: 'Anticipa tus OC', path: 'ordering', icon: <CheckIcon />, component: <Ordering />, disabled: !user.companies.length },
  { id: 'sales-side-accountable', label: 'Cuentas por cobrar', path: 'accountable', icon: <DescriptionOutlinedIcon />, component: <Sales />, disabled: false },
];

const purchasesRouteList = (user) => [
  { id: 'purchases-side-payable', label: 'Cuentas por pagar', path: 'payable', icon: <DescriptionOutlinedIcon />, component: <Purchase />, disabled: !user.companies.length },
  { id: 'purchases-side-providers', label: 'Proveedores', path: 'providers', icon: <DescriptionOutlinedIcon />, component: <Providers />, disabled: !user.companies.length },
];

const analyticsRouteList = (user) => [
  { id: 'analytics-side-finance', label: 'Financiero', path: 'finance', icon: <DescriptionOutlinedIcon />, component: <Analytics />, disabled: !user.companies.length },
];

const routeSetter = (user) => [
  {
    id: 'nav-home',
    label: 'Home',
    path: '/app/home',
    exactMatch: false,
    shouldShow: () => user && !user.isAustralisStaff,
    disabled: !user.companies.length,
    children: <Home />,
    subRoutes: [],
  },
  {
    label: 'Mis ventas',
    path: '/app/sales',
    shouldShow: () => user && !user.isAustralisStaff,
    disabled: false,
    children: (
      <Sidebar
        menuOptions={salesRouteList(user)}
      />
    ),
    subRoutes: salesRouteList(user),
  },
  {
    id: 'nav-purchases',
    label: 'Mis compras',
    path: '/app/purchases',
    shouldShow: () => user,
    disabled: !user.companies.length,
    children: (
      <Sidebar
        menuOptions={purchasesRouteList(user)}
      />
    ),
    subRoutes: purchasesRouteList(user),
  },
  {
    id: 'nav-analytics',
    label: 'Analytics',
    path: '/app/analytics',
    new: false,
    shouldShow: () => user && !user.isAustralisStaff,
    disabled: !user.companies.length,
    children: (
      <Sidebar
        menuOptions={analyticsRouteList(user)}
      />
    ),
    subRoutes: analyticsRouteList(user),
  },
];
const useFingoRoutes = () => {
  /* In order to change fewer code we define a base routing case */
  const currentUser = useGetUser();
  const routes = currentUser ? routeSetter(currentUser) : [];
  return routes.filter((route) => route.shouldShow());
};

export default useFingoRoutes;
