/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_TERMS } from '@fingo/lib/graphql';
import { TermsAndConditions, BottomLeftWave } from '@fingo/lib/assets';
import {
  Grid,
  Typography,
  Divider,
  CircularProgress,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import TermsAndConditionsDocument from '@fingo/lib/components/text/TermsAndConditionsDocument';

const useStyles = makeStyles((theme) => ({
  termsContainer: {
    margin: '2rem 2rem 0',
    [theme.breakpoints.down('md')]: {
      margin: '1rem 0',
    },
  },
  animationContainer: {
    height: '100%',
  },
  form: {
    padding: '1.5rem',
  },
  section: {
    marginBottom: '1rem',
  },
  titleSection: {
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  divider: {
    width: '100%',
    marginBottom: '1rem',
  },
  termsSection: {
    maxHeight: '70vh',
    paddingRight: '1rem',
    marginBottom: '1rem',
    overflowY: 'auto',
  },
  banner: {
    display: 'flex',
    height: '100%',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
  },
  illustration: {
    position: 'absolute',
    bottom: 0,
    left: '10%',
    width: '80vh',
    height: '80vh',
    zIndex: -1,
  },
  bottomLeftWave: {
    position: 'absolute',
    height: '90%',
    width: '50%',
    bottom: 0,
    left: 0,
    zIndex: -2,
  },
}));

const TermsAndConditionsSection = () => {
  const classes = useStyles();
  const [termsSections, setTermsSections] = useState([]);
  const { loading, data, error } = useQuery(GET_TERMS);

  useEffect(() => {
    if (data) {
      setTermsSections(data.getTermsAndConditions);
    }
  }, [data]);

  if (error) return `Error! ${error.message}`;

  return (
    <Grid container>
      <Grid item xs={0} md={4} lg={6}>
        <div className={classes.banner}>
          <img src={TermsAndConditions} alt="Terms and conditions illustration" className={classes.illustration} />
        </div>
      </Grid>
      <Grid item xs={12} md={8} lg={6}>
        <div className={classes.termsContainer}>
          {loading && (
            <Grid container direction="column" alignItems="center" justifyContent="center" className={classes.animationContainer}>
              <CircularProgress />
            </Grid>
          )}
          {!loading && (
            <Grid container justifyContent="center" className={classes.form}>
              <Grid item xs={12}>
                <Typography variant="h5" color="primary" className={classes.titleSection}>
                  Términos y Condiciones
                </Typography>
              </Grid>
              <Divider variant="middle" className={classes.divider} />
              <Grid item xs={12} className={classes.termsSection}>
                <Grid container alignItems="flex-start" justifyContent="center" className={classes.section}>
                  <Grid item xs={12}>
                    <TermsAndConditionsDocument termsSections={termsSections} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </div>
      </Grid>
      <img src={BottomLeftWave} className={classes.bottomLeftWave} alt="Bottom left wave decoration" />
    </Grid>
  );
};

export default TermsAndConditionsSection;
