import React from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { PaperHeader } from '@fingo/lib/components/headers';
import { GET_USER, MARK_TUTORIAL_SEEN } from '@fingo/lib/graphql';
import { Grid } from '@mui/material';
import { useBooleanState } from '@fingo/lib/hooks';
import { SophiaHead, Graphs } from '../../components/sophia';
import { SophiaTourGuide } from '../../components/joyRides';

const Tracker = () => {
  const TUTORIAL_CODE = 'sophia_tutorial';
  const { data: { getUser: { userseentutorialSet, ...user } } } = useQuery(GET_USER);
  const [markTutorialSeen] = useMutation(
    MARK_TUTORIAL_SEEN,
    { variables: { tutorialCode: TUTORIAL_CODE } },
  );
  const [runGuide, toggleRunGuide] = useBooleanState(false);
  const onCompletedInfo = () => {
    const tutorial = userseentutorialSet.filter(
      (_tutorial) => _tutorial.tutorialCode === TUTORIAL_CODE && _tutorial.seen,
    );
    if (!tutorial.length) {
      markTutorialSeen();
      toggleRunGuide();
    }
  };
  return (
    <Grid container id="name-container" pb={3}>
      <PaperHeader
        type="sophia"
        viewTitle={user.selectedCompany ? user.selectedCompany.name : user.completeName}
        openJoyride={toggleRunGuide}
      />
      <SophiaHead setIsReady={onCompletedInfo} />
      <Graphs />
      <SophiaTourGuide run={runGuide} setRun={toggleRunGuide} />
    </Grid>
  );
};

export default Tracker;
