import { Card, CardActions, Grid, IconButton, Skeleton, Stack, Tooltip, Typography } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

const ProfileCards = ({
  primaryTitle, subtitle, children, cardActions, titleActions, customActions }) => (
    <Card
      raised
      elevation={2}
      sx={{ height: '100%' }}
    >
      <Grid container py={2} px={2}>
        <Stack direction="row" mb={3} alignItems="flex-start" justifyContent="space-between" flex={1}>
          <Typography component="span" variant="h6">
            <Typography component="span" variant="h5" color="primary.main" sx={{ mr: '4px' }}>{primaryTitle}</Typography>
            {' '}{subtitle}
          </Typography>
          {titleActions && (
            <Tooltip title={titleActions.tooltipTitle || ''} arrow>
              <span>
                {titleActions.loading ? <Skeleton variant="circular" width={50} height={50} /> : (
                  <IconButton
                    onClick={titleActions.onClick}
                    disabled={titleActions.disabled}
                    id={titleActions.id}
                  >
                    {titleActions.icon}
                  </IconButton>
                )}
              </span>
            </Tooltip>
          )}
        </Stack>
        <Grid container>
          {children}
        </Grid>
      </Grid>
      {cardActions && (
        <CardActions
          sx={{ justifyContent: 'flex-end' }}
        >
          {cardActions.loading ? <Skeleton variant="text" width={30} height={40} /> : (
            <IconButton onClick={cardActions.onClick}>
              {cardActions.icon}
            </IconButton>
          )}

        </CardActions>
      )}
      {!!customActions && customActions}
    </Card>
);

ProfileCards.propTypes = {
  primaryTitle: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  children: PropTypes.element.isRequired,
  cardActions: PropTypes.shape({
    id: PropTypes.string,
    icon: PropTypes.element,
    onClick: PropTypes.func,
    loading: PropTypes.bool,
  }),
  titleActions: PropTypes.shape({
    id: PropTypes.string,
    icon: PropTypes.element,
    onClick: PropTypes.func,
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    tooltipTitle: PropTypes.node,
  }),
  customActions: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
};
ProfileCards.defaultProps = {
  subtitle: '',
  cardActions: null,
  titleActions: null,
  customActions: false,
};

export default ProfileCards;
