import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { offerDateToPaySelectedQuery, offerSelectedQuery } from '@fingo/lib/apollo/cache/localQuery';
import { DOCUMENTS } from '@fingo/lib/graphql';
import { formatDayMonthYear } from '@fingo/lib/helpers';
import { LoadingButton } from '@mui/lab';
import { Button, Grid, Typography } from '@mui/material';
import useCreateOperation from '../../../hooks/useCreateOperation';
import SelectBankAccount from '../../factoring/SelectBankAccount';

const ExternalOfferShoppingCartStepOne = ({ prevStep, nextStep }) => {
  const { data: { selectedOfferIds } } = useQuery(offerSelectedQuery);
  const { data: { selectedOfferDateToPay } } = useQuery(offerDateToPaySelectedQuery);
  const { data: offers } = useQuery(DOCUMENTS, { variables: { id_In: selectedOfferIds, first: 100 },
    skip: !selectedOfferIds.length });
  const [bankId, setBankId] = useState('');
  const [createOperation, { loading }] = useCreateOperation(true, bankId, null, nextStep);
  const paragraphs = [
    {
      title: 'Para ceder desde tu facturador debes seguir los siguientes pasos.',
      text: [],
    },
    {
      title: 'Ingresa a tu facturador electrónico y cédenos las siguientes facturas:',
      text: offers?.invoices.edges.map(({ node }) => `Folio ${node.folio} - Fecha de pago: ${formatDayMonthYear(selectedOfferDateToPay[node.id])}`),
    },
    {
      title: 'Para ceder debes ingresar los siguientes datos:',
      text: [
        'RUT: 77.201.556-9',
        'Razón social: FINGO SPA',
        'Dirección: PROVIDENCIA 1760 OF 1603',
        'Email: cesionario@fingo.cl',
      ],
    },
    {
      title: 'A continuación, seleccione la cuenta del banco a la que desea que se gire el dinero y una vez que las facturas estén cedidas, debes confirmar tu cesión apretando el botón a continuación.',
      text: [],
    },
  ];
  return (
    <Grid container rowSpacing={2}>
      <Grid container item>
        <Typography variant="h2" align="center" sx={{ width: '100%' }}>
          ¡Quiero ceder yo desde mi facturador!
        </Typography>
        {paragraphs.map(({ title, text }) => (
          <React.Fragment key={title}>
            <Typography variant="h6" sx={{ width: '100%', mt: 2 }} lineHeight={1.6} fontWeight="500">
              {title}
            </Typography>
            {text?.map((t) => (
              <Typography key={t} variant="body1" sx={{ width: '100%' }} lineHeight={1.3}>{t}</Typography>
            ))}
          </React.Fragment>
        ))}
      </Grid>
      <Grid container item>
        <SelectBankAccount bankId={bankId} setBankId={setBankId} />
      </Grid>
      <Grid item container xs={12} justifyContent="space-between">
        <Button
          variant="link"
          color="primary"
          size="small"
          onClick={prevStep}
          id="cart-offer-back-2"
        >
          Atrás
        </Button>
        <LoadingButton
          variant="contained"
          color="primary"
          size="small"
          disabled={bankId === ''}
          onClick={() => createOperation()}
          loading={loading}
          id="cart-offer-external-confirm"
        >
          ¡Operar con Fingo!
        </LoadingButton>
      </Grid>
    </Grid>
  );
};

ExternalOfferShoppingCartStepOne.propTypes = {
  prevStep: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
};

export default ExternalOfferShoppingCartStepOne;
