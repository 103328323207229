import React from 'react';
import { Dialog } from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import UploadSiiCredentialsForm from '../forms/UploadSiiCredentialsForm';
import { toggleSiiForm } from '../../apollo/reactive-variables';

const SiiDialog = () => {
  const siiOpen = useReactiveVar(toggleSiiForm);
  const closeSiiForm = () => toggleSiiForm(false);
  return (
    <Dialog
      open={siiOpen}
      onClose={closeSiiForm}
      maxWidth="sm"
      fullWidth
    >
      <UploadSiiCredentialsForm
        isNewCompany
        closeSelf={closeSiiForm}
      />
    </Dialog>
  );
};

export default SiiDialog;
