import React from 'react';
import { Route, useRouteMatch, Switch } from 'react-router-dom';
import { FingoPaper } from '@fingo/lib/components/layout';
import {
  AvailableOrdering,
  OfferOrdering,
  SignDocument,
  OrderingOperations,
  SuccessDocumentSigning,
} from './orderingRoutes';

const Ordering = () => {
  const { path: routePath } = useRouteMatch();
  return (
    <>
      <Switch>
        <Route path={`${routePath}/signdocument/success`}>
          <FingoPaper
            menuOptions={[
              {
                label: 'Éxito mandato',
                path: '',
                component: <SuccessDocumentSigning />,
              },
            ]}
          />
        </Route>
        <Route path={`${routePath}/signdocument`}>
          <FingoPaper
            menuOptions={[
              {
                label: 'Firma de mandato',
                path: '',
                component: <SignDocument />,
              },
            ]}
          />
        </Route>
        <Route>
          <FingoPaper
            menuOptions={[
              {
                id: 'ordering-tab-available',
                label: 'OC disponibles',
                path: 'available',
                component: <AvailableOrdering />,
              },
              {
                id: 'ordering-tab-offers',
                label: 'OC con oferta',
                path: 'offer',
                component: <OfferOrdering />,
              },
              {
                id: 'ordering-tab-operations',
                label: 'Operaciones activas e históricas',
                path: 'operations',
                component: <OrderingOperations />,
              },
            ]}
          />
        </Route>
      </Switch>
    </>
  );
};

export default Ordering;
