import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { FingoSnackBar } from '@fingo/lib/components/snackBars';
import { GET_INVOICE_PROVIDER } from '@fingo/lib/graphql';
import { useBooleanState } from '@fingo/lib/hooks';
import { SendIcon } from '@fingo/lib/assets';
import { ExpandMore } from '@mui/icons-material';
import { Button, Card, Grid, List, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Menu, Skeleton, Stack, Typography } from '@mui/material';
import LoaderProgressiveImage from '../image/LoaderProgressiveImage';
import ProviderCard from './ProviderCard';
import UploadCredentialForm from './UploadCredentialForm';
import InvoiceProviderCredentialMoreInfo from './InvoiceProviderCredentialMoreInfo';

const textMaker = {
  electronic_invoices: {
    otherSelectorText: 'Otro facturador',
    insideSelectorText: 'Facturador',
    buttonText: '¿Por qué es necesario vincular la cuenta del facturador?',
  },
  supplier_platform: {
    otherSelectorText: 'Otra plataforma',
    insideSelectorText: 'Portal de proovedores',
  },
};

const CredentialProvider = ({ viewType }) => {
  const [integratedProvider, setIntegratedProvider] = useState([]);
  const [notIntegratedProvider, setNotIntegratedProvider] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [moreInfoOpen, toggleMoreInfoOpen] = useBooleanState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState();
  const [snackBarType, setSnackBarType] = useState();
  const configureSnackBar = (type, message) => {
    setSnackBarMessage(message);
    setSnackBarType(type);
    setOpenSnackBar(true);
  };
  const { otherSelectorText, insideSelectorText, buttonText } = textMaker[viewType];
  const openMenu = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const closeMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);
  const {
    loading: loadingInvoiceProvider } = useQuery(
    GET_INVOICE_PROVIDER,
    {
      variables: { credentialProviderType_Name: viewType },
      onCompleted: ({ getInvoiceProvider }) => {
        setIntegratedProvider(
          () => getInvoiceProvider.filter(({ integrated }) => integrated),
        );
        setNotIntegratedProvider(
          () => getInvoiceProvider.filter(({ integrated }) => !integrated),
        );
      },
    },
  );
  if (loadingInvoiceProvider) return <Skeleton />;
  if (selectedProvider) {
    return (
      <UploadCredentialForm
        configureSnackBar={configureSnackBar}
        provider={selectedProvider}
        close={() => setSelectedProvider(null)}
      />
    );
  }
  if (moreInfoOpen) return <InvoiceProviderCredentialMoreInfo close={toggleMoreInfoOpen} />;
  return (
    <>
      <FingoSnackBar
        open={openSnackBar}
        setOpen={setOpenSnackBar}
        message={snackBarMessage}
        severity={snackBarType}
      />
      <Grid container justifyContent="space-around" rowSpacing={3}>
        {integratedProvider.map((provider) => (
          <Grid
            item
            xs={5.5}
            key={provider.name}
            onClick={() => setSelectedProvider(provider)}
            sx={{
              cursor: 'pointer',
            }}
          >
            <ProviderCard value={provider} />
          </Grid>
        ))}
        <Grid item xs={12} justifyContent="center" alignItems="center">
          <Card
            sx={{ p: 2, width: 'fit-content', mr: 'auto', ml: 'auto', cursor: 'pointer' }}
            onClick={openMenu}
          >
            <Stack direction="row" spacing={2} alignItems="center">
              <Typography variant="h5">{otherSelectorText}</Typography>
              <ExpandMore color="primary" />
            </Stack>
          </Card>
        </Grid>
        {buttonText && (
        <Button variant="link" onClick={toggleMoreInfoOpen}>
          {buttonText}
        </Button>
        )}
      </Grid>
      <Menu
        id="provider-selector-credential"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <List component="nav" aria-labelledby="select-provider" sx={{ minWidth: 250 }}>
          <ListSubheader component="div" id="provider title">
            {insideSelectorText}
          </ListSubheader>
          {notIntegratedProvider.map((provider) => (
            <ListItemButton
              key={String(provider.id)}
              disablePadding
              onClick={() => {
                closeMenu();
                setSelectedProvider(provider);
              }}
              sx={{ height: 50, justifyContent: 'center' }}
            >
              {provider.iconUrl ? (
                <ListItemIcon sx={{ height: '100%' }}>
                  <LoaderProgressiveImage
                    src={provider.iconUrl || SendIcon}
                    alt={provider.name}
                    height="100%"
                    width="100%"
                  />
                </ListItemIcon>
              ) : (
                <ListItemText
                  primary={provider.name}
                  primaryTypographyProps={{
                    align: 'center',
                    variant: 'h2',
                  }}
                />
              )}
            </ListItemButton>
          ))}
        </List>
      </Menu>
    </>
  );
};

CredentialProvider.propTypes = {
  viewType: PropTypes.string.isRequired,
};

export default CredentialProvider;
