import React, { useCallback, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useBooleanState, useSelectedCompany } from '@fingo/lib/hooks';
import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import { CESSIONS_SUMMARY, REQUEST_SURPLUS_WITHDRAWAL } from '@fingo/lib/graphql';
import { AccountsListWithAdd } from '../bank';
import FingoDialog from '../dialogs/FingoDialog';
import CantWithdrawSurplusExplanation from '../dialogs/CantWithdrawSurplusExplanation';

const Surplus = () => {
  const [open, toggleOpen] = useBooleanState(false);
  const [openExplanation, toggleOpenExplanation] = useBooleanState(false);
  const selectedCompany = useSelectedCompany();
  const { data: cessionsSummary } = useQuery(
    CESSIONS_SUMMARY,
    { variables: { companyId: selectedCompany.rut } },
  );
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [requestSurplus, { loading }] = useMutation(
    REQUEST_SURPLUS_WITHDRAWAL,
    {
      variables: {
        companyRut: selectedCompany.rut,
        accountId: selectedAccount?.id,
      },
      refetchQueries: [CESSIONS_SUMMARY],
      awaitRefetchQueries: true,
    },
  );
  const cantWithdrawSurplus = useCallback(() => cessionsSummary?.cessionsSummary.debtAmount > 0
  || cessionsSummary?.cessionsSummary.availableSurplus < 0, [cessionsSummary]);
  const handleSubmit = () => {
    requestSurplus();
    toggleOpen();
  };
  const handleButton = useCallback(() => {
    if (!cantWithdrawSurplus()) {
      toggleOpen();
    } else {
      toggleOpenExplanation();
    }
  }, [cantWithdrawSurplus, toggleOpen, toggleOpenExplanation]);
  return (
    <>
      <LoadingButton
        color="primary"
        variant="containedReverse"
        fullWidth
        disableElevation
        sx={{ mt: 2 }}
        onClick={handleButton}
        loading={loading}
        disabled={cessionsSummary?.cessionsSummary.availableSurplus === 0}
        id="withdraw-surplus"
      >
        {cantWithdrawSurplus() ? '¿Por qué no puedo retirar excedentes?' : 'Retirar excedentes'}
      </LoadingButton>
      <FingoDialog
        open={open}
        handleClose={toggleOpen}
        title="Seleccione la cuenta del banco"
        dialogActionButton={(
          <Button onClick={handleSubmit} disabled={!selectedAccount}>
            Continuar
          </Button>
        )}
      >
        <AccountsListWithAdd
          setSelectedAccountInParent={setSelectedAccount}
          xs={12}
          setCreatingNewAccount={() => {}}
          withSelect
        />
      </FingoDialog>
      <CantWithdrawSurplusExplanation
        open={openExplanation}
        onClose={toggleOpenExplanation}
      />
    </>
  );
};

export default Surplus;
