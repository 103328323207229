import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
} from '@mui/x-data-grid';
import { useMutation, useQuery } from '@apollo/client';
import { Tooltip, Grid, InputAdornment } from '@mui/material';
import { GET_USER, ADD_OR_EDIT_CONTACT, ERASE_CONTACT, INVITE_CONTACT } from '@fingo/lib/graphql';
import ValidationTextFieldDataGrid from '../../../components/inputs/ValidationTextFieldDataGrid';

function EditToolbar(props) {
  const { setRows, setRowModesModel, id } = props;
  const handleClick = () => {
    setRows((oldRows) => [...oldRows, { id, name: '', phoneNumber: '', position: '', email: '', isNew: true }]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Añadir
      </Button>
    </GridToolbarContainer>
  );
}

EditToolbar.propTypes = {
  setRowModesModel: PropTypes.func.isRequired,
  setRows: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
};

export default function ContactGrid({ data, company, configureSnackBar }) {
  const [rows, setRows] = useState(data);
  const [rowModesModel, setRowModesModel] = useState({});
  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };
  const { data: {
    getUser: {
      selectedCompany: {
        rut: selectedCompanyRut,
      },
    },
  } } = useQuery(GET_USER);
  const [createOrEditContact] = useMutation(
    ADD_OR_EDIT_CONTACT,
    {
      onCompleted: (response) => {
        configureSnackBar(
          response.createOrEditContact.messageType,
          response.createOrEditContact.message,
        );
      },
      onError: (error) => {
        configureSnackBar(
          'error',
          error.message,
        );
      },
    },
  );
  const [eraseContact] = useMutation(
    ERASE_CONTACT,
    {
      onCompleted: (response) => {
        configureSnackBar(
          response.eraseContact.messageType,
          response.eraseContact.message,
        );
      },
      onError: (error) => {
        configureSnackBar(
          'error',
          error.message,
        );
      },
    },
  );
  const [inviteContact] = useMutation(INVITE_CONTACT, {
    onCompleted: (response) => {
      configureSnackBar(
        response.inviteContact.messageType,
        response.inviteContact.message,
      );
    },
    onError: (error) => {
      configureSnackBar(
        'error',
        error.message,
      );
    },
  });
  const handleSaveClick = (id) => async () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View },
    });
  };
  const handleProcessRowUpdate = React.useCallback(
    async (newRow) => {
      // Make the HTTP request to save in the backend
      const response = await createOrEditContact({
        variables: { isNew: false, company, ...newRow },
      });
      return response.data.createOrEditContact.contact;
    },
    [createOrEditContact],
  );

  const handleProcessRowUpdateError = React.useCallback((error) => {
    configureSnackBar('error', error.message);
  }, []);

  const handleDeleteClick = (id) => async () => {
    eraseContact(
      { variables: { id, company } },
    );
    setRows(rows.filter((row) => row.id !== id));
  };
  const handleAddFingoClick = (contactId) => async () => {
    setRows(rows.map((row) => (row.id !== contactId ? row : { ...row, userInstance: true })));
    inviteContact({ variables: { contactId, companyFrom: selectedCompanyRut } });
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };
  const columns = [
    { field: 'name', headerName: 'Nombre', flex: 1, editable: true },
    {
      field: 'phoneNumber',
      headerName: 'Celular',
      type: 'number',
      align: 'left',
      headerAlign: 'left',
      flex: 1,
      editable: true,
      renderCell: (params) => (
        <>
          <b>
            +56
          </b>
          {params.value}
        </>
      ),
      renderEditCell: (params) => (
        <ValidationTextFieldDataGrid
          id={params.id}
          value={params.value}
          defaultValue={params.value}
          field={params.field}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                +56
              </InputAdornment>),
            disableUnderline: true,
          }}
          validationtype="phone"
        />
      ),
    },
    {
      field: 'position',
      headerName: 'Cargo',
      flex: 1,
      editable: true,
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
      editable: true,
      renderEditCell: (params) => (
        <ValidationTextFieldDataGrid
          id={params.id}
          value={params.value}
          field={params.field}
          defaultValue={params.value}
          InputProps={{
            disableUnderline: true,
          }}
          validationtype="email"
        />
      ),
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Acción',
      flex: 0.5,
      cellClassName: 'actions',
      getActions: ({ id, row: { userInstance, email } }) => {
        const isInEditMode = rowModesModel[id] && rowModesModel[id].mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            sx={{ padding: 0 }}
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            sx={{ padding: 0 }}
            onClick={handleDeleteClick(id)}
            color="inherit"
          />, !userInstance && email
            ? (
              <GridActionsCellItem
                icon={<Tooltip title="Invitar" placement="right"><ForwardToInboxIcon color="primary" /></Tooltip>}
                label="Add"
                sx={{ padding: 0 }}
                className="textPrimary"
                onClick={handleAddFingoClick(id)}
                color="inherit"
              />
            ) : (
              <GridActionsCellItem
                icon={<DoneAllIcon />}
                label="Add"
                sx={{ padding: 0 }}
                className="textPrimary"
                color="inherit"
                disabled
              />
            ),
        ];
      },
    },
  ];
  return (
    <Grid height="400px">
      <DataGrid
        rows={rows}
        columns={columns}
        disableColumnFilter
        disableColumnMenu
        editMode="row"
        rowModesModel={rowModesModel}
        processRowUpdate={handleProcessRowUpdate}
        onProcessRowUpdateError={handleProcessRowUpdateError}
        components={{
          Toolbar: (props) => (
            <EditToolbar
              {...props}
              id={rows.length ? Math.max(...rows.map((row) => row.id)) + 1 : 1}
            />
          ),
          Pagination: () => <></>,
        }}
        componentsProps={{
          toolbar: { setRows, setRowModesModel },
        }}
        getRowHeight={() => 'auto'}
        experimentalFeatures={{ newEditingApi: true }}
      />
    </Grid>
  );
}
ContactGrid.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.array.isRequired,
  company: PropTypes.number.isRequired,
  configureSnackBar: PropTypes.func.isRequired,
};
