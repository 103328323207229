import React from 'react';
import PropTypes from 'prop-types';
import { NPS } from '../dialogs';

const NpsProvider = ({ children }) => (
  <>
    <NPS />
    {children}
  </>
);

NpsProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default NpsProvider;
