import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { LoggedInNavItems, ProfileNavItem } from '@fingo/lib/components/layout/navbar';
import { GET_USER } from '@fingo/lib/graphql';
import { Stack } from '@mui/material';
import Notifications from '../../../views/App/notifications/NotificationMenu';
import ShoppingCart from '../../shopping/shoppingCart';
import SelectCompanyNavItem from './SelectCompanyNavItem';
import useFingoRoutes from '../../../hooks/useFingoRoutes';

const LoggedInDesktopNavItems = () => {
  const appLinks = useFingoRoutes();
  const [profileMissing, setProfileMissing] = useState(0);
  const [credentialsMissing, setCredentialsMissing] = useState(0);
  const { data: { getUser: { selectedCompany } } } = useQuery(
    GET_USER,
    {
      onCompleted: ({ getUser }) => {
        setProfileMissing(getUser?.hasProfileInfoMissing || 0);
        setCredentialsMissing(getUser?.selectedCompany?.hasMissingCredentials || 0);
      },
    },
  );

  return (
    <Stack
      width="100%"
      direction="row"
      spacing={3}
      height="100%"
      alignItems="center"
      justifyContent="space-around"
      ml={4}
    >
      <LoggedInNavItems pages={appLinks} />
      <SelectCompanyNavItem />
      <Stack direction="row" spacing={1}>
        {selectedCompany && (
          <>
            <Notifications />
            <ShoppingCart />
          </>
        )}
        <ProfileNavItem
          credentialsMissing={credentialsMissing}
          profileMissing={profileMissing}
        />
      </Stack>
    </Stack>
  );
};

export default LoggedInDesktopNavItems;
