import { useState } from 'react';
import { calculateDv, prettifyRut, removeSeparators } from 'react-rut-formatter';

const useInputRut = (initialValue) => {
  const [rut, setRut] = useState(initialValue || null);
  const formattedRut = prettifyRut(rut);
  const updateRut = (newRut) => {
    setRut(removeSeparators(newRut));
  };
  // eslint-disable-next-line no-void
  const rutWithoutDv = formattedRut === null || formattedRut === void 0 ? void 0 : formattedRut.slice(0, -1).replace(/\./g, '');
  const dv = formattedRut.charAt(formattedRut.length - 1);
  const isValid = rutWithoutDv !== ''
        && rutWithoutDv !== '0-'
        && dv === calculateDv(parseInt(rutWithoutDv, 10));
  return {
    updateRut,
    rut: {
      formatted: formattedRut !== '0-0' ? formattedRut : '',
      raw: formattedRut !== '0-0' ? `${rutWithoutDv}${dv}` : '',
    },
    isValid,
  };
};

export default useInputRut;
