import { Button, Stack, Typography } from '@mui/material';
import React from 'react';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import PropTypes from 'prop-types';

const SentToEvaluationCart = ({ nextStep }) => (
  <Stack spacing={3} alignItems="center">
    <Typography align="center" variant="h5">
      ¡Listo!
    </Typography>
    <Typography align="center">
      Tu factura ha sido enviada a evaluación, te notificaremos cuando tengamos una respuesta
    </Typography>
    <TaskAltOutlinedIcon sx={{ fontSize: 80 }} />
    <Button
      variant="contained"
      size="small"
      color="primary"
      onClick={nextStep}
      id="cart-ok-evaluation"
    >
      OK
    </Button>
  </Stack>
);

SentToEvaluationCart.propTypes = {
  nextStep: PropTypes.func.isRequired,
};

export default SentToEvaluationCart;
