import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Typography, Tooltip, Link } from '@mui/material';

function traceToLabel(invoice, value, event) {
  const { providerRejectionReasons = [], creditNotes, loanedTo, folio } = invoice;
  const creditNoteLabel = (labelFinish) => (creditNoteIndex) => {
    if (!creditNotes[creditNoteIndex]) {
      return `Factura #${folio} anulada con nota de crédito por monto ${labelFinish}.`;
    }
    return (
      <>
        Factura {`#${folio} anulada con `}
        <Link
          component={RouterLink}
          to={{
            pathname: '/app/invoice',
            search: `?invoiceId=${creditNotes[creditNoteIndex].id}`,
          }}
          target="_blank"
          rel="noopener noreferrer"
        >
          nota de crédito #{creditNotes[creditNoteIndex].folio}
        </Link>
        {' por monto'} {labelFinish}.
      </>
    );
  };
  const loanedLabel = (numFolio) => {
    const loanedReceptor = loanedTo ? `a ${loanedTo}` : '';
    return `Factura #${numFolio} cedida ${loanedReceptor}`;
  };
  const rejectionLabel = (docFolio, sentenceEnd) => (
    <>
      Factura #{docFolio} {sentenceEnd}
      {!!providerRejectionReasons.length && (
      <Tooltip title={providerRejectionReasons[0].comments}>
        <Typography sx={{ fontSize: '14px !important', fontWeight: 'light', textAlign: 'justify', pr: 4 }}>
          - {providerRejectionReasons[0].rejectionCode.reason}.
        </Typography>
      </Tooltip>
      )}
    </>
  );
  const paymentLabel = (status, paymentDate, bankName) => (paymentDate ? `${status} el ${paymentDate.format('DD-MM-YYYY')}.${bankName}` : status);
  const { paymentDate = null, status: { status = null } = {}, bank = null } = event || {};
  const bankName = bank ? `Fue depositado a través del banco ${bank.name}` : '';
  return {
    ERM: { label: `Se da acuse de recibo a factura #${folio}.` },
    ACD: { label: `Se acepta contenido del documento a factura #${folio}.` },
    ENC: { label: creditNoteLabel('total') },
    NCA: { label: creditNoteLabel('parcial') },
    RCD: { label: rejectionLabel(folio, 'con reclamos al contenido.') },
    RFT: { label: rejectionLabel(folio, 'rechazada por falta total de servicios.') },
    RFP: { label: rejectionLabel(folio, 'rechzada por falta parcial de servicios.') },
    CED: { label: loanedLabel(folio) },
    PAG: { label: `Factura #${folio} emitida al contado.` },
    6: { label: paymentLabel(status, paymentDate, bankName), isProviderInfo: true },
    7: { label: paymentLabel(status, paymentDate, bankName), isProviderInfo: true },

  }[value] ?? { label: status, isProviderInfo: true };
}

export default traceToLabel;
