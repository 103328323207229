/* eslint-disable max-len */
import React, { useState } from 'react';
import {
  Grid,
  Link,
  Typography,
} from '@mui/material';
import TermsAndConditionsDialog from '../dialogs/TermsAndConditionsDialog';

const TermsAndConditionsText = () => {
  const [openTerms, setOpenTerms] = useState(false);
  return (
    <Grid
      sx={{
        width: '70%',
        marginTop: '1em',
        textAlign: 'center',
      }}
    >
      <Typography
        variant="caption"
      >
        Al hacer click en Aceptar, aceptas los
      </Typography>
      <Link
        component="button"
        variant="caption"
        style={{ whiteSpace: 'pre' }}
        onClick={() => {
          setOpenTerms(true);
        }}
      >
        {' '}términos y condiciones
      </Link>
      <Typography
        variant="caption"
      >
        {' '}de uso de nuestra plataforma.
      </Typography>
      <TermsAndConditionsDialog open={openTerms} setOpen={setOpenTerms} />
    </Grid>
  );
};

export default TermsAndConditionsText;
