import React from 'react';
import { useQuery } from '@apollo/client';
import { FingoNavbar } from '@fingo/lib/components/layout';
import { LoggedOutNavItems, LogoNavItem } from '@fingo/lib/components/layout/navbar';
import { useIsLogged } from '@fingo/lib/hooks';
import { GET_USER } from '@fingo/lib/graphql';
import { FingoLogo } from '@fingo/lib/assets';
import LoggedInDesktopNavItems from './LoggedInDesktopNavItems';

const DesktopNavbar = () => {
  const isLogged = useIsLogged();
  const { data: userData } = useQuery(GET_USER);

  return (
    <FingoNavbar>
      <LogoNavItem logo={FingoLogo} id="nav-logo" />
      {isLogged && userData?.getUser && (
        <LoggedInDesktopNavItems />
      )}
      {!isLogged && <LoggedOutNavItems />}
    </FingoNavbar>
  );
};

export default DesktopNavbar;
