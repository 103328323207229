import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Close } from '@mui/icons-material';
import React from 'react';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  dialogPaper: {
    borderRadius: 16,
  },
}));

const FingoDialog = ({ open, handleClose, title, children, dialogActionButton }) => {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitle
        component="div"
        sx={{
          borderRadius: 4,
          bgcolor: 'primary.main',
          py: 3,
        }}
      >
        <Typography color="white" align="center" variant="h4">{title}</Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
          color="fingoWhite"
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ mt: 2, px: 6 }}>
        {children}
      </DialogContent>
      <DialogActions>
        {dialogActionButton}
      </DialogActions>
    </Dialog>
  );
};

FingoDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  dialogActionButton: PropTypes.element,
};
FingoDialog.defaultProps = {
  dialogActionButton: null,
};

export default FingoDialog;
