import React, { useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { FingoSnackBar } from '@fingo/lib/components/snackBars';
import { PaperHeader } from '@fingo/lib/components/headers';
import { GET_CONTACTS, PROVIDER_EXCEL_TEMPLATE, UPLOAD_CONTACTS_EXCEL } from '@fingo/lib/graphql';
import { formatRut, groupIt, openUrlAndDownload } from '@fingo/lib/helpers';
import { useSelectedCompany, useFilteredQuery } from '@fingo/lib/hooks';
import { Accordion, AccordionDetails, AccordionSummary, Stack, Typography, Skeleton } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import ContactGrid from './ContactGrid';
import { CustomPagination } from '../../../components/tables';
import UploadDropzone from '../../../components/dialogs/UploadDropzone';

const Contacts = () => {
  const rowNumber = 15;
  const [page, setPage] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [globalFilter, setGlobalFilter] = useState('');
  const [preparedData, setPreparedData] = useState(0);
  const [selectedFile, setSelectedFile] = useState({ name: '' });
  const [openExcelModal, setOpenExcelModal] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState();
  const [snackBarType, setSnackBarType] = useState();
  const configureSnackBar = (type, message) => {
    setSnackBarMessage(message);
    setSnackBarType(type);
    setOpenSnackBar(true);
  };
  const setFilter = (props) => { setGlobalFilter(props); setPage(0); };
  const selectedCompany = useSelectedCompany();
  const { loading, refetch: refetchContacts } = useFilteredQuery(
    GET_CONTACTS,
    {
      variables: {
        companySuppliers: selectedCompany?.rut,
        first: rowNumber,
        offset: page * rowNumber,
        globalFilter,
      },
      skip: !selectedCompany,
      notifyOnNetworkStatusChange: true,
      onCompleted: ({ getMasterEntities: { edges, totalPages } }) => {
        setPreparedData(groupIt(edges.map((edge) => (edge.node))));
        setPageNumber(totalPages);
      },
    },
  );
  const [downLoadExcelTemplate] = useLazyQuery(PROVIDER_EXCEL_TEMPLATE, {
    onCompleted: (_data) => openUrlAndDownload(_data.exportTemplateContacts, 'excel_de_ejemplo.xlsx'),
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });
  const handleSearchChange = (value) => {
    setFilter(value);
  };
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  return (
    <>
      <PaperHeader
        viewTitle="Proveedores"
        finder={{
          onFinderChange: handleSearchChange,
          searchPlaceHolder: 'Buscar proveedor',
          finderValue: globalFilter,
        }}
      />
      <Stack alignItems="center" width="100%" spacing={3}>
        {Object.keys(preparedData).map((letter) => (
          <Stack width="100%" key={letter} spacing={1}>
            <Typography
              color="primary"
              variant="h6"
            >
              {letter}
            </Typography>
            {preparedData[letter].map((company) => (
              <Accordion
                elevation={0}
                key={company.rut}
                expanded={expanded === company.rut}
                onChange={handleChange(company.rut)}
                sx={{ bgcolor: '#f9f9f9', '&:before': { display: 'none' } }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  id="providers-list-header"
                  sx={{ px: 4 }}
                >
                  {loading ? <Skeleton variant="text" width="90%" /> : (
                    <>
                      <Typography variant="h2" fontWeight={700}>{company.name} &nbsp;&nbsp;</Typography>
                      <Typography variant="h2" ml={2}>{formatRut(company.rut)}</Typography>
                    </>
                  )}
                </AccordionSummary>
                <AccordionDetails>
                  <ContactGrid
                    data={company.company.contacts}
                    company={company.company.rut}
                    configureSnackBar={configureSnackBar}
                    refetchContacts={refetchContacts}
                  />
                </AccordionDetails>
              </Accordion>
            ))}
          </Stack>
        ))}
      </Stack>
      <CustomPagination
        page={page}
        totalPages={pageNumber}
        onChangePage={handleChangePage}
      />
      <FingoSnackBar
        open={openSnackBar}
        setOpen={setOpenSnackBar}
        message={snackBarMessage}
        severity={snackBarType}
      />
      <UploadDropzone
        open={openExcelModal}
        setOpen={setOpenExcelModal}
        file={selectedFile}
        setFile={setSelectedFile}
        UPLOAD_MUTATION={UPLOAD_CONTACTS_EXCEL}
        configureSnackBar={configureSnackBar}
        downloadTemplate={downLoadExcelTemplate}
        message="Arrastra el archivo que posee los proveedores que deseas importar (Formato XLSX o CSV - máximo 10Mb)"
      />
    </>
  );
};

export default Contacts;
