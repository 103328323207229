import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { preofferSelectedQuery } from '@fingo/lib/apollo/cache/localQuery';
import PreofferShoppingStepOne from './PreofferShoppingStepOne';
import PreofferShoppingStepTwo from './PreofferShoppingStepTwo';
import SentToEvaluationCart from '../SentToEvaluationCart';
import BaseShoppingCart from '../BaseShoppingCart';

const PreofferShoppingCart = ({ toggleMenu }) => {
  const [step, setStep] = useState(0);
  const { data: { selectedPreofferIds } } = useQuery(preofferSelectedQuery);
  const prevStep = () => setStep((prev) => prev - 1);
  const nextStep = () => setStep((prev) => prev + 1);
  const stepElements = [
    <PreofferShoppingStepOne nextStep={nextStep} />,
    <PreofferShoppingStepTwo prevStep={prevStep} nextStep={nextStep} />,
    <SentToEvaluationCart nextStep={toggleMenu} />,
  ];
  return (
    <BaseShoppingCart
      stepElements={stepElements}
      title="Pre evaluaciones"
      id="cart-preoffer-header"
      selected={selectedPreofferIds.length}
      step={step}
    />
  );
};

PreofferShoppingCart.propTypes = {
  toggleMenu: PropTypes.func.isRequired,
};

export default PreofferShoppingCart;
