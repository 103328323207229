import { CircularProgress, Icon } from '@mui/material';
import React from 'react';
import ProgressiveImage from 'react-progressive-graceful-image';
import PropTypes from 'prop-types';

const LoaderProgressiveImage = ({ src, alt, height, width }) => (
  <ProgressiveImage src={src} placeholder="">
    {(newSrc, loading) => {
      if (loading) {
        return <CircularProgress color="gray" />;
      }
      return (
        <Icon sx={{ width, height }}>
          <img src={newSrc} alt={alt} style={{ height: 'inherit', marginRight: 'auto', marginLeft: 'auto' }} />
        </Icon>
      );
    }}
  </ProgressiveImage>
);

LoaderProgressiveImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default LoaderProgressiveImage;
