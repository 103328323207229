import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

const ProfileTable = ({ headers, data }) => (
  <Grid style={{ maxHeight: 250, overflow: 'auto' }}>
    {data.map((profile, index) => (
      <Box key={profile.id} sx={{ mb: 2, '&:last-child': { mb: 0 } }}>
        {headers.map((header) => (
          <Typography sx={{ py: 0.5 }} variant="body2" key={`${header.label}: ${header.getValue(profile)}`}>
            <Typography component="span" variant="h6">{header.label}</Typography>:
            {' '}{header.getValue(profile)}
          </Typography>
        ))}
        {index + 1 < data.length && '------'}
      </Box>
    ))}
  </Grid>
);

ProfileTable.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    getValue: PropTypes.func,
  })).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape).isRequired,
};

export default ProfileTable;
