import React, { useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { FullHeightBox } from '@fingo/lib/components/boxes';
import { PasswordInput, ValidationTextFieldInput } from '@fingo/lib/components/inputs';
import { Wave } from '@fingo/lib/assets';
import { WelcomeHeader } from '@fingo/lib/components/headers';
import { Paper, Grid, Link, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { isLoggedInQuery } from '@fingo/lib/apollo/cache/localQuery';
import { useIsLogged } from '@fingo/lib/hooks';
import PasswordRecovery from '../components/dialogs/PasswordRecovery';
import AceptaAuth from '../components/dialogs/AceptaAuth';
import useLogin from '../hooks/useLogin';

const MARGIN = 3;
const TEXT_WIDTH = 250;
const Login = () => {
  const [formInput, setFormInput] = useState({ email: '', password: '' });
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const isLogged = useIsLogged(isLoggedInQuery);
  const [openPasswordRecovery, setOpenPasswordRecovery] = useState(false);
  const [aceptaAuthOpen, setAceptaAuthOpen] = useState(params.get('acepta') === 'true');
  const [loginGql, loadingLogin, errorForm] = useLogin(formInput.email, formInput.password);
  const formChange = (variable, event) => {
    setFormInput((prev) => ({ ...prev, [variable]: event.target.value }));
  };
  if (isLogged) return <Redirect to={params.get('next') || '/'} />;
  return (
    <FullHeightBox
      sx={{
        backgroundImage: `url(${Wave})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% auto',
        backgroundPosition: 'bottom',
      }}
    >
      <WelcomeHeader topMessage="Iniciar" headerMessage="¡Bienvenido nuevamente!" bottomMessage="sesión" />
      <Paper
        sx={{
          width: 450,
          pb: 8,
          mr: 'auto',
          ml: 'auto',
        }}
        elevation={0}
      >
        <form
          onSubmit={(event) => {
            event.preventDefault();
            loginGql();
          }}
          id="loginForm"
        >
          <Grid container alignItems="center" direction="column">
            <ValidationTextFieldInput
              name="email"
              type="text"
              label="Email"
              variant="standard"
              sx={{ width: TEXT_WIDTH, mt: 8 }}
              value={formInput.email}
              onChange={(event) => formChange('email', event)}
            />
            <PasswordInput
              name="password"
              label="Contraseña"
              variant="standard"
              sx={{ width: TEXT_WIDTH, mt: MARGIN }}
              value={formInput.password}
              onChange={(event) => formChange('password', event)}
              error={errorForm?.password}
              helperText={errorForm.password}
            />
            <Link
              underline="hover"
              variant="body2"
              sx={{
                color: 'text.primary',
                mt: MARGIN,
                fontSize: 12,
              }}
              onClick={() => setOpenPasswordRecovery(true)}
              id="login-forgot"
            >
              ¿Olvidaste tu contraseña?
            </Link>
            <LoadingButton
              variant="contained"
              color="primary"
              size="small"
              sx={{
                mt: MARGIN,
                width: 200,
              }}
              type="submit"
              loading={loadingLogin}
              disabled={!formInput.email.length || !formInput.password.length}
              id="login-enter"
            >
              Ingresar
            </LoadingButton>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              sx={{
                mt: 1,
                width: 200,
              }}
              onClick={() => setAceptaAuthOpen(true)}
              id="login-acepta"
            >
              Ingresar con Acepta
            </Button>
          </Grid>
        </form>
      </Paper>
      <PasswordRecovery
        open={openPasswordRecovery}
        onClose={() => setOpenPasswordRecovery(false)}
      />
      <AceptaAuth
        open={aceptaAuthOpen}
        onClose={() => setAceptaAuthOpen(false)}
      />
    </FullHeightBox>
  );
};

export default Login;
