import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { MinHeightBox } from '@fingo/lib/components/boxes';
import { useTheme } from '@mui/styles';
import Header from './Header';

const Layout = ({ children }) => {
  const location = useLocation();
  const theme = useTheme();
  useEffect(() => {
    document.body.style.backgroundColor = theme.palette.background.dark;
  }, [theme]);
  if (location.pathname === '/debtor-payment-checker') return <div>{children}</div>;
  return (
    <>
      <Header />
      <MinHeightBox>
        {children}
      </MinHeightBox>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Layout;
