import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
  Typography,
} from '@mui/material';

const firstParagraph = 'Tus excedentes no pueden ser retirados porque tienes una o más facturas en mora. Apenas regularices la situación podrás hacer el retiro.';
const secondParagraph = 'Esto es un resguardo en caso de que tus clientes no paguen las facturas que están morosas.';

const CantWithdrawSurplusExplanation = ({ open, onClose }) => (
  <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">¿Por qué no puedo retirar mis excedentes?</DialogTitle>
    <DialogContent>
      <Typography gutterBottom paragraph>{firstParagraph}</Typography>
      <Typography>{secondParagraph}</Typography>
    </DialogContent>
    <DialogActions>
      <Button
        onClick={onClose}
        color="primary"
      >
        Entendido
      </Button>
    </DialogActions>
  </Dialog>
);

CantWithdrawSurplusExplanation.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CantWithdrawSurplusExplanation;
