import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { UPLOAD_INVOICING_INSTRUCTIONS } from '@fingo/lib/graphql';
import { Button, CircularProgress, Grid, IconButton, Typography } from '@mui/material';
import { AttachFile, CloudUpload } from '@mui/icons-material';

const UploadInvoicingInstructions = ({ receiverRut }) => {
  const [selectedFile, setSelectedFile] = useState({ name: '' });
  const [uploadInstructions,
    {
      loading,
    },
  ] = useMutation(UPLOAD_INVOICING_INSTRUCTIONS, {
    onCompleted: (data) => {
      setSelectedFile({
        ...selectedFile,
        message: data.uploadInvoicingInstructions.message,
      });
    },
    onError: ({ message }) => { setSelectedFile({ name: '', message }); },
  });

  return (
    <>
      <Grid>
        <Button
          component="label"
          size="small"
          color="text"
        >
          <AttachFile />
          {selectedFile.name || 'Adjuntar archivo' }
          <input
            type="file"
            onChange={(e) => { e.preventDefault(); setSelectedFile(e.target.files[0]); }}
            hidden
          />
        </Button>
        { selectedFile.size && (
        <IconButton
          id="settings-upload-instructions"
          onClick={() => {
            uploadInstructions({
              variables: { companyRut: receiverRut, file: selectedFile },
            });
          }}
          disabled={!selectedFile || loading}
          size="small"
        >
          {loading ? <CircularProgress /> : <CloudUpload color="primary" />}
        </IconButton>
        )}
      </Grid>
      <Typography variant="caption" style={{ fontWeight: 900 }}>{selectedFile.message}</Typography>
    </>
  );
};
export default UploadInvoicingInstructions;

UploadInvoicingInstructions.propTypes = {
  receiverRut: PropTypes.number.isRequired,
};
