import React from 'react';
import { useQuery } from '@apollo/client';
import { useSelectedCompany, useSavedState } from '@fingo/lib/hooks';
import { Grid } from '@mui/material';
import { SOPHIA_GRAPHS } from '@fingo/lib/graphql';
import GraphByDay from './GraphByDay';
import GraphByMonth from './GraphByMonth';
import MenuFingoButton from '../menu/MenuFingoButton';

const groupOptions = [
  { name: 'Mes', value: 'month' },
  { name: 'Día', value: 'day' },
];

const accumulatedOptions = [
  { name: 'Acumulado', value: true },
  { name: 'No acumulado', value: false },
];

const Graphs = () => {
  const [accumulated, setAccumulated] = useSavedState(accumulatedOptions[1].value, 'sophia-accumulated');
  const [group, setGroup] = useSavedState(groupOptions[0].value, 'sophia-grouped');
  const selectedCompany = useSelectedCompany();
  const { data } = useQuery(
    SOPHIA_GRAPHS,
    { variables: { accumulated, group }, skip: !selectedCompany },
  );
  const buttonWidth = 150;
  return (
    <Grid container justifyContent="space-between" spacing={2} id="graphs">
      <Grid container item spacing={2}>
        <Grid item sx={{ minWidth: buttonWidth }}>
          <MenuFingoButton
            value={group}
            setValue={setGroup}
            options={groupOptions}
          />
        </Grid>
        <Grid item sx={{ minWidth: buttonWidth }}>
          <MenuFingoButton
            value={accumulated}
            setValue={setAccumulated}
            options={accumulatedOptions}
          />
        </Grid>
      </Grid>
      {group === 'day' && (
        <>
          <Grid item md={6} xs={12}>
            <GraphByDay queryValue={data?.sophiaSalesGraph} accumulated={accumulated} title="Ventas facturadas por día" />
          </Grid>
          <Grid item md={6} xs={12}>
            <GraphByDay queryValue={data?.sophiaPurchasesGraph} accumulated={accumulated} title="Compras facturadas por día" />
          </Grid>
        </>
      )}
      {group === 'month' && (
        <>
          <Grid item md={6} xs={12}>
            <GraphByMonth queryValue={data?.sophiaSalesGraph} accumulated={accumulated} title="Ventas facturadas por mes" />
          </Grid>
          <Grid item md={6} xs={12}>
            <GraphByMonth queryValue={data?.sophiaPurchasesGraph} accumulated={accumulated} title="Compras facturadas por mes" />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default Graphs;
