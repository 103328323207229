import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/client';
import { GET_USER, EDIT_BANK_ACCOUNT, GET_BANK_ACCOUNT_TYPES } from '@fingo/lib/graphql';
import { validEmail, formatRut } from '@fingo/lib/helpers';
import {
  Typography,
  TextField,
  FormControl,
  Button,
  IconButton,
  Select,
  MenuItem,
  Grid,
  useTheme,
  useMediaQuery,
  CircularProgress,
} from '@mui/material';
import { ArrowBackIos } from '@mui/icons-material';
import makeStyles from '@mui/styles/makeStyles';
import { banks } from './data';

const useStyles = makeStyles({
  label: {
    marginBottom: '0.5rem',
  },
  input: {
    width: '100%',
    marginBottom: '1rem',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  error: {
    marginBottom: '0.5rem',
    marginLeft: '0.5rem',
  },
});

const EditAccount = ({ cancel, onAccountEdit, accounts, account }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), { defaultMatches: true });
  const { data: { getUser: { selectedCompany } } } = useQuery(GET_USER);
  const { data: accountTypes, loading: loadingAccountTypes } = useQuery(GET_BANK_ACCOUNT_TYPES);
  const [editBankAccount, { loading }] = useMutation(
    EDIT_BANK_ACCOUNT,
    {
      refetchQueries: [GET_USER],
      awaitRefetchQueries: true,
      onCompleted: (data) => { onAccountEdit(data.editBankAccount.bankAccount); },
      onError: () => { onAccountEdit(null); },
    },
  );
  const [data, setData] = useState({
    number: { value: parseInt(account.accountNumber, 10), error: '' },
    name: { value: account.accountName, error: '' },
    email: { value: account.accountEmail, error: '' },
    bank: { value: account.bankName, error: '' },
    accountType: { value: account.accountType, error: '' },
  });
  const [formError, setFormError] = useState('');
  const [accountTypeError, setAccountTypeError] = useState('');

  const handleChange = (event) => {
    const { name, value } = event.target;
    setData((oldData) => ({ ...oldData, [name]: { ...oldData[name], value } }));
    if (name === 'bank') {
      setFormError('');
    } else if (name === 'accountType') {
      setAccountTypeError('');
    }
  };

  const cleanError = (field) => {
    setData((oldData) => ({ ...oldData, [field]: { ...oldData[field], error: '' } }));
  };

  const handleEdit = async () => {
    const { number, name, email, bank, accountType } = data;
    if (name.value === '') {
      setData((oldData) => ({ ...oldData, name: { ...oldData.name, error: 'El nombre de la cuenta no puede estar vacío' } }));
      return;
    }
    cleanError('name');
    if (number.value === '') {
      setData((oldData) => ({ ...oldData, number: { ...oldData.number, error: 'El número de la cuenta no puede estar vacío' } }));
      return;
    }
    cleanError('number');
    if (bank.value === '') {
      setFormError('Debes elegir algún banco');
      return;
    }
    setFormError('');
    if (accountType.value === '') {
      setAccountTypeError('Debes elegir algún tipo de cuenta');
      return;
    }
    setAccountTypeError('');
    if (!validEmail(email.value)) {
      setData((oldData) => ({ ...oldData, email: { ...oldData.email, error: 'Ingresa un email válido' } }));
      return;
    }
    cleanError('email');
    for (let i = 0; i < accounts.length; i += 1) {
      const accountExists = accounts[i];
      if (accountExists.bankName === bank.value && accountExists.accountNumber === number.value) {
        setData((oldData) => ({ ...oldData, number: { ...oldData.number, error: 'Ya registraste esta cuenta' } }));
        return;
      }
    }
    cleanError('number');
    setFormError('');
    editBankAccount({
      variables: {
        id: account.id,
        bankName: bank.value,
        accountEmail: email.value,
        accountName: name.value,
        accountNumber: number.value,
        accountType: accountType.value,
      },
    });
  };

  return (
    <>
      <Grid container alignItems="center" sx={{ marginBottom: '2rem' }}>
        <Grid item xs={2}>
          <IconButton onClick={cancel} size="large">
            <ArrowBackIos />
          </IconButton>
        </Grid>
        <Grid item xs={10}>
          <Typography color="primary" variant="h6">Edita tu cuenta bancaria</Typography>
        </Grid>
      </Grid>
      <Typography className={classes.label}><b>Rut</b></Typography>
      <TextField
        className={classes.input}
        variant="outlined"
        name="rut"
        value={formatRut(selectedCompany.rut)}
        disabled
      />
      <Typography className={classes.label}><b>Titular de la cuenta</b></Typography>
      <TextField
        className={classes.input}
        variant="outlined"
        name="name"
        value={data.name.value}
        error={data.name.error !== ''}
        helperText={data.name.error}
        onChange={handleChange}
      />
      <Typography className={classes.label}><b>Número de la cuenta</b></Typography>
      <TextField
        className={classes.input}
        variant="outlined"
        name="number"
        type="number"
        value={data.number.value}
        error={data.number.error !== ''}
        helperText={data.number.error}
        onChange={handleChange}
      />
      <Typography className={classes.label}><b>Banco</b></Typography>
      <FormControl variant="outlined" fullWidth>
        <Select
          className={classes.input}
          name="bank"
          value={data.bank.value}
          error={formError !== ''}
          onChange={handleChange}
        >
          <MenuItem value="" disabled>Elige un banco</MenuItem>
          {banks.map((bank) => (
            <MenuItem key={bank.name} value={bank.name}>{bank.name}</MenuItem>
          ))}
        </Select>
        {formError && <Typography color="error" variant="caption" className={classes.error}>{formError}</Typography>}
      </FormControl>
      <Typography className={classes.label}><b>Tipo de cuenta</b></Typography>
      <FormControl variant="outlined" fullWidth>
        <Select
          className={classes.input}
          name="accountType"
          value={data.accountType.value}
          error={accountTypeError !== ''}
          onChange={handleChange}
        >
          <MenuItem value="" disabled>Elige un tipo de cuenta</MenuItem>
          {!loadingAccountTypes && accountTypes.getBankAccountTypes.map((type) => (
            <MenuItem key={type[1]} value={type[1]}>{type[1]}</MenuItem>
          ))}
        </Select>
        {accountTypeError && <Typography color="error" variant="caption" className={classes.error}>{accountTypeError}</Typography>}
      </FormControl>
      <Typography className={classes.label}><b>Email</b></Typography>
      <TextField
        className={classes.input}
        variant="outlined"
        name="email"
        value={data.email.value}
        error={data.email.error !== ''}
        helperText={data.email.error}
        onChange={handleChange}
      />
      <div className={classes.buttonsContainer}>
        <Button
          variant="contained"
          color="primary"
          sx={{
            width: '50%',
            maxWidth: '250px',
            height: '100%',
            alignSelf: isMobile ? 'center' : 'flex-end',
          }}
          onClick={handleEdit}
          endIcon={loading ? <CircularProgress size={16} /> : null}
          disabled={loading}
        >
          Editar cuenta
        </Button>
      </div>
    </>
  );
};

EditAccount.propTypes = {
  cancel: PropTypes.func.isRequired,
  onAccountEdit: PropTypes.func.isRequired,
  accounts: PropTypes.arrayOf(PropTypes.shape({
    bankName: PropTypes.string.isRequired,
    accountNumber: PropTypes.string.isRequired,
  })).isRequired,
  account: PropTypes.shape({
    id: PropTypes.number.isRequired,
    bankName: PropTypes.string.isRequired,
    accountNumber: PropTypes.string.isRequired,
    accountType: PropTypes.string.isRequired,
    accountEmail: PropTypes.string.isRequired,
    accountName: PropTypes.string.isRequired,
  }).isRequired,
};

export default EditAccount;
