import { Card, Typography, CircularProgress, Box } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import ProgressiveImage from 'react-progressive-graceful-image';

const ProviderCard = ({ value }) => (
  <Card sx={{ p: 2, height: 120, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    {value.iconUrl ? (
      <ProgressiveImage
        src={value.iconUrl}
        placeholder=""
      >
        {(src, loading) => {
          if (loading) {
            return <CircularProgress color="gray" />;
          }
          return (
            <Box
              component="img"
              sx={{
                width: { xs: '100%', md: '60%' },
                maxWidth: 950,
                height: 'auto',
              }}
              src={src}
              alt={value.name}
            />
          );
        }}
      </ProgressiveImage>
    ) : (<Typography variant="h5">{value.name}</Typography>)}
  </Card>
);

ProviderCard.propTypes = {
  value: PropTypes.shape({
    iconUrl: PropTypes.string,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default ProviderCard;
