import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { offerSelectedQuery } from '@fingo/lib/apollo/cache/localQuery';
import OfferShoppingStepOne from './OfferShoppingCartStepOne';
import BaseShoppingCart from '../BaseShoppingCart';
import OfferShoppingCartStepTwo from './OfferShoppingCartStepTwo';
import FingoOfferShoppingCartStepOne from './FingoOfferShoppingCartStepOne';
import ExternalOfferShoppingCartStepOne from './ExternalOfferShoppingCartStepOne';
import OkOperation from './OkOperation';

const OfferShoppingCart = ({ toggleMenu }) => {
  const [step, setStep] = useState(0);
  const [isFingo, setIsFingo] = useState(false);
  const { data: { selectedOfferIds } } = useQuery(offerSelectedQuery);
  const prevStep = useCallback(() => setStep((prev) => prev - 1), [setStep]);
  const nextStep = useCallback(() => setStep((prev) => prev + 1), [setStep]);
  const goToFingoCession = useCallback(() => {
    setIsFingo(true);
    nextStep();
  }, [setIsFingo, nextStep]);
  const goToExternalCession = useCallback(() => {
    setIsFingo(false);
    nextStep();
  }, [setIsFingo, nextStep]);
  const stepElements = [
    <OfferShoppingStepOne nextStep={nextStep} />,
    <OfferShoppingCartStepTwo
      goToFingoCession={goToFingoCession}
      goToExternalCession={goToExternalCession}
      prevStep={prevStep}
    />,
  ];
  const fingoCessionSteps = [
    <FingoOfferShoppingCartStepOne prevStep={prevStep} nextStep={nextStep} />,
  ];
  const externalCessionSteps = [
    <ExternalOfferShoppingCartStepOne prevStep={prevStep} nextStep={nextStep} />,
  ];
  return (
    <BaseShoppingCart
      stepElements={isFingo
        ? [...stepElements, ...fingoCessionSteps, <OkOperation toggleMenu={toggleMenu} />]
        : [...stepElements, ...externalCessionSteps, <OkOperation toggleMenu={toggleMenu} />]}
      title="Ofertas"
      id="cart-offer-header"
      selected={selectedOfferIds.length}
      step={step}
    />
  );
};

OfferShoppingCart.propTypes = {
  toggleMenu: PropTypes.func.isRequired,
};

export default OfferShoppingCart;
