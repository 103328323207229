import React from 'react';
import { useQuery } from '@apollo/client';
import { useBooleanState, useSelectedCompany } from '@fingo/lib/hooks';
import { Button, Grid } from '@mui/material';
import { CESSIONS_SUMMARY } from '@fingo/lib/graphql';
import { SummaryCard } from '@fingo/lib/components/cards';
import PorftolioDownload from './PorfolioDownload';
import Surplus from './Surplus';

const CessionsSummary = () => {
  const [portfolioOpen, togglePortfolio] = useBooleanState(false);
  const selectedCompany = useSelectedCompany();
  const { data: cessionsSummary } = useQuery(
    CESSIONS_SUMMARY,
    { variables: { companyId: selectedCompany?.rut }, skip: !selectedCompany },
  );
  return (
    <>
      <Grid
        container
        columnSpacing={1}
        rowSpacing={1}
        sx={{
          bgcolor: 'background.light',
          borderRadius: 2,
          px: 1,
          ml: 0,
          py: 1,
          '& .MuiGrid-item:first-of-type': {
            pl: 0,
          } }}
        justifyContent="space-between"
      >
        {cessionsSummary && (
        <>
          <Grid item xs={6} lg={2}>
            <SummaryCard
              title={cessionsSummary.cessionsSummary.amount}
              subtitle="Monto total de facturas cedidas"
              isMoney
            />
          </Grid>
          <Grid item xs={6} lg={2}>
            <SummaryCard
              title={cessionsSummary.cessionsSummary.invoiceCount}
              subtitle="Cantidad total de facturas operadas"
            />
          </Grid>
          <Grid item xs={6} lg={2}>
            <SummaryCard
              title={cessionsSummary.cessionsSummary.debtAmount}
              subtitle="Monto total de facturas en mora"
              isMoney
            />
          </Grid>
          <Grid item xs={6} lg={2}>
            <SummaryCard
              title={cessionsSummary.cessionsSummary.debtCount}
              subtitle="Cantidad total de facturas en mora"
            />
          </Grid>
          <Grid item xs={6} lg={2}>
            <SummaryCard
              title={cessionsSummary.cessionsSummary.availableSurplus}
              subtitle="Excedentes totales"
              isMoney
            />
          </Grid>
          <Grid item xs={6} lg={2} justifyContent="center">
            <Grid item xs={12}>
              <Button
                color="primary"
                variant="contained"
                disableElevation
                fullWidth
                onClick={togglePortfolio}
                id="toggle-download-portfolio"
              >
                Descargar resumen
              </Button>
              <Surplus />
            </Grid>
          </Grid>
        </>
        )}
      </Grid>
      <PorftolioDownload open={portfolioOpen} onClose={togglePortfolio} />
    </>
  );
};

export default CessionsSummary;
