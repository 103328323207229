import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import {
  Typography,
  TextField,
  Button,
  CircularProgress,
  Grid,
  Stack,
  Box,
} from '@mui/material';
import PropTypes from 'prop-types';
import MoreSiiCredentialsInfo from '../text/MoreSiiCredentialsInfo';
import TermsAndConditionsText from '../text/TermsAndConditionsText';
import { CONFIRM_ORDERING_SIMULATION, GET_USER, REGISTER_COMPANY, UPLOAD_SII_CREDENTIALS } from '../../graphql';
import { useSelectedCompany } from '../../hooks';
import { SiiLogo } from '../../assets';
import AlertOneDialog from '../dialogs/AlertOneDialog';
import { PasswordInput } from '../inputs';

const UploadSiiCredentialsForm = ({ isOrderingData, isNewCompany, closeSelf }) => {
  const history = useHistory();
  const [siiRut, setSiiRut] = useState('');
  const [siiPassword, setSiiPassword] = useState('');
  const [formError, setFormError] = useState('');
  const [moreInfoOpen, setMoreInfoOpen] = useState(false);
  const [openSuccessMsg, setOpenSuccessMsg] = useState(false);

  const [uploadSiiCredentials,
    { loading: uploadLoading }] = useMutation(UPLOAD_SII_CREDENTIALS, {
    onError: (_error) => {
      if (_error.message === 'invalid_sii_credentials') {
        setFormError('Contraseña incorrecta');
      } else if (_error.message === 'block_warning') {
        setFormError('Demasiados intentos fallidos, intente más tarde');
      } else setFormError('Hay un error con la contraseña');
    },
    onCompleted: (data) => {
      setSiiPassword('');
      if (data.uploadSiiCredentials.success) {
        setOpenSuccessMsg(true);
      } else setFormError('Hay ocurrido algún error');
    },
  });

  const [addNewCompany,
    { loading: uploadCompanyLoading }] = useMutation(REGISTER_COMPANY, {
    variables: {
      companyRut: siiRut,
      siiPassword,
      isNotRegister: isNewCompany,
    },
    onError: (error) => {
      setFormError(error.message);
    },
    onCompleted: () => {
      setOpenSuccessMsg(true);
      setSiiPassword('');
    },
    refetchQueries: [GET_USER],
    awaitRefetchQueries: true,
  });

  const [confirmOrderingSimulation] = useMutation(CONFIRM_ORDERING_SIMULATION);
  const selectedCompany = useSelectedCompany();

  const handleAccept = async () => {
    if (!siiPassword) {
      setFormError('Campo de contraseña vacío');
    } else {
      setFormError('');
      await uploadSiiCredentials({
        variables: {
          companyRut: selectedCompany.rut,
          siiPassword,
        },
      });
      if (isOrderingData) {
        await confirmOrderingSimulation({
          variables: {
            simulationId: isOrderingData.id,
            orderingInterest: isOrderingData.orderingInterest,
            factoringInterest: isOrderingData.factoringInterest,
            orderingPaymentAmount: isOrderingData.orderingPaymentAmount,
            factoringPaymentAmount: isOrderingData.factoringPaymentAmount,
            invoiceIssuedDate: isOrderingData.invoiceIssuedDate,
            invoiceDateToPay: isOrderingData.invoiceDateToPay,
          },
        });
      }
    }
  };
  const onCloseSuccessMsg = () => {
    if (isOrderingData) {
      setOpenSuccessMsg(false);
      history.replace('/app/ordering');
      window.location.reload();
    } else {
      setOpenSuccessMsg(false);
    }
  };
  const doesNotHavePasswordText = `Todavía no has subido tus credenciales del SII en nuestra plataforma
  o las que registraste anteriormente ya no son válidas, por favor súbelas para poder operar con nosotros`;
  const newCompanyText = 'Te pedimos estos datos para mostrarte toda la información asociada a tus facturas';
  const getPasswordText = useMemo(() => {
    if (isNewCompany) return newCompanyText;
    return doesNotHavePasswordText;
  }, [isNewCompany]);
  return (
    <Grid item xs={12} p="10%" justifyContent="center">
      <MoreSiiCredentialsInfo
        open={moreInfoOpen}
        onClose={() => setMoreInfoOpen(false)}
        isOrdering
      />
      <Stack alignItems="center" spacing="1rem">
        <Box
          component="img"
          width="25%"
          height="25%"
          src={SiiLogo}
          alt="sii-logo"
        />
        <Typography
          sx={{ fontWeight: 500 }}
          variant="h4"
        >
          {isNewCompany ? 'Datos del SII (empresa a agregar)' : 'Contraseña del SII Empresa' }
        </Typography>

        <Typography textAlign="center" alignSelf="center" align="center">
          {getPasswordText}
        </Typography>
        <Button
          onClick={() => setMoreInfoOpen(true)}
          variant="text"
          color="primary"
        >
          Más información
        </Button>
        <Stack width="70%" spacing="1rem">
          {isNewCompany && (
            <div>
              <Typography fontWeight={500}>
                Ingresa el rut de tu empresa (con guión)
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                name="rut"
                onChange={(e) => setSiiRut(e.target.value)}
                value={siiRut}
              />
            </div>
          )}
          <div>
            <Typography fontWeight={500}>
              Ingresa la clave del SII
            </Typography>
            <PasswordInput
              fullWidth
              variant="outlined"
              onChange={(e) => setSiiPassword(e.target.value)}
              value={siiPassword}
              type="password"
            />
          </div>
        </Stack>
        {Boolean(formError) && (
        <Typography
          variant="caption"
          component="div"
          color="error"
          align="center"
          gutterBottom
        >
          {formError}
        </Typography>
        )}
        <AlertOneDialog
          open={openSuccessMsg}
          onClose={() => { onCloseSuccessMsg(); closeSelf(); }}
          message="Recibimos tu clave del SII, ahora podremos evaluar tus solicitudes más rápido."
          title="Credenciales recibidas con éxito"
        />
        <Button
          variant="contained"
          color="primary"
          onClick={isNewCompany ? addNewCompany : handleAccept}
          disabled={uploadLoading || uploadCompanyLoading}
          endIcon={(uploadLoading || uploadCompanyLoading) ? <CircularProgress size={20} /> : null}
          style={{ width: '50%' }}
        >
          Aceptar
        </Button>
        <TermsAndConditionsText />
      </Stack>
    </Grid>
  );
};

UploadSiiCredentialsForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  isOrderingData: PropTypes.objectOf(PropTypes.object),
  isNewCompany: PropTypes.bool,
  closeSelf: PropTypes.func,

};
UploadSiiCredentialsForm.defaultProps = {
  isOrderingData: null,
  isNewCompany: false,
  closeSelf: () => {},
};

export default UploadSiiCredentialsForm;
