import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { formatRut, formatDateTime } from '@fingo/lib/helpers';
import { Grid, Typography, Box } from '@mui/material';
import { Table } from '../../../components/tables';

const headers = [
  { id: 'dateCession', label: 'Fecha cesión', type: 'date', align: 'center', isSearchable: true, mobile: true },
  { id: 'transferorName', label: 'Cedente', type: 'text', align: 'center', isSearchable: true, mobile: true },
  { id: 'assigneeName', label: 'Transferido a', type: 'text', align: 'center', isSearchable: true, mobile: true },
];

const CessionTable = ({ cessions }) => {
  const dataToRows = useCallback((_data) => _data.map((cession) => {
    const {
      id,
      cessionDate,
      transferor,
      assignee,
    } = cession;
    return ({
      id,
      nonSelectable: true,
      transferorName: {
        value: transferor.rut,
        component: (
          <Box>
            <Grid container alignItems="center" justifyContent="center">
              <Typography
                align="center"
                variant="subtitle1"
                style={{
                  display: 'block',
                  overflow: 'inherit',
                }}
              >
                {transferor.name}{' '}({formatRut(transferor.rut)})
              </Typography>
            </Grid>
          </Box>
        ),
        size: 'small',
      },
      assigneeName: {
        value: assignee.rut,
        component: (
          <Box>
            <Grid container alignItems="center" justifyContent="center">
              <Typography
                align="center"
                variant="subtitle1"
                style={{
                  display: 'block',
                  overflow: 'inherit',
                }}
              >
                {assignee.name}{' '}({formatRut(assignee.rut)})
              </Typography>
            </Grid>
          </Box>
        ),
        size: 'small',
      },
      dateCession: {
        value: cessionDate,
        component: (
          <Box>
            <Grid container alignItems="center" justifyContent="center">
              <Typography
                align="center"
                variant="subtitle1"
                style={{
                  display: 'block',
                  overflow: 'inherit',
                }}
              >
                {formatDateTime(cessionDate)}
              </Typography>
            </Grid>
          </Box>
        ),
        size: 'small',
      },
    });
  }), []);
  return (
    <Grid maxHeight="200px" overflow="auto">
      <Table
        initialOrderId="date_cession"
        orderDirection="desc"
        headers={headers}
        data={cessions}
        dataToRows={dataToRows}
        setNewDataOrder={() => {}}
        detailHeaders={headers.filter((header) => !header.mobile)}
        pagination={false}
      />
    </Grid>
  );
};
export default CessionTable;

CessionTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  cessions: PropTypes.arrayOf(PropTypes.object).isRequired,
};
