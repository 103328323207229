import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { TextCell } from '@fingo/lib/components/cells';
import { PaperHeader } from '@fingo/lib/components/headers';
import FingoTable from '@fingo/lib/components/tables/FingoTable';
import { ORDERING_PURCHASE_ORDERS } from '@fingo/lib/graphql';
import { formatDayMonthYear, formatMoney } from '@fingo/lib/helpers';
import { useSelectedCompany } from '@fingo/lib/hooks';
import {
  OrderingOperationActions,
  OrderingOperationActionButton,
  OrderingOperationPaymentSummary,
} from '../../../../components/ordering/ordering-operation';
import OrderingStatus from '../../../../components/ordering/status';
import { orderStatus } from '../../../../helpers/orderingStatus';

const HEADERS = [
  {
    id: 'orderNumber',
    label: 'Número',
    sortable: true,
    component: (row) => (
      <TextCell
        text={row.orderNumber}
        variant="body2"
        noWrap
        width="inherit"
      />
    ),
  },
  {
    id: 'purchaser_Name',
    label: 'Comprador',
    sortable: true,
    component: (row) => (
      <TextCell
        text={row.purchaser?.name}
        variant="body2"
        noWrap
        width="inherit"
      />
    ),
  },
  {
    id: 'publicationDate',
    label: 'Fecha OC',
    sortable: true,
    component: (row) => (
      <TextCell
        text={formatDayMonthYear(row.publicationDate)}
        variant="body2"
      />
    ),
  },
  {
    id: 'totalAmount',
    label: 'Monto total',
    sortable: true,
    component: (row) => (
      <TextCell
        text={`$${formatMoney(row.totalAmount)}`}
        variant="body2"
      />
    ),
  },
  {
    id: 'orderingoffer_OrderingPaymentAmount',
    label: 'Monto adelanto',
    sortable: true,
    component: (row) => (
      <TextCell
        text={`$${formatMoney(row.orderingoffer.orderingPaymentAmount)}`}
        variant="body2"
      />
    ),
  },
  {
    id: 'orderingoffer_OrderingMonthlyRate',
    label: 'Tasa',
    sortable: true,
    component: (row) => (
      <TextCell
        text={`${row.orderingoffer.orderingMonthlyRate.toFixed(2).replace('.', ',')}%`}
        variant="body2"
      />
    ),
  },
  {
    id: 'orderingOperationStatus',
    label: 'Estado',
    component: (row) => <OrderingStatus status={row.status} />,
  },
  {
    id: 'orderingOperationActionOffer',
    label: 'Detalle',
    component: (row) => (
      <OrderingOperationActionButton purchaseOrder={row} showPaymentOption={false} />
    ),
  },
];

const OrderingOperations = () => {
  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [rowCount, setRowCount] = useState(0);
  const [orderBy, setOrderBy] = useState('orderNumber');
  const selectedCompany = useSelectedCompany();
  const PAYABLE_STATUS = [orderStatus.PendingCollection, orderStatus.PendingInvoices];

  const { loading } = useQuery(
    ORDERING_PURCHASE_ORDERS,
    {
      variables: {
        first: rowsPerPage,
        offset: page * rowsPerPage,
        orderBy,
        companyId_In: selectedCompany ? [selectedCompany.rut] : null,
        inOperation: true,
      },
      onCompleted: (data) => {
        setPurchaseOrders(data.purchaseOrders.edges.map(({ node: oc }) => ({
          ...oc,
          selectable: true, // if isn't payed
          collapsible: false, // if is payed
          selected: false,
          selectDisabled: !PAYABLE_STATUS.includes(oc.status),
          open: false,
          collapseComponent: <OrderingOperationPaymentSummary purchaseOrder={oc} />,
        })) || []);
        setRowCount(data.purchaseOrders.totalCount);
      },
    },
  );

  const selectedPurchaseOrders = purchaseOrders.filter((purchaseOrder) => purchaseOrder.selected);

  return (
    <>
      <PaperHeader viewTitle="Operaciones activas e históricas" />
      <OrderingOperationActions
        purchaseOrders={selectedPurchaseOrders}
        companyRut={selectedCompany.rut}
      />
      <FingoTable
        headers={HEADERS}
        rows={purchaseOrders}
        setRows={setPurchaseOrders}
        loading={loading}
        selectable
        collapsible
        paginable
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        rowCount={rowCount}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
      />
    </>
  );
};

export default OrderingOperations;
