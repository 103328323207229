import React from 'react';
import { FingoPaper } from '@fingo/lib/components/layout';
import Tracker from '../Sophia';

const Analytics = () => (
  <FingoPaper
    menuOptions={[
      {
        label: 'Financiero',
        path: 'analytics',
        component: <Tracker />,
      },
    ]}
  />
);

export default Analytics;
