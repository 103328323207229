import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useGetUser } from '@fingo/lib/hooks';
import { RegisterForm, RegisterFormSecondStep } from '../components/userActions';

const Register = () => {
  const [step, setStep] = useState(0);
  const history = useHistory();
  const registerStepMapper = (_user) => {
    if (!_user) return setStep(0);
    if (_user.registerComplete) history.replace('/app');
    const steps = _user.userRegistrationSteps;
    const stepObject = {};
    if (!steps) return setStep(2);
    for (let i = 0; i < steps.length; i += 1) {
      stepObject[steps[i].step] = steps[i].success;
    }
    if (stepObject[2]) {
      return history.replace('/app');
    }
    if (stepObject[1]) return setStep(2);
    if (stepObject[0]) return setStep(1);
    return null;
  };
  useGetUser((_data) => {
    registerStepMapper(_data?.getUser);
  });
  if (step === 0) return <RegisterForm />;
  return <RegisterFormSecondStep />;
};

export default Register;
