import React, { useCallback, useState } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { SiiLogo } from '@fingo/lib/assets';
import { WelcomeHeader } from '@fingo/lib/components/headers';
import { PasswordInput, ValidationTextFieldInput } from '@fingo/lib/components/inputs';
import { GET_USER, REGISTER_COMPANY } from '@fingo/lib/graphql';
import { useInputRut } from '@fingo/lib/hooks';
import { Button, Grid, Icon, Stack, Typography, Link } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import PrimaryFormCard from '../cards/PrimaryFormCard';
import UnderlineColorTextField from '../inputs/UnderlineTextField';

const RegisterFormSecondStep = () => {
  const history = useHistory();
  const { rut, updateRut, isValid: isValidRut } = useInputRut();
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const goToHomePage = useCallback(() => {
    history.push('/');
  }, [history]);
  const [registerCompany, { loading: loadingRegisterCompany }] = useMutation(REGISTER_COMPANY, {
    variables: {
      companyRut: rut.raw,
      siiPassword: password,
    },
    refetchQueries: [GET_USER],
    awaitRefetchQueries: true,
    onError: (_error) => {
      if (_error.message === 'invalid_sii_credentials') {
        setErrorMessage('Credenciales inválidas');
      } else {
        setErrorMessage('Hubo un error al verificar tu empresa. Contáctate con nosotros');
      }
    },
    onCompleted: goToHomePage,
  });
  const onChangeInput = useCallback((event) => {
    const handlers = {
      companyRut: updateRut,
      siiPassword: setPassword,
    };
    const { name, value } = event.target;
    handlers[name](value);
  }, [updateRut]);

  return (
    <Grid container justifyContent="center" alignItems="flex-start">
      <Stack maxWidth={1000} width="100%" spacing={2}>
        <WelcomeHeader
          topMessage="Crea tu"
          headerMessage="Credenciales SII"
          bottomMessage="cuenta"
        />
        <PrimaryFormCard
          leftSideComponent={(
            <Stack
              width="100%"
              alignItems="center"
              height="auto"
              spacing={2}
              justifyContent="flex-end"
            >
              <Icon sx={{ height: 62, width: '100%' }}>
                <img src={SiiLogo} style={{ height: '100%' }} alt="sii" />
              </Icon>
              <Typography variant="body1" align="center" width="100%">
                Te pedimos estos datos para mostrarte todas
                las facturas que tienes disponibles para financiar
              </Typography>
            </Stack>
          )}
          rightSideComponent={(
            <Stack
              spacing={4}
              width="100%"
              alignItems="center"
              justifyContent="flex-start"
            >
              <ValidationTextFieldInput
                Component={UnderlineColorTextField}
                underlineColor="white"
                fullWidth
                InputProps={{
                  sx: { color: 'white' },
                }}
                helperTextColor="white"
                placeholder="Rut"
                variant="standard"
                name="companyRut"
                validationtype="rut"
                value={rut.formatted}
                onChange={onChangeInput}
              />
              <PasswordInput
                underlineColor="white"
                Component={UnderlineColorTextField}
                fullWidth
                InputProps={{
                  sx: { color: 'white' },
                }}
                type="password"
                color="error"
                placeholder="Contraseña SII"
                variant="standard"
                name="siiPassword"
                value={password}
                onChange={onChangeInput}
                iconColor="fingoWhite"
              />
              <Typography variant="body1" color="white">
                {errorMessage}
              </Typography>
              <Stack direction="row" spacing={2}>
                <LoadingButton
                  variant="containedReverse"
                  color="primary"
                  size="small"
                  disableElevation
                  loading={loadingRegisterCompany}
                  onClick={registerCompany}
                  disabled={!isValidRut || password === ''}
                >
                  Registrar empresa
                </LoadingButton>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    borderColor: 'white',
                    borderWidth: 1,
                    borderStyle: 'solid',
                  }}
                  size="small"
                  disableElevation
                  onClick={goToHomePage}
                >
                  Ir a la plataforma
                </Button>
              </Stack>
              <Typography variant="body1" color="white" align="center">
                Al ingresar declaras haber leído y aceptado los {' '}
                <Link target="_blank" to="/app/profile/terms" component={RouterLink} color="white" fontWeight={700} underline="hover">
                  “Términos y condiciones”
                </Link>
              </Typography>
            </Stack>
          )}
        />
      </Stack>
    </Grid>
  );
};

export default RegisterFormSecondStep;
