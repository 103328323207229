import React from 'react';
import { FingoPaper } from '@fingo/lib/components/layout';
import { FakeDocumentList } from '@fingo/lib/components/lists';
import { useBooleanState } from '@fingo/lib/hooks';
import { HEADERS, MOBILE_HEADERS, useInvoicePreColumns } from '@fingo/lib/constants';
import MySalesTourGuide from '../../../components/joyRides/MySalesTourGuide';

const MySales = () => {
  const [openJoyride, toggleJoyride] = useBooleanState(false);
  return (
    <>
      <FakeDocumentList
        type="my-sales"
        headerTitle="Cuentas por cobrar"
        includeHeaders={HEADERS}
        mobileHeaders={MOBILE_HEADERS}
        preColumns={useInvoicePreColumns()}
        openJoyride={toggleJoyride}
      />
      <MySalesTourGuide run={openJoyride} setRun={toggleJoyride} />
    </>
  );
};

const Sales = () => (
  <FingoPaper
    menuOptions={[
      {
        label: 'Cuentas por cobrar',
        path: 'my-sales',
        component: <MySales />,
      },
    ]}
  />
);

export default Sales;
