import { useCallback } from 'react';
import moment from 'moment';
import { useMutation, useQuery } from '@apollo/client';
import { cleanOfferShoppingCart } from '@fingo/lib/apollo/reactive-variables/localUpdates';
import { offerDateToPaySelectedQuery, offerSelectedQuery } from '@fingo/lib/apollo/cache/localQuery';
import { GET_USER, CREATE_OPERATION, OFFER_SHOPPING_CART, DOCUMENTS } from '@fingo/lib/graphql';
import { useFilteredQuery } from '@fingo/lib/hooks';
import { formatYearMonthDay } from '@fingo/lib/helpers';
import { calculateInvoiceAmounts } from '../helpers/invoiceAmountsCalculation';

const useCreateOperation = (isExternal, bankId, certificatePassword, onCompleted, onError) => {
  const { data: { getUser: { selectedCompany: {
    rut: companyRut, negativeSurplusBalance } } } } = useQuery(GET_USER);
  const { data: { selectedOfferIds } } = useQuery(offerSelectedQuery);
  const { data: offers, deletePaginatedAndRefetch } = useFilteredQuery(
    DOCUMENTS,
    {
      variables: { id_In: selectedOfferIds, first: 100 },
      skip: !selectedOfferIds.length,
    },
  );
  const { data: { selectedOfferDateToPay } } = useQuery(offerDateToPaySelectedQuery);
  const offerJoined = selectedOfferIds.map((invoiceId) => ({
    invoiceId,
    dateToPay: selectedOfferDateToPay[invoiceId]
      ? formatYearMonthDay(selectedOfferDateToPay[invoiceId]) : null,
  }));
  const { data: shoppingCartData } = useQuery(
    OFFER_SHOPPING_CART,
    { variables: { offerSelected: offerJoined || [] } },
  );
  const amountCalculator = useCallback((invoice) => {
    const dateToPay = selectedOfferDateToPay[invoice.id];
    if (!dateToPay) return null;
    const days = dateToPay.diff(moment().startOf('day'), 'days');
    return calculateInvoiceAmounts({
      days,
      retentionRate: invoice.offer.retentionRate,
      monthlyRate: invoice.offer.monthlyRate,
      amountWithIva: invoice.amountWithIva,
    }).amountMinusInterests;
  }, [selectedOfferDateToPay]);
  const assignmentRequests = offers?.invoices.edges.map(
    ({ node }) => ({
      id: node.id,
      dateToPay: selectedOfferDateToPay[node.id]
        ? formatYearMonthDay(selectedOfferDateToPay[node.id]) : null,
      amountMinusInterests: amountCalculator(node),
    }),
  );
  const nothing = () => {};
  const result = useMutation(
    CREATE_OPERATION,
    {
      variables: {
        assignmentRequests,
        digitalCertificatePassword: certificatePassword,
        negativeSurplus: negativeSurplusBalance > 0 ? -negativeSurplusBalance : 0,
        commission: shoppingCartData?.offerShoppingCart.commission,
        bankAccountId: bankId,
        isExternal,
        companyRut,
      },
      refetchQueries: [GET_USER],
      awaitRefetchQueries: true,
      onCompleted: (_data) => {
        deletePaginatedAndRefetch();
        cleanOfferShoppingCart();
        if (onCompleted) {
          onCompleted(_data);
        }
      },
      onError: onError || nothing,
    },
  );
  return result;
};

export default useCreateOperation;
