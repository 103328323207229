import React from 'react';
import { useRouteMatch, Route, Redirect } from 'react-router-dom';
import { Sidebar } from '@fingo/lib/components/layout';
import { FadeSwitch } from '@fingo/lib/components/switches';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import SettingsSuggestOutlinedIcon from '@mui/icons-material/SettingsSuggestOutlined';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import { useSelectedCompany } from '@fingo/lib/hooks';
import SiiDialog from '@fingo/lib/components/dialogs/SiiDialog';
import Info from './profile/Info';
import Credentials from './profile/Credentials';
import Settings from './profile/Settings';
import Terms from './profile/Terms';
import InvoiceProfile from './supplierAutomate/InvoiceProfile';
import NpsProvider from '../../components/nps/NpsProvider';
import useFingoRoutes from '../../hooks/useFingoRoutes';
import TermsAndConditions from '../../components/onboarding/TermsAndConditions';
import Register from '../Register';
import NotificationList from './notifications/NotificationList';
import HotjarProvider from './HotjarProvider';
import TrackerProvider from './TrackerProvider';
import PopUpAlertProvider from './PopUpAlertProvider';

const App = () => {
  const { path } = useRouteMatch();
  // Tracking code for changing pages
  const selectedCompany = useSelectedCompany();
  const fingoRoutes = useFingoRoutes().filter((route) => !route.disabled);
  const userMenu = [
    { id: 'profile-side-credentials', label: 'Mis credenciales', path: 'credentials', icon: <ArticleOutlinedIcon />, component: <Credentials />, show: !!selectedCompany, disabled: false },
    { id: 'profile-side-info', label: 'Mi información', path: 'info', icon: <PersonOutlineOutlinedIcon />, component: <Info />, show: !!selectedCompany, disabled: false },
    { id: 'profile-side-settings', label: 'Configuraciones', path: 'settings', icon: <SettingsSuggestOutlinedIcon />, component: <Settings />, show: !!selectedCompany, disabled: false },
    { id: 'profile-side-terms', label: 'TyC', path: 'terms', icon: <StarBorderOutlinedIcon />, component: <Terms />, show: true, disabled: false },
    { id: 'profile-side-register', label: 'Terminar registro', path: 'register', icon: <PersonOutlineOutlinedIcon />, component: <Register />, show: !selectedCompany, disabled: false },
  ];
  if (selectedCompany && !selectedCompany.hasAcceptedterms) {
    return <TermsAndConditions />;
  }
  return (
    <FadeSwitch depth={2}>
      {fingoRoutes.map(({ label, children, path: compPath }) => (
        <Route key={label} path={compPath}>
          {children}
        </Route>
      ))}
      <Route path={`${path}/profile`}>
        <Sidebar
          showLogOut
          menuOptions={userMenu.filter(({ show }) => show)}
        />
      </Route>
      <Route path={`${path}/notifications`}>
        <Sidebar
          goBack
          menuOptions={[
            { label: 'Notificaciones', path: 'notifications', icon: null, component: NotificationList, disabled: false },
          ]}
        />
      </Route>
      <Route path={`${path}/invoice`}>
        <Sidebar
          goBack
          menuOptions={[
            { label: 'Documento', path: '', icon: null, component: InvoiceProfile, disabled: false },
          ]}
        />
      </Route>
      <Route path={path}>
        <Redirect to={fingoRoutes[0].path} />
      </Route>
    </FadeSwitch>
  );
};

export default () => (
  <HotjarProvider>
    <TrackerProvider>
      <NpsProvider>
        <PopUpAlertProvider>
          <App />
          <SiiDialog />
        </PopUpAlertProvider>
      </NpsProvider>
    </TrackerProvider>
  </HotjarProvider>
);
