import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { PasswordInput, ValidationTextFieldInput } from '@fingo/lib/components/inputs';
import { UPDATE_INVOICE_PROVIDER_CREDENTIALS } from '@fingo/lib/graphql';
import { useInputRut, useSelectedCompany } from '@fingo/lib/hooks';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Stack, Typography } from '@mui/material';

const UploadCredentialForm = ({ provider, close, configureSnackBar }) => {
  const company = useSelectedCompany();
  const { rut, updateRut } = useInputRut();
  const [error, setError] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const onChangeInput = useCallback((event) => {
    const handlers = {
      rut: updateRut,
      password: setPassword,
      email: setEmail,
    };
    const { name, value } = event.target;
    handlers[name](value);
  }, [updateRut]);
  const [uploadCredentials, { loading }] = useMutation(UPDATE_INVOICE_PROVIDER_CREDENTIALS, {
    variables: {
      companyRut: company.rut,
      secondUserName: email,
      password,
      naturalPersonRut: rut.raw,
      credentialTypeId: provider.id,
    },
    onCompleted: () => {
      configureSnackBar(
        'success',
        'Credenciales ingresadas exitosamente',
      );
      close();
    },
    onError: () => {
      setError('Las credenciales son incorrectas. Revise que su usuario y contraseña sean las del facturador elegido');
    },
  });
  const onSubmit = useCallback((event) => {
    event.preventDefault();
    uploadCredentials();
  }, [uploadCredentials]);
  const userMessage = useMemo(() => {
    if (provider.credentialProviderType.name !== 'electronic_invoices') {
      return '';
    }
    if (company.invoiceProvider && company.invoiceProvider.name !== provider.name) {
      return `Tenemos en nuestros registros que tienes el facturador ${company.invoiceProvider.name}. Para cambiar eso, solamente ingrese las credenciales nuevas de su facturador ${provider.name}.`;
    }
    if (company.hasInvoiceProviderCredentials) {
      if (company.invoiceProvider) {
        return `Ya tenemos credenciales de su facturador ${company.invoiceProvider.name}. Puede subir nuevamente si lo desea aquí.`;
      }
      return 'Ya tenemos credenciales de su facturador. Puede subir nuevamente.';
    }
    return '';
  }, [company, provider]);
  return (
    <form onSubmit={onSubmit}>
      <Stack spacing={3} alignItems="center" ml="auto" mr="auto" maxWidth={350}>
        {provider.iconUrl ? (
          <Box
            component="img"
            sx={{
              width: { xs: '100%', md: '60%' },
              maxWidth: 950,
              height: 'auto',
            }}
            src={provider.iconUrl}
            alt={provider.name}
          />
        ) : (
          <Typography variant="h4">{provider.name}</Typography>
        )}
        <Typography variant="h6" align="center">
          {userMessage}
        </Typography>
        {!provider.integrated && (
          <Typography color="error" align="center">
            {provider.name} no está integrado en Fingo.
            Sin embargo puedes subir tus credenciales de igual forma
            para que así lo podamos integrar más adelante.
          </Typography>
        )}
        <ValidationTextFieldInput
          name="rut"
          type="text"
          validationtype="rut"
          label="Rut usuario"
          variant="standard"
          fullWidth
          value={rut.formatted}
          onChange={onChangeInput}
        />
        {provider.credentialProviderType.name === 'supplier_platform'
          ? (
            <ValidationTextFieldInput
              name="email"
              type="text"
              validationtype="email"
              label="Correo electrónico"
              variant="standard"
              fullWidth
              value={email}
              onChange={onChangeInput}
            />
          ) : <></>}
        <PasswordInput
          name="password"
          fullWidth
          label="Contraseña"
          variant="standard"
          value={password}
          onChange={onChangeInput}
          error={Boolean(error)}
          helperText={error}
        />
        <Stack direction="row" spacing={1}>
          <LoadingButton
            variant="contained"
            color="primary"
            size="small"
            type="submit"
            loading={loading}
            id="save-invoice-credentials"
          >
            Guardar credenciales
          </LoadingButton>
          <Button variant="link" onClick={close}>
            Atrás
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};

UploadCredentialForm.propTypes = {
  provider: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    iconUrl: PropTypes.string,
    integrated: PropTypes.bool.isRequired,
    credentialProviderType: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
  close: PropTypes.func.isRequired,
  configureSnackBar: PropTypes.func.isRequired,
};

export default UploadCredentialForm;
