import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: '1rem',
  },
  subtitleText: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    fontSize: 16,
  },
  paragraphText: {
    color: theme.palette.text.secondary,
    fontSize: 14,
    lineHeight: 1.3,
  },
}));

const TermsAndConditionsDocument = ({ termsSections }) => {
  const classes = useStyles();
  const textClasses = {
    paragraph: classes.paragraphText,
    simple_subparagraph: classes.paragraphText,
    roman_subparagraph: classes.paragraphText,
    subtitle: classes.subtitleText,
  };

  return (
    <Grid container direction="column" alignItems="flex-start" justifyContent="center">
      {termsSections.map((section) => (
        <Grid item xs={12} className={classes.container} key={section.text}>
          <Typography className={textClasses[section.sectionType]} align="justify">
            {section.text}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
};

TermsAndConditionsDocument.propTypes = {
  termsSections: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    section_type: PropTypes.string,
  })).isRequired,
};

export default TermsAndConditionsDocument;
