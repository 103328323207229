import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTracking } from 'react-tracking';
import { useIsMobile, useReactTrackingLayer, useSelectedCompany } from '@fingo/lib/hooks';
import ButtonTrackerApp from './ButtonTrackerApp';

// eslint-disable-next-line react/prop-types
const TrackerProvider = ({ children }) => {
  const dispatch = useReactTrackingLayer();
  const location = useLocation();
  const selectedCompany = useSelectedCompany();
  const isMobile = useIsMobile();
  // Tracking code for changing pages
  const { Track, trackEvent } = useTracking(
    {
      route: location.pathname,
      searchParams: location.search,
      company: selectedCompany,
      isMobile,
    },
    { dispatch },
  );
  // send load and unload events on page change
  useEffect(() => {
    trackEvent({ action: 'LOADED' });
  }, [location.pathname, trackEvent, selectedCompany, isMobile, location.search]);
  return (
    <Track>
      <ButtonTrackerApp>
        {children}
      </ButtonTrackerApp>
    </Track>
  );
};

export default TrackerProvider;
