import { useSubscription } from '@apollo/client';
import { DOCUMENTS } from '@fingo/lib/graphql';
import { NEW_INVOICES_CHARGED } from '@fingo/lib/graphql/invoices/subscriptions';
import { useFilteredQuery, useSelectedCompany } from '@fingo/lib/hooks';
import { useCallback, useEffect, useState } from 'react';

const useNewInvoiceCharged = () => {
  const selectedCompany = useSelectedCompany();
  const [newInvoices, setNewInvoices] = useState(0);
  const { data } = useSubscription(NEW_INVOICES_CHARGED, {
    variables: {
      companyId: selectedCompany?.rut,
    },
    skip: !selectedCompany,
    shouldResubscribe: true,
  });
  const { deleteQueryFromCache } = useFilteredQuery(DOCUMENTS, {
    skip: true,
  });
  useEffect(() => {
    setNewInvoices((previous) => previous + (data?.newInvoiceCharged?.newInvoices || 0));
  }, [data]);
  useEffect(() => () => {
    setNewInvoices((previous) => {
      if (previous) {
        deleteQueryFromCache();
      }
      return 0;
    });
  }, []);
  const readData = useCallback(() => {
    setNewInvoices(0);
  }, []);
  return [newInvoices, readData];
};

export default useNewInvoiceCharged;
