import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { formatMoney, formatDateWordsWithYear } from '@fingo/lib/helpers';

const OrderingOperationDetailDialog = ({ purchaseOrder, open, onClose }) => {
  if (!open) return null;
  const orderingDetails = [
    { label: 'Monto de orden de compra', value: purchaseOrder.totalAmount },
    { label: `Monto financiado (${purchaseOrder.orderingoffer.orderingRetentionRate}%)`, value: purchaseOrder.orderingoffer.orderingFinancedAmount },
    { label: `Diferencia de precio (tasa ${purchaseOrder.orderingoffer.orderingMonthlyRate}%)`, value: purchaseOrder.orderingoffer.orderingInterest },
    { label: 'Comisión', value: purchaseOrder.orderingoffer.orderingCommission },
  ];
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      keepMounted={false}
    >
      <DialogTitle id="alert-dialog-title" sx={{ px: 3, mb: 4 }} component="div">
        <Typography align="center" variant="h5">
          Resumen de la operación
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ minWidth: 500, maxWidth: 600 }}>
        <Stack spacing={2} width="100%">
          <Typography variant="h6" gutterBottom fontWeight={600}>Financiamiento de la orden de compra</Typography>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h6">Fecha emisión de factura</Typography>
            <Typography variant="h6" align="right">{formatDateWordsWithYear(purchaseOrder.orderingoffer.invoiceIssuedDate)}</Typography>
          </Stack>
          {orderingDetails.map(({ label, value }) => (
            <Stack key={label} direction="row" justifyContent="space-between">
              <Typography variant="h6">{label}</Typography>
              <Typography variant="h6" align="right">${formatMoney(value)}</Typography>
            </Stack>
          ))}
          <Stack direction="row" justifyContent="space-between">
            <Typography fontWeight={600} variant="h6">Monto a girar</Typography>
            <Typography fontWeight={600} variant="h6" align="right">
              ${formatMoney(purchaseOrder.orderingoffer.orderingPaymentAmount)}
            </Typography>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions
        sx={{ justifyContent: 'center' }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={onClose}
          size="small"
          sx={{
            minWidth: 200,
            fontSize: 14,
          }}
        >
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

OrderingOperationDetailDialog.propTypes = {
  purchaseOrder: PropTypes.shape({
    id: PropTypes.string.isRequired,
    totalAmount: PropTypes.number.isRequired,
    orderingoffer: PropTypes.shape({
      id: PropTypes.string.isRequired,
      orderingCommission: PropTypes.number.isRequired,
      orderingRetentionRate: PropTypes.number.isRequired,
      orderingMonthlyRate: PropTypes.number.isRequired,
      orderingFinancedAmount: PropTypes.number.isRequired,
      orderingPaymentAmount: PropTypes.number.isRequired,
      orderingInterest: PropTypes.number.isRequired,
      invoiceIssuedDate: PropTypes.objectOf(moment).isRequired,
    }).isRequired,
  }).isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default OrderingOperationDetailDialog;
