import React from 'react';
import { useDropzone } from 'react-dropzone';
import { Grid, Stack, Typography } from '@mui/material';
import { CloudUpload } from '@mui/icons-material';
import PropTypes from 'prop-types';

const UploadContainer = ({ file, setFile, errorMutation, setErrorMutation }) => {
  const onDrop = (acceptedFiles) => setFile(acceptedFiles[0]);
  const { getRootProps, isDragActive } = useDropzone({ onDrop });
  const ConditionalFilling = () => {
    if (isDragActive) {
      return (
        <Grid item container direction="row" justifyContent="center">
          <CloudUpload color="primary" sx={{ marginRight: 1 }} />
          <Typography>
            Suelta el archivo aquí!
          </Typography>
        </Grid>
      );
    }
    return (
      <Stack
        width="100%"
        height="100%"
        textAlign="center"
        alignItems="center"
        justifyContent="center"
        spacing={1}
      >
        <Typography
          variant="caption"
          width="90%"
          display="block"
          color="text.main"
        >
          {file.size && errorMutation && errorMutation.split('\n').map((i, key) => <p key={`error-${key + 1}`}>{i}</p>)}
        </Typography>

        {file.size ? <Typography variant="caption" width="180px">{file.name}</Typography>
          : (
            <><Typography variant="caption" width="180px">Arrastra aquí el archivo que quieres subir</Typography>
              <Typography variant="caption" width="180px">ó</Typography>
            </>
          )}
        <Typography
          variant="caption"
          width="180px"
          color="primary"
          sx={{ textDecoration: 'underline' }}
        >Sube desde tu dispositivo
        </Typography>
      </Stack>
    );
  };
  return (
    <Grid
      {...getRootProps()}
      onClick={(props) => { getRootProps().onClick(props); setErrorMutation(''); }}
      sx={{
        borderColor: 'primary.main',
        backgroundColor: isDragActive ? 'primary.unreadNotification' : 'gray.light',
        borderStyle: isDragActive ? 'dashed' : 'none',
        borderRadius: 5,
        height: 240,
        width: '100%',
        cursor: 'pointer',
        transition: 'border 0.24s ease-in-out' }}
      container
      justifyContent="center"
      alignItems="center"
    >
      {ConditionalFilling()}
    </Grid>
  );
};
UploadContainer.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  file: PropTypes.object.isRequired,
  setFile: PropTypes.func.isRequired,
  errorMutation: PropTypes.string,
  setErrorMutation: PropTypes.func.isRequired,
};
UploadContainer.defaultProps = {
  errorMutation: null,
};
export default UploadContainer;
