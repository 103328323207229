import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/client';
import { logIn } from '@fingo/lib/apollo/reactive-variables/localUpdates';
import { SiiLogo } from '@fingo/lib/assets';
import { getFormFieldError, formatRut } from '@fingo/lib/helpers';
import {
  GET_USER,
  UPDATE_CONTACT_INFO,
  UPDATE_DUMMY_PASSWORD,
  LOGIN,
  UPLOAD_SII_CREDENTIALS,
  CONFIRM_ORDERING_SIMULATION,
} from '@fingo/lib/graphql';
import {
  Typography,
  TextField,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Divider,
  InputAdornment,
  IconButton,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import AlertOneDialog from '@fingo/lib/components/dialogs/AlertOneDialog';
import MoreSiiCredentialsInfo from '@fingo/lib/components/text/MoreSiiCredentialsInfo';
import TermsAndConditionsDialog from '@fingo/lib/components/dialogs/TermsAndConditionsDialog';
import { PasswordStrength } from '../utils';

const useStylesOrdering = makeStyles(() => ({
  subTitle: {
    align: 'center',
    paddingBottom: 10,
  },
  textFieldContainer: {
    minWidth: '50%',
    margin: 10,
    alignSelf: 'center',
  },
  textField: {
    borderRadius: 10,
  },
  siiPasswordInputContainer: {
    marginTop: 10,
    alignContent: 'center',
  },
  siiItemsContainer: {
    margin: 10,
    alignSelf: 'center',
    textAlign: 'center',
  },
  button: {
    minWidth: 150,
    marginInline: 'auto',
    marginTop: 5,
  },
  illustration: {
    width: 40,
    marginTop: 7,
    marginRight: 15,
  },
  passwordStrength: {
    alignSelf: 'center',
    textAlign: 'justify',
    maxWidth: 230,
    marginBottom: 10,
  },
}));

const UploadOrderingUserForm = ({ orderingData, handleClose, openForm, setOpenForm }) => {
  const history = useHistory();
  const classes = useStylesOrdering();
  const { data, refetch } = useQuery(GET_USER, {
    fetchPolicy: 'cache-and-network',
  });
  let title = '';
  let subTitle = '';
  if (Object.keys(orderingData).length !== 0) {
    title = 'Solicitud de evaluación';
    subTitle = '¡Estás a pocos pasos de tu financiamiento! Completa tu registro para solicitar la evaluación de tu orden de compra.';
  } else {
    title = 'Completa tu registro';
    subTitle = '¡Estás a pocos pasos de tu financiamiento! Completa tu registro para poder operar con Fingo.';
  }
  const [uploadSiiCredentials] = useMutation(UPLOAD_SII_CREDENTIALS);
  const [updateDummyPassword] = useMutation(UPDATE_DUMMY_PASSWORD);
  const [loginGql] = useMutation(LOGIN);
  const [updateContactInfo] = useMutation(UPDATE_CONTACT_INFO);
  const [confirmSimulation] = useMutation(CONFIRM_ORDERING_SIMULATION);
  const [formInputs, setFormInputs] = useState({
    email: {
      value: '',
      error: '',
    },
    phoneNumber: {
      value: '',
      error: '',
    },
    userPassword: {
      value: '',
      error: '',
    },
    confirmUserPassword: {
      value: '',
      error: '',
    },
    siiPassword: {
      value: '',
      error: '',
    },
  });
  const [showUserPassword, setShowUserPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showSiiPassword, setShowSiiPassword] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [formError, setFormError] = useState('');
  const [openTC, setOpenTC] = useState(false);
  const [success, setSuccess] = useState(false);
  const [moreInfoOpen, setMoreInfoOpen] = useState(false);

  const onChangeInput = (e) => {
    const { name: nameTarget, type, value } = e.target;
    const fieldError = getFormFieldError(value, type, nameTarget);
    if (fieldError.isError) {
      setFormInputs((oldState) => ({
        ...oldState,
        [nameTarget]: { ...oldState[nameTarget], error: fieldError.message, value },
      }));
    } else if (nameTarget === 'confirmUserPassword' && value !== formInputs.userPassword.value) {
      setFormInputs((oldState) => ({
        ...oldState,
        [nameTarget]: { ...oldState[nameTarget], error: 'Las contraseñas no coinciden', value },
      }));
    } else {
      setFormInputs((oldState) => ({
        ...oldState,
        [nameTarget]: { ...oldState[nameTarget], error: '', value },
      }));
    }
  };

  const updateSiiPassword = async () => {
    await uploadSiiCredentials({
      variables: {
        companyRut: data.getUser.selectedCompany.rut,
        siiPassword: formInputs.siiPassword.value,
      },
    });
  };

  const updateUserPassword = async () => {
    await updateDummyPassword({
      variables: {
        newPassword: formInputs.userPassword.value,
        mailProvided: formInputs.email.value,
        phoneNumber: `+56${formInputs.phoneNumber.value}`,
      },
    });
  };

  const updateUserContactInfo = async () => {
    await updateContactInfo({
      variables: {
        email: formInputs.email.value,
        phoneNumber: formInputs.phoneNumber.value,
      },
    });
  };

  const confirmOrderingSimulation = async () => {
    await confirmSimulation({
      variables: {
        simulationId: orderingData.id,
        orderingInterest: orderingData.orderingInterest,
        factoringInterest: orderingData.factoringInterest,
        orderingPaymentAmount: orderingData.orderingPaymentAmount,
        factoringPaymentAmount: orderingData.factoringPaymentAmount,
        invoiceIssuedDate: orderingData.invoiceIssuedDate,
        invoiceDateToPay: orderingData.invoiceDateToPay,
      },
    });
  };

  const reLogin = async () => {
    const responseLogin = await loginGql({
      variables: {
        email: formInputs.email.value,
        password: formInputs.userPassword.value,
      },
    });
    const userData = responseLogin.data.tokenAuth;
    await logIn(userData);
  };

  const handleAccept = async () => {
    try {
      await updateUserPassword();
      await updateSiiPassword();
      await updateUserContactInfo();
      await reLogin();
      await refetch();
      if (Object.keys(orderingData).length !== 0) {
        await confirmOrderingSimulation();
        setUploadLoading(false);
        setSuccess(true);
      }
      setUploadLoading(false);
      setSuccess(true);
    } catch (_error) {
      setUploadLoading(false);
      if (_error.message === 'invalid_sii_credentials') {
        setFormError('Contraseña del SII incorrecta');
      } else if (_error.message === 'block_warning') {
        setFormError('Demasiados intentos fallidos, intente más tarde');
      } else if (_error.message === 'email_already_used') {
        setFormError('Email ya está registrado');
      } else {
        setFormError('Hay un error en el formulario');
      }
    }
  };

  const checkErrorsBeforeSubmit = async () => {
    setFormError('');
    for (let i = 0; i < Object.keys(formInputs).length; i += 1) {
      if (Object.values(formInputs)[i].error !== '') {
        setFormError('Hay errores en el formulario');
        return;
      }
    }
    setUploadLoading(true);
    await handleAccept();
  };
  const onCloseSuccessMsg = async () => {
    setSuccess(false);
    setOpenForm(false);
    if (Object.keys(orderingData).length !== 0) {
      history.push('/app/ordering');
      window.location.reload();
    } else {
      history.push('/app/factoring');
      window.location.reload();
    }
  };

  const {
    email,
    phoneNumber,
    userPassword,
    confirmUserPassword,
    siiPassword,
  } = formInputs;
  return (
    <Dialog onClose={handleClose} open={openForm}>
      <DialogTitle>
        <Typography variant="h5" align="center" style={{ marginTop: 10 }}>
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography className={classes.subTitle} align="justify">
          {subTitle}
        </Typography>
        <Grid container direction="column" style={{ marginTop: 15 }}>
          <Grid item className={classes.textFieldContainer}>
            {/* Email TextField  */}
            <TextField
              id="userEmail"
              variant="outlined"
              type="email"
              name="email"
              label="Email"
              fullWidth
              value={email.value}
              error={email.error !== ''}
              helperText={email.error}
              className={classes.textField}
              onChange={onChangeInput}
              InputProps={{
                style: {
                  height: 50,
                  borderRadius: 20,
                },
                autoComplete: 'new-password',
                form: {
                  autocomplete: 'off',
                },
              }}
              InputLabelProps={{
                style: {
                  color: 'lightgrey',
                },
              }}
            />
          </Grid>
          <Grid item className={classes.textFieldContainer}>
            {/* Phone number TextField  */}
            <TextField
              id="phoneNumber"
              variant="outlined"
              label="Número de contacto"
              name="phoneNumber"
              type="number"
              fullWidth
              error={phoneNumber.error !== ''}
              helperText={phoneNumber.error}
              value={phoneNumber.value}
              onChange={onChangeInput}
              className={classes.textField}
              InputProps={{
                style: {
                  height: 50,
                  borderRadius: 20,
                },
                startAdornment: <InputAdornment position="start">+56</InputAdornment>,
                autoComplete: 'new-password',
                form: {
                  autocomplete: 'off',
                },
              }}
              InputLabelProps={{
                style: {
                  color: 'lightgrey',
                },
              }}
            />
          </Grid>
          <Grid item className={classes.textFieldContainer}>
            {/* User password TextField  */}
            <TextField
              id="userPassword"
              variant="outlined"
              label="Contraseña de Fingo"
              fullWidth
              value={userPassword.value}
              error={userPassword.error !== ''}
              helperText={userPassword.error}
              name="userPassword"
              onChange={onChangeInput}
              type={showUserPassword ? 'text' : 'password'}
              className={classes.textField}
              InputProps={{
                style: {
                  height: 50,
                  borderRadius: 20,
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowUserPassword(!showUserPassword)}
                      size="large"
                    >
                      {showUserPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
                autoComplete: 'new-password',
                form: {
                  autocomplete: 'off',
                },
              }}
              InputLabelProps={{
                style: {
                  color: 'lightgrey',
                },
              }}
            />
          </Grid>
          <Grid item className={classes.textFieldContainer}>
            {/* User confirm password TextField  */}
            <TextField
              id="confirmPassword"
              variant="outlined"
              name="confirmUserPassword"
              fullWidth
              value={confirmUserPassword.value}
              error={confirmUserPassword.error !== ''}
              helperText={confirmUserPassword.error}
              label="Confirma contraseña"
              onChange={onChangeInput}
              type={showConfirmPassword ? 'text' : 'password'}
              className={classes.textField}
              InputProps={{
                style: {
                  height: 50,
                  borderRadius: 20,
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                      size="large"
                    >
                      {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
                autoComplete: 'new-password',
                form: {
                  autocomplete: 'off',
                },
              }}
              InputLabelProps={{
                style: {
                  color: 'lightgrey',
                },
              }}
            />
          </Grid>
          <Grid item className={classes.passwordStrength}>
            <PasswordStrength password={formInputs.userPassword.value} />
          </Grid>
          <Divider />
          <Grid container direction="column" className={classes.siiPasswordInputContainer}>
            <Grid item className={classes.siiItemsContainer}>
              <Typography variant="h4">Ingresa contraseña del SII</Typography>
              { data && data.getUser && data.getUser.selectedCompany && (
                <Typography variant="caption">Rut de empresa: {formatRut(data.getUser.selectedCompany.rut)}</Typography>
              )}
            </Grid>
            <Grid item className={classes.siiItemsContainer}>
              {/* SiiPassword TextField  */}
              <img src={SiiLogo} alt="banner illustration" className={classes.illustration} />
              <TextField
                id="siiPassword"
                variant="outlined"
                name="siiPassword"
                value={siiPassword.value}
                error={siiPassword.error !== ''}
                helperText={siiPassword.error}
                onChange={onChangeInput}
                type={showSiiPassword ? 'text' : 'password'}
                className={classes.textField}
                label="Contaseña del SII"
                InputProps={{
                  style: {
                    height: 50,
                    borderRadius: 20,
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowSiiPassword(!showSiiPassword)}
                        size="large"
                      >
                        {showSiiPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                  autoComplete: 'new-password',
                  form: {
                    autocomplete: 'off',
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: 'lightgrey',
                  },
                }}
              />
              <br />
              <Button
                onClick={() => setMoreInfoOpen(true)}
                variant="text"
                color="primary"

              >
                Más información
              </Button>
            </Grid>
          </Grid>
          {Boolean(formError) && (
            <Typography
              variant="caption"
              component="div"
              color="error"
              align="center"
              gutterBottom
            >
              {formError}
            </Typography>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={checkErrorsBeforeSubmit}
          disabled={uploadLoading}
          endIcon={uploadLoading ? <CircularProgress size={20} /> : null}
        >
          Guardar
        </Button>
      </DialogActions>
      <Typography component="div" variant="caption" align="center">
        Al hacer click en &apos; Guardar&apos;,
        declaras haber leído y aceptado nuestros
        <Button onClick={() => setOpenTC(true)} size="small">
          <Typography variant="caption" color="primary">
            {' '}términos y condiciones
          </Typography>
        </Button>
      </Typography>
      {/* PopUps */}
      <TermsAndConditionsDialog open={openTC} setOpen={setOpenTC} />
      <AlertOneDialog
        open={success}
        onClose={onCloseSuccessMsg}
        message="¡Registro realizado con éxito!"
        title="Registro completado"
      />
      <MoreSiiCredentialsInfo
        open={moreInfoOpen}
        onClose={() => setMoreInfoOpen(false)}
        isOrdering
      />
    </Dialog>
  );
};

UploadOrderingUserForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  orderingData: PropTypes.objectOf(PropTypes.object),
  openForm: PropTypes.bool.isRequired,
  setOpenForm: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};
UploadOrderingUserForm.defaultProps = {
  orderingData: {},
};

export default UploadOrderingUserForm;
