import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { orderingSimulationSelection } from '@fingo/lib/apollo/cache/localQuery';
import { cleanOrderingShoppingCart } from '@fingo/lib/apollo/reactive-variables/localUpdates';
import { ORDERING_SHOPPING_CART } from '@fingo/lib/graphql';
import { formatYearMonthDay } from '@fingo/lib/helpers';
import useSimulateOrdering from '../../../hooks/useSimulateOrdering';
import BaseSummaryCart from '../BaseSummaryCart';

const OrderingShoppingStepOne = ({ nextStep }) => {
  const { data: {
    selectedOrderingIds,
    orderingInvoiceIssuedSelected,
  } } = useQuery(orderingSimulationSelection);
  const formatInvoiceIssued = useCallback((ocId) => {
    if (orderingInvoiceIssuedSelected[ocId]) {
      return formatYearMonthDay(orderingInvoiceIssuedSelected[ocId]);
    }
    return null;
  }, [orderingInvoiceIssuedSelected]);
  const { data: shoppingCartData, loading: loadingCart } = useQuery(
    ORDERING_SHOPPING_CART,
    {
      variables: {
        purchaseOrders: selectedOrderingIds.map((ocId) => ({
          purchaseOrderId: ocId,
          invoiceIssuedDate: formatInvoiceIssued(ocId),
        })),
      },
    },
  );
  const evaluateOnComplete = () => {
    cleanOrderingShoppingCart();
    nextStep();
  };
  const [sendEvaluate, { loading: loadingEvaluate }] = useSimulateOrdering(
    { onCompleted: evaluateOnComplete },
  );
  const summaryCart = [
    { title: 'Cantidad de facturas', value: shoppingCartData?.orderingShoppingCart.invoiceCount, color: 'text.primary', money: false },
    { title: 'Monto total', value: shoppingCartData?.orderingShoppingCart.totalAmount, color: 'text.primary', money: true },
    { title: 'Diferencia de precio', value: shoppingCartData?.orderingShoppingCart.priceDifference, color: 'text.primary', money: true },
    { title: 'Comisión', value: shoppingCartData?.orderingShoppingCart.commission, color: 'text.primary', money: true },
    { title: 'Costos totales', value: shoppingCartData?.orderingShoppingCart.totalCosts, color: 'text.primary', money: true },
    { title: 'Monto retenidos', value: shoppingCartData?.orderingShoppingCart.retainedAmount, color: 'text.primary', money: true },
    { title: 'Monto a depositar', value: shoppingCartData?.orderingShoppingCart.depositAmount, color: 'primary.main', money: true },
  ];
  const notSelectedDate = useCallback(
    () => selectedOrderingIds.filter(
      (nodeId) => !orderingInvoiceIssuedSelected[nodeId],
    ),
    [selectedOrderingIds, orderingInvoiceIssuedSelected],
  );
  const nothing = useCallback(() => {}, []);
  const tooltipTitle = useCallback(() => {
    if (notSelectedDate().length > 0) {
      return 'Ingrese por favor todas las fechas solicitadas';
    }
    return '';
  }, [notSelectedDate]);
  return (
    <BaseSummaryCart
      cartType="ordering"
      summaryCart={summaryCart}
      cleanFunction={cleanOrderingShoppingCart}
      principalActionFunction={sendEvaluate || nothing}
      disabledPrincipalAction={selectedOrderingIds.length === 0
          || notSelectedDate().length > 0 || !sendEvaluate}
      loadingButton={loadingEvaluate}
      loading={loadingCart}
      principalActionTitle="Evaluar"
      principalActionId="cart-ordering-evaluate"
      tooltipDisabledTitle={tooltipTitle()}
    />
  );
};

OrderingShoppingStepOne.propTypes = {
  nextStep: PropTypes.func.isRequired,
};

export default OrderingShoppingStepOne;
