import React from 'react';
import { FingoPaper } from '@fingo/lib/components/layout';
import { COUNT_FACTORING } from '@fingo/lib/graphql';
import { useQuery } from '@apollo/client';
import { useSelectedCompany } from '@fingo/lib/hooks';
import { AvailableFactoring, AvailableOffers, CessionStatus } from './factoringRoutes';

const Factoring = () => {
  const selectedCompany = useSelectedCompany();
  const { data } = useQuery(
    COUNT_FACTORING,
    {
      variables: {
        company_MasterEntity_Rut_In: selectedCompany?.rut,
      },
      skip: !selectedCompany,
    },
  );
  return (
    <FingoPaper
      menuOptions={[
        {
          id: 'factoring-tab-available',
          label: 'Facturas disponibles',
          path: 'available',
          component: <AvailableFactoring />,
          quantity: data?.preoffers.totalCount,
        },
        {
          id: 'factoring-tab-offers',
          label: 'Ofertas',
          path: 'offers',
          component: <AvailableOffers />,
          quantity: data?.offers.totalCount,
        },
        {
          id: 'factoring-tab-cessions',
          label: 'Estado de cesiones',
          path: 'cessions',
          component: <CessionStatus />,
          quantity: data?.cessions.totalCount,
        },
      ]}
    />
  );
};

export default Factoring;
