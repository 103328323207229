import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery } from '@apollo/client';
import TermsAndConditionsDocument from '../text/TermsAndConditionsDocument';
import { GET_TERMS } from '../../graphql';

const useStyles = makeStyles(() => ({
  titleSection: {
    margin: '1rem 0 0.5rem',
  },
  content: {
    padding: '1.5rem',
    margin: '0.5rem',
  },
  offerButton: {
    width: '10%',
    fontSize: '15px',
    borderRadius: '20px',
    marginBottom: '0.5rem',
  },
}));

const TermsAndConditionsDialog = ({ open, setOpen }) => {
  const classes = useStyles();
  const [termsSections, setTermsSections] = useState([]);
  const { data, error } = useQuery(GET_TERMS);

  useEffect(() => {
    if (data) {
      setTermsSections(data.getTermsAndConditions);
    }
  }, [data]);

  if (error) return `Error! ${error.message}`;

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        <Typography variant="h5" color="primary" className={classes.titleSection}>
          Términos y Condiciones
        </Typography>
      </DialogTitle>
      <DialogContent dividers className={classes.content}>
        <TermsAndConditionsDocument termsSections={termsSections} />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          className={classes.offerButton}
          onClick={() => setOpen(false)}
          id="close-terms-and-conditions"
        >
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

TermsAndConditionsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default TermsAndConditionsDialog;
