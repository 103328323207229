import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { TableHead, TableRow, TableCell, Checkbox, TableSortLabel, Box } from '@mui/material';
import { visuallyHidden } from '@mui/utils';

const FingoTableHead = (props) => {
  const {
    headers,
    setRows,
    orderBy,
    setOrderBy,
    selectable,
    selectedRowsCount,
    selectableRowsCount,
    collapsible,
  } = props;

  const selectAllClickHandler = (event) => {
    if (event.target.checked) {
      setRows((prevState) => prevState.map((row) => {
        if (row.selectable) return { ...row, selected: true };

        return row;
      }));
      return;
    }

    setRows((prevState) => prevState.map((row) => ({ ...row, selected: false })));
  };

  const sortDirection = useMemo(() => (orderBy.charAt(0) === '-' ? 'desc' : 'asc'), [orderBy]);

  const sortClickHandler = (newOrderBy) => {
    setOrderBy(orderBy.includes(newOrderBy) && sortDirection === 'asc' ? `-${newOrderBy}` : newOrderBy);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {((selectable || collapsible)) && (
            <Checkbox
              color="primary"
              indeterminate={!selectableRowsCount}
              disabled={!selectableRowsCount}
              checked={selectableRowsCount > 0 && selectedRowsCount === selectableRowsCount}
              onChange={selectAllClickHandler}
            />
          )}
        </TableCell>
        {headers.map((header) => (
          <TableCell
            key={header.id}
            sortDirection={orderBy.includes(header.id) && sortDirection}
          >
            <TableSortLabel
              active={header.sortable && orderBy.includes(header.id)}
              direction={orderBy.includes(header.id) ? sortDirection : 'asc'}
              onClick={() => sortClickHandler(header.id)}
            >
              {header.label}
              {header.sortable && orderBy.includes(header.id) && (
                <Box component="span" sx={visuallyHidden}>
                  {sortDirection === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              )}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

FingoTableHead.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string,
      sortable: PropTypes.bool,
      component: PropTypes.func.isRequired,
    }),
  ).isRequired,
  setRows: PropTypes.func.isRequired,
  orderBy: PropTypes.string.isRequired,
  setOrderBy: PropTypes.func.isRequired,
  selectable: PropTypes.bool,
  selectedRowsCount: PropTypes.number,
  selectableRowsCount: PropTypes.number,
  collapsible: PropTypes.bool,
};

FingoTableHead.defaultProps = {
  selectable: false,
  selectedRowsCount: 0,
  selectableRowsCount: 0,
  collapsible: false,
};

export default FingoTableHead;
