import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/client';
import { useSelectedCompany } from '@fingo/lib/hooks';
import { GENERATE_PORTFOLIO_URL, PORTFOLIO_OPTIONS } from '@fingo/lib/graphql';
import { openUrlAndDownload, formatYearMonthDay } from '@fingo/lib/helpers';
import { LoadingButton } from '@mui/lab';
import { FormControl, FormLabel, LinearProgress, MenuItem, Select, Stack, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import FingoDialog from '../dialogs/FingoDialog';

const PorftolioDownload = ({ open, onClose }) => {
  const {
    data: portfolioOptionsData,
    loading: loadingPortfolioOptions,
  } = useQuery(PORTFOLIO_OPTIONS);
  const selectedCompany = useSelectedCompany();
  const [states, setStates] = useState({});
  const [errors, setErrors] = useState({});
  const [generatePortfolioUrl, { loading: loadingPortfolio }] = useMutation(
    GENERATE_PORTFOLIO_URL,
    {
      variables: {
        clientRut: selectedCompany?.rut,
        portfolioType: states.portfolioType,
        documentFormat: states.documentFormat,
        startDate: states.startDate && formatYearMonthDay(states.startDate),
        endDate: states.endDate && formatYearMonthDay(states.endDate),
      },
      onCompleted: (data) => openUrlAndDownload(data.generatePortfolioSummary.url, 'portfolio'),
    },
  );
  const validateStates = useCallback((_states) => {
    setErrors(() => ({
      portfolioType: !_states.portfolioType,
      documentFormat: !_states.documentFormat,
      startDate: !_states.startDate,
      endDate: !_states.endDate,
    }));
    return (!_states.portfolioType
    || !_states.documentFormat
    || !_states.startDate
    || !_states.endDate);
  }, []);
  const handleChange = useCallback((event) => {
    setStates((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  }, []);
  const handleChangeDate = useCallback((stateName) => (newDate) => {
    setStates((prev) => ({ ...prev, [stateName]: newDate }));
  }, []);
  const onSubmit = (event) => {
    event.preventDefault();
    const validateErrors = validateStates(states);
    if (!validateErrors) {
      generatePortfolioUrl();
    }
  };
  return (
    <FingoDialog
      open={open}
      handleClose={onClose}
      title="Descargar resumen de cartera"
    >
      {loadingPortfolioOptions ? <LinearProgress /> : (
        <form id="portfolioDownload" onSubmit={onSubmit} noValidate>
          <Stack width="100%" spacing={2} alignItems="center">
            <FormControl required fullWidth error={!!errors.portfolioType}>
              <FormLabel>Tipo de documento</FormLabel>
              <Select name="portfolioType" onChange={handleChange} value={states.portfolioType || ''}>
                {portfolioOptionsData?.getPortfolioTypeEnum.map(({ value, label }) => (
                  <MenuItem value={value} key={value}>{label}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl required fullWidth error={!!errors.documentFormat}>
              <FormLabel>Formato</FormLabel>
              <Select name="documentFormat" onChange={handleChange} value={states.documentFormat || ''}>
                {portfolioOptionsData?.getPortfolioFormatEnum.map(({ value, label }) => (
                  <MenuItem value={value} key={value}>{label}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <Stack direction="row" justifyContent="space-between" width="100%">
              <FormControl required error={!!errors.startDate}>
                <FormLabel>Desde</FormLabel>
                <DatePicker
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{ ...params.inputProps, 'data-hj-allow': '' }}
                    />
                  )}
                  onChange={handleChangeDate('startDate')}
                  value={states.startDate || ''}
                  id="startDate"
                  required
                />
              </FormControl>
              <FormControl required error={!!errors.endDate}>
                <FormLabel>Hasta</FormLabel>
                <DatePicker
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{ ...params.inputProps, 'data-hj-allow': '' }}
                    />
                  )}
                  onChange={handleChangeDate('endDate')}
                  value={states.endDate || ''}
                  id="endDate"
                  required
                />
              </FormControl>
            </Stack>
            <LoadingButton
              variant="contained"
              size="small"
              color="primary"
              type="submit"
              loading={loadingPortfolio}
              id="download-portfolio"
            >
              Descargar resumen
            </LoadingButton>
          </Stack>
        </form>
      )}
    </FingoDialog>
  );
};

PorftolioDownload.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PorftolioDownload;
