import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  Typography,
  Grid,
} from '@mui/material';
import { SiiLogo } from '@fingo/lib/assets';
import {
  GET_USER,
  REGISTER_COMPANY,
} from '@fingo/lib/graphql';
import { PasswordInput, ValidationTextFieldInput } from '@fingo/lib/components/inputs';
import styled from 'styled-components';
import { Stack } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import { useInputRut } from '@fingo/lib/hooks';
import { Redirect } from 'react-router-dom';
import TermsAndConditionsText from '@fingo/lib/components/text/TermsAndConditionsText';
import useStyles from '../../styles';

const RegisterTextField = styled(ValidationTextFieldInput)(() => ({
  width: '100%',
}));

const QuickSiiForm = () => {
  const { rut: siiRut, updateRut: setSiiRut } = useInputRut('');
  const classes = useStyles();
  const [siiPassword, setSiiPassword] = useState('');
  const [formError, setFormError] = useState('');
  const [redirectSales, setRedirectSales] = useState(false);

  const [addNewCompany,
    { loading: uploadCompanyLoading }] = useMutation(REGISTER_COMPANY, {
    onError: (error) => {
      if (error.message === 'Contraseña incorrecta') {
        setFormError('Credenciales inválidas');
      } else {
        setFormError('Hubo un error al verificar tu empresa. Contáctate con nosotros');
      }
    },
    variables: {
      companyRut: siiRut.formatted,
      siiPassword,
      isNotRegister: true,
    },
    onCompleted: () => {
      setSiiPassword('');
      setRedirectSales(true);
    },
    refetchQueries: [{ query: GET_USER }],
  });

  return (
    <Grid
      sx={{
        width: { xs: 200, sm: 400 },
        py: 3,
        px: { xs: 0, md: 4 },
        mr: 'auto',
        ml: 'auto',
      }}
    >
      <form
        onSubmit={async (event) => {
          event.preventDefault();
          addNewCompany();
        }}
        id="quickSiiForm"
      >
        <Stack alignItems="center" spacing={2} width="100%">
          <img src={SiiLogo} alt="banner illustration" className={classes.illustration} />
          <Typography variant="p" align="center">
            Te pedimos estos datos para mostrarte toda la información asociada a tus facturas
          </Typography>
          <RegisterTextField
            name="rut"
            validationtype="rut"
            type="rut"
            label="Rut empresa"
            variant="standard"
            value={siiRut.formatted}
            onChange={(e) => setSiiRut(e.target.value)}
          />
          <PasswordInput
            name="password"
            label="Clave SII"
            variant="standard"
            value={siiPassword}
            onChange={(e) => setSiiPassword(e.target.value)}
            error={!!formError?.password}
            helperText={formError.password}
            sx={{
              width: '100%',
            }}
          />

          {Boolean(formError) && (
            <Typography
              variant="caption"
              component="div"
              color="error"
              align="center"
              gutterBottom
            >
              {formError}
            </Typography>
          )}
          <LoadingButton
            id="quick-register-sii"
            variant="contained"
            color="primary"
            size="small"
            type="submit"
            loading={uploadCompanyLoading}
          >
            Registrar empresa
          </LoadingButton>

          <TermsAndConditionsText />
          {redirectSales ? (
            <Redirect to={{
              pathname: '/app/sales/accountable',
              search: '?referrer=quick-registration',
            }}
            />
          ) : <></>}

        </Stack>
      </form>
    </Grid>
  );
};

export default QuickSiiForm;
