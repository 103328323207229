import React from 'react';
import { DOCUMENTS, EXPORT_INVOICES } from '@fingo/lib/graphql';
import { DocumentList } from '@fingo/lib/components/lists';
import { useBooleanState } from '@fingo/lib/hooks';
import { HEADERS, MOBILE_HEADERS, useInvoicePreColumns } from '@fingo/lib/constants';
import MySalesTourGuide from '../../../../components/joyRides/MySalesTourGuide';
import useNewInvoiceCharged from '../../../../hooks/useNewInvoiceCharged';
import SupplierWelcomeDialog from '../SupplierWelcomeDialog';

const MySales = () => {
  const [openJoyride, toggleJoyride] = useBooleanState(false);
  const [newInvoices, readNewInvoices] = useNewInvoiceCharged();
  return (
    <>
      <SupplierWelcomeDialog />
      <DocumentList
        trackerId="MY_SALES"
        type="my-sales"
        headerTitle="Cuentas por cobrar"
        queryDocument={DOCUMENTS}
        emitter
        newData={newInvoices}
        readData={readNewInvoices}
        showFilters
        includeHeaders={HEADERS}
        mobileHeaders={MOBILE_HEADERS}
        allCompaniesOptions
        showExportInvoice
        queryExport={EXPORT_INVOICES}
        preColumns={useInvoicePreColumns()}
        openJoyride={toggleJoyride}
      />
      <MySalesTourGuide run={openJoyride} setRun={toggleJoyride} />
    </>
  );
};

export default MySales;
