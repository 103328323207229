/* eslint-disable max-len */
import React from 'react';
import ProgressiveImage from 'react-progressive-graceful-image';
import { useMutation } from '@apollo/client';
import { GET_USER, ACCEPT_TERMS } from '@fingo/lib/graphql';
import { FingoPlatformBig, FingoPlatformMd, PartnerAceptaLogo, RedWave } from '@fingo/lib/assets';
import { useBooleanState, useIsMobile, useSelectedCompany } from '@fingo/lib/hooks';
import { LoadingButton } from '@mui/lab';
import { Box, List, ListItem, ListItemIcon, ListItemText, Stack, Typography, Checkbox, Button, Skeleton } from '@mui/material';
import TermsAndConditionsDialog from '@fingo/lib/components/dialogs/TermsAndConditionsDialog';

const COMMON_PROPS = {
  mt: { xs: 3 },
};

const FINGO_TEXTS = [
  'Ve tus facturas emitidas y la tasa asociada al negocio',
  'Solicita las tasas definitas de financiamiento',
  'Cede y financia facturas',
  'Revisa el estado de tus cesiones en tiempo real',
];

const TermsAndConditions = () => {
  const selectedCompany = useSelectedCompany();
  const [checkTerms, toggleCheckTerms] = useBooleanState(selectedCompany?.isAcepta || selectedCompany?.isSenegocia);
  const [openTerms, toggleOpenTerms] = useBooleanState(false);
  const isMobile = useIsMobile('md');
  const [acceptTermsMutation, { loading: loadingTerms }] = useMutation(ACCEPT_TERMS, {
    variables: {
      ruts: [selectedCompany?.rut],
    },
    refetchQueries: [GET_USER],
    awaitRefetchQueries: true,
  });

  return (
    <>
      <TermsAndConditionsDialog open={openTerms} setOpen={toggleOpenTerms} />
      <Box
        bgcolor="white"
        display="flex"
        flexDirection="column"
        alignItems="center"
        minHeight="100%"
        borderTop="solid 3px #F0F0F0"
        sx={{
          backgroundImage: `url(${RedWave})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100% auto',
          backgroundPosition: 'bottom',
        }}
      >
        <Typography
          variant="h1"
          color="primary"
          fontSize={20}
          fontWeight="500"
          letterSpacing={3}
          align="center"
          {...COMMON_PROPS}
        >
          BIENVENIDO A FINGO
        </Typography>
        <Typography
          variant="h1"
          fontWeight={500}
          align="center"
          maxWidth={600}
          {...COMMON_PROPS}
        >
          Anticipa tus facturas hoy para que siempre tengas efectivo cuando lo necesites
        </Typography>
        <Stack
          direction={isMobile ? 'column' : 'row'}
          spacing={2}
          {...COMMON_PROPS}
          alignItems="center"
          justifyContent="center"
          width="100%"
        >
          <ProgressiveImage src={isMobile ? FingoPlatformMd : FingoPlatformBig} placeholder="">
            {(newSrc, loading) => {
              if (loading) {
                return (
                  <Skeleton
                    sx={{
                      width: { xs: '100%', md: '60%' },
                      maxWidth: 950,
                      height: 300,
                    }}
                    variant="rectangular"
                  />
                );
              }
              return (
                <Box
                  component="img"
                  sx={{
                    width: { xs: '100%', md: '60%' },
                    maxWidth: 950,
                    height: 'auto',
                  }}
                  alt="Plataforma de fingo"
                  src={newSrc}
                />
              );
            }}
          </ProgressiveImage>
          <List>
            {FINGO_TEXTS.map((text, index) => (
              <ListItem sx={{ py: 2 }} key={text}>
                <ListItemIcon sx={{ minWidth: 40 }}>
                  <Box
                    bgcolor="primary.main"
                    borderRadius={100}
                    height={25}
                    width={25}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography variant="h5" color="white" align="center">{index + 1}</Typography>
                  </Box>
                </ListItemIcon>
                <ListItemText
                  primary={text}
                  primaryTypographyProps={{ variant: 'h6' }}
                />
              </ListItem>
            ))}
            <ListItem sx={{ py: 2 }}>
              <Checkbox checked={checkTerms} onChange={toggleCheckTerms} />
              <Button color="primary" variant="link" onClick={toggleOpenTerms}>
                Leer y aceptar Términos y condiciones
              </Button>
            </ListItem>
            <ListItem sx={{ py: 2 }}>
              <LoadingButton
                disabled={!checkTerms}
                variant="contained"
                size="small"
                loading={loadingTerms}
                onClick={acceptTermsMutation}
              >
                Ver mis facturas
              </LoadingButton>
            </ListItem>
          </List>
        </Stack>
        {selectedCompany?.isAcepta && (
        <Box
          component="img"
          {...COMMON_PROPS}
          width={250}
          height="auto"
          alt="Partner de acepta"
          src={PartnerAceptaLogo}
        />
        )}
      </Box>
    </>
  );
};

export default TermsAndConditions;
