import React from 'react';
import { FingoPaper } from '@fingo/lib/components/layout';
import { MyPurchases } from './purchasesRoutes';

const Purchase = () => (
  <FingoPaper
    menuOptions={[
      {
        label: 'Cuentas por pagar',
        path: 'my-purchases',
        component: <MyPurchases />,
      },
    ]}
  />
);

export default Purchase;
