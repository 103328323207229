import { useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import { logIn } from '@fingo/lib/apollo/reactive-variables/localUpdates';
import { LOGIN } from '@fingo/lib/graphql';
import { validEmail } from '@fingo/lib/helpers';

const useLogin = (email, password) => {
  const [loginError, setLoginError] = useState('');
  const [loginGql, { loading }] = useMutation(LOGIN, {
    variables: {
      email,
      password,
    },
    onCompleted: ({ tokenAuth }) => {
      logIn(tokenAuth);
    },
    onError: (error) => setLoginError(() => {
      if (error.message === 'Please enter valid credentials') {
        return { password: 'El correo o la contraseña no son correctos.' };
      }
      if (error.message === 'failed login attempts limit exceded') {
        return { password: 'Se ha bloqueado su cuenta. Espere un tiempo para volver a intentar o reestablezca su contraseña.' };
      }
      return { password: error.message };
    }),
  });
  const wrapperLogin = useCallback(() => {
    if (!validEmail(email)) {
      setLoginError({ email: 'Ingrese un email correcto' });
      return;
    }
    loginGql();
  }, [email, loginGql]);
  return [wrapperLogin, loading, loginError, setLoginError];
};

export default useLogin;
