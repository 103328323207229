import React from 'react';
import { FingoPaper } from '@fingo/lib/components/layout';
import MySales from './salesRoutes/MySales';

const Sales = () => (
  <FingoPaper
    menuOptions={[
      {
        label: 'Cuentas por cobrar',
        path: 'my-sales',
        component: <MySales />,
      },
    ]}
  />
);

export default Sales;
