import React from 'react';
import { DOCUMENTS } from '@fingo/lib/graphql';
import { DocumentList } from '@fingo/lib/components/lists';
import { useInvoicePreColumns } from '@fingo/lib/constants';
import CessionsSummary from '../../../../components/factoring/CessionsSummary';

const CessionStatus = () => (
  <DocumentList
    trackerId="CESSION_STATUS"
    type="cession-status"
    headerTitle="Estado de cesiones"
    queryDocument={DOCUMENTS}
    emitter
    includeHeaders={[
      'folio',
      'dateIssued',
      'receiver_Name',
      'amountWithIva',
      'offerMonthlyRate',
      'offerDefaultRate',
      'cessionStatus',
    ]}
    mobileHeaders={[
      'folio',
      'receiver_Name',
      'cessionStatus',
    ]}
    allCompaniesOptions
    customVariables={{
      hasBeenCeded: true,
      dateIssued_Gte: null,
      dateIssued_Lte: null,
    }}
    customSummaryElement={<CessionsSummary />}
    preColumns={useInvoicePreColumns()}
  />
);

export default CessionStatus;
