/* eslint-disable import/prefer-default-export */
export const banks = [
  { name: 'Banco BICE' },
  { name: 'Banco Consorcio' },
  { name: 'Banco Corpbanca' },
  { name: 'Banco Crédito e Inversiones' },
  { name: 'Banco Estado' },
  { name: 'Banco Falabella' },
  { name: 'Banco Internacional' },
  { name: 'Banco Paris' },
  { name: 'Banco Ripley' },
  { name: 'Banco Santander' },
  { name: 'Banco Security' },
  { name: 'Banco de Chile / Edwards-Citi' },
  { name: 'Banco del Desarrollo' },
  { name: 'Banco Coopeuch' },
  { name: 'Banco Itaú' },
  { name: 'Banco Scotiabank' },
  { name: 'Banco Scotiabank Azul' },
];
