import React from 'react';
import { useQuery } from '@apollo/client';
import { offerSelected } from '@fingo/lib/apollo/reactive-variables';
import { offerSelectedQuery } from '@fingo/lib/apollo/cache/localQuery';
import { DOCUMENTS } from '@fingo/lib/graphql';
import { DocumentList } from '@fingo/lib/components/lists';
import { useInvoicePreColumns } from '@fingo/lib/constants';

const AvailableOffers = () => {
  const { data: { selectedOfferIds } } = useQuery(offerSelectedQuery);
  return (
    <DocumentList
      trackerId="OFFERS"
      type="available-offers"
      headerTitle="Ofertas"
      queryDocument={DOCUMENTS}
      checkboxSelection
      shoppingCartPreview
      emitter
      includeHeaders={[
        'folio',
        'dteType_Code',
        'dateIssued',
        'receiver_Name',
        'amountWithIva',
        'offerMonthlyRate',
        'offerDefaultRate',
        'dateToPay',
        'uploadDocuments',
      ]}
      mobileHeaders={[
        'folio',
        'receiver_Name',
        'offerMonthlyRate',
        'offerDefaultRate',
        'dateToPay',
      ]}
      customVariables={{
        hasValidOffer: true,
        dateIssued_Gte: null,
        dateIssued_Lte: null,
      }}
      onSelectionModelChange={(ids) => offerSelected(ids)}
      selectionModel={selectedOfferIds}
      keepNonExistentRowsSelected
      preColumns={useInvoicePreColumns()}
    />
  );
};

export default AvailableOffers;
