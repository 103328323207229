import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { InputAdornment, Paper, Stack } from '@mui/material';
import { FullHeightBox } from '@fingo/lib/components/boxes';
import { PasswordInput, ValidationTextFieldInput } from '@fingo/lib/components/inputs';
import { WelcomeHeader } from '@fingo/lib/components/headers';
import { REGISTER_USER } from '@fingo/lib/graphql';
import { Wave } from '@fingo/lib/assets';
import { formatRut, getFormFieldError } from '@fingo/lib/helpers';
import { LoadingButton } from '@mui/lab';
import styled from 'styled-components';
import Cookies from 'universal-cookie';
import { PasswordStrength } from '../utils';
import useLogin from '../../hooks/useLogin';
import { isPasswordValid } from '../utils/passwordCheckers';

const RegisterTextField = styled(ValidationTextFieldInput)(() => ({
  width: '100%',
}));

const RegisterForm = () => {
  const [formData, setFormData] = useState({
    firstName: {
      value: '',
      error: '',
    },
    lastName: {
      value: '',
      error: '',
    },
    phoneNumber: {
      value: '',
      error: '',
    },
    password: {
      value: '',
      error: '',
    },
    email: {
      value: '',
      error: '',
    },
  });
  const [loginGql, loadingLogin, errorForm] = useLogin(
    formData.email.value,
    formData.password.value,
  );
  const cookies = new Cookies();
  const [registerUser, { loading: loadingRegisterUser }] = useMutation(REGISTER_USER, {
    variables: {
      email: formData.email.value,
      firstName: formData.firstName.value,
      lastName: formData.lastName.value,
      password: formData.password.value,
      phoneNumber: `+56${formData.phoneNumber.value}`,
      utmCampaign: cookies.get('utm_campaign') || null,
    },
    onError: () => {},
    onCompleted: loginGql,
  });

  const handleChange = (e) => {
    let { value } = e.target;
    const { name, type } = e.target;
    if (name === 'companyRut') value = formatRut(value);
    const fieldError = getFormFieldError(value, type, name);
    if (fieldError.isError) {
      setFormData((oldState) => ({
        ...oldState,
        [name]: { ...oldState[name], error: fieldError.message, value },
      }));
    } else {
      setFormData((oldState) => ({
        ...oldState,
        [name]: { ...oldState[name], error: '', value },
      }));
    }
  };

  const isButtonReady = () => {
    for (let i = 0; i < Object.values(formData).length; i += 1) {
      const element = Object.values(formData)[i];
      if (element.value === '' || element.error !== '') {
        return false;
      }
    }
    if (!isPasswordValid(formData.password.value)) {
      return false;
    }
    return true;
  };
  return (
    <FullHeightBox
      sx={{
        backgroundImage: `url(${Wave})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% auto',
        backgroundPosition: 'bottom',
      }}
    >
      <WelcomeHeader topMessage="Crea tu" headerMessage="Regístrate" bottomMessage="cuenta" />
      <Paper
        sx={{
          width: { xs: 250, sm: 400 },
          py: 3,
          px: { xs: 4, md: 8 },
          mr: 'auto',
          ml: 'auto',
        }}
        elevation={4}
      >
        <form
          onSubmit={async (event) => {
            event.preventDefault();
            registerUser();
          }}
          id="loginForm"
        >
          <Stack alignItems="center" spacing={2} width="100%">
            <RegisterTextField
              name="firstName"
              validationtype="text"
              type="text"
              label="Nombre"
              variant="standard"
              value={formData.firstName.value}
              onChange={handleChange}
            />
            <RegisterTextField
              name="lastName"
              validationtype="text"
              type="text"
              label="Apellido"
              variant="standard"
              value={formData.lastName.value}
              onChange={handleChange}
            />
            <RegisterTextField
              name="phoneNumber"
              validationtype="phone"
              type="number"
              label="Número de teléfono"
              variant="standard"
              value={formData.phoneNumber.value}
              onChange={handleChange}
              InputProps={{
                startAdornment: <InputAdornment position="start">+56</InputAdornment>,
              }}
            />
            <RegisterTextField
              name="email"
              validationtype="email"
              type="email"
              label="Email"
              variant="standard"
              value={formData.email.value}
              onChange={handleChange}
            />
            <PasswordInput
              name="password"
              label="Contraseña"
              variant="standard"
              value={formData.password.value}
              onChange={handleChange}
              error={!!errorForm?.password}
              helperText={errorForm.password}
              sx={{
                width: '100%',
              }}
            />
            <PasswordStrength password={formData.password.value} />
            <LoadingButton
              variant="contained"
              color="primary"
              size="small"
              type="submit"
              loading={loadingLogin || loadingRegisterUser}
              disabled={!isButtonReady()}
              id="register-create-account"
            >
              Crear cuenta
            </LoadingButton>
          </Stack>
        </form>
      </Paper>
    </FullHeightBox>
  );
};

export default RegisterForm;
