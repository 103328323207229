import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import 'moment/locale/es';
import React, { useEffect, useState } from 'react';
import { render } from 'react-dom';
import {
  Route,
  BrowserRouter as Router,
  useHistory,
  Switch,
} from 'react-router-dom';
import { ApolloClient, ApolloProvider, split } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { WebSocketLink } from '@apollo/client/link/ws';
import { createUploadLink } from 'apollo-upload-client';
import moment from 'moment';
import { setContext } from 'apollo-link-context';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { CssBaseline } from '@mui/material';
import { theme } from '@fingo/lib/theme';
import { cacheCreator } from '@fingo/lib/apollo/cache';
import { token } from '@fingo/lib/apollo/reactive-variables';
import { customFetch } from '@fingo/lib/helpers';
import { IntercomProvider, useIntercom } from 'react-use-intercom';
import { useGetUser, useSelectedCompany } from '@fingo/lib/hooks';
import { SnackBarProvider } from '@fingo/lib/contexts/snack-bar';
import {
  Login,
  Register,
  App,
  ExternalClickController,
  Landing,
  TermsAndConditions,
  PasswordRecovery,
  QuickRegistration,
} from './views';
import PrivateRoute from './privateroute';
import Layout from './components/layout';
import Game from './game/index';
import UtmCampaignTracker from './components/utmCampaigns/UtmCampaignTracker';
import OnboardingAuth from './views/App/supplierAutomate/SupplierOnboardingAuth';

const AppContainer = ({ children }) => {
  const history = useHistory();
  const { update } = useIntercom();
  const user = useGetUser();
  const selectedCompany = useSelectedCompany();
  useEffect(() => {
    if (user) {
      update({
        name: user.completeName,
        userId: user.id,
        email: user.mailProvided || user.email,
        phone: user.phoneNumber,
        company: {
          companyId: selectedCompany?.id,
          name: selectedCompany?.name,
        },
      });
    } else {
      update();
    }
  }, [history, user]);
  return children;
};

const dateCaster = () => {
  moment.locale('es');
  const myDate = moment();
  myDate.format('ll');
};

const ApolloRouting = () => (
  <StyledEngineProvider injectFirst>
    <SnackBarProvider>
      <Router>
        <AppContainer>
          <UtmCampaignTracker>
            <Layout>
              <Switch>
                <PrivateRoute path="/app" component={App} />
                <PrivateRoute path="/teuber" component={Game} />
                <Route exact path="/register" component={Register} />
                <Route exact path="/quick-registration" component={QuickRegistration} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/provider-login" component={OnboardingAuth} />
                <Route exact path="/external-login" component={ExternalClickController} />
                <Route exact path="/" component={Landing} />
                <Route exact path="/terms" component={TermsAndConditions} />
                <Route exact path="/password-recovery" component={PasswordRecovery} />
              </Switch>
            </Layout>
          </UtmCampaignTracker>
        </AppContainer>
      </Router>
    </SnackBarProvider>
  </StyledEngineProvider>
);

const Routing = () => {
  const [client, setClient] = useState();
  dateCaster();
  const cache = cacheCreator();
  const uploadLink = createUploadLink({
    uri: process.env.REACT_APP_GRAPHQL_SERVER_URI,
    fetch: (uri, options) => customFetch(uri, options),
  });
  const wsLink = new WebSocketLink(
    new SubscriptionClient(
      process.env.REACT_APP_WEBSOCKET_SERVER || 'ws://localhost/ws/graphql/',
      {
        lazy: true,
        options: {
          reconnect: true,
        },
        inactivityTimeout: 1000,
        connectionParams: () => ({
          token: token() || '',
        }),
      },
    ),
  );

  const authLink = setContext((_, { headers }) => ({
    headers: {
      ...headers,
      authorization: token() ? `JWT ${token()}` : '',
    },
  }));
  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition'
        && definition.operation === 'subscription'
      );
    },
    wsLink,
    authLink.concat(uploadLink),
  );
  useEffect(() => {
    const createClientApollo = async () => {
      const apolloClient = new ApolloClient({
        cache,
        link: splitLink,
        resolvers: {},
      });
      setClient(apolloClient);
    };
    if (!client) {
      createClientApollo();
    }
  }, [cache, splitLink, client]);
  if (!client) {
    return <></>;
  }
  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <CssBaseline>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <ApolloProvider client={client}>
              <IntercomProvider
                appId="b72hnksj"
                autoBoot
                shouldInitialize={process.env.NODE_ENV === 'production'}
              >
                <ApolloRouting />
              </IntercomProvider>
            </ApolloProvider>
          </LocalizationProvider>
        </CssBaseline>
      </ThemeProvider>
    </React.StrictMode>
  );
};

render(<Routing />, document.getElementById('root'));
