import { useQuery } from '@apollo/client';
import { FormControl, MenuItem, Select, Typography } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import { GET_USER } from '@fingo/lib/graphql';

const SelectBankAccount = ({ setBankId, bankId }) => {
  const { data: { getUser: { selectedCompany: { bankAccounts } } } } = useQuery(GET_USER);
  return (
    <FormControl fullWidth margin="dense">
      <Typography variant="h2">Seleccione la cuenta del banco para realizar el depósito</Typography>
      <Select
        id="select-bank-account"
        value={bankId}
        onChange={(event) => setBankId(event.target.value)}
        sx={{ mt: 1 }}
      >
        {bankAccounts.filter((bank) => bank.active).map((bank) => (
          <MenuItem
            key={bank.id}
            value={bank.id}
          >
            {bank.accountType} {bank.bankName} N°{bank.accountNumber}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

SelectBankAccount.propTypes = {
  setBankId: PropTypes.func.isRequired,
  bankId: PropTypes.string.isRequired,
};

export default SelectBankAccount;
