import React from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/client';
import { preofferSelectedQuery } from '@fingo/lib/apollo/cache/localQuery';
import { cleanPreOfferShoppingCart } from '@fingo/lib/apollo/reactive-variables/localUpdates';
import { GET_USER, DOCUMENTS, REQUEST_PRE_OFFERS_EVALUATION } from '@fingo/lib/graphql';
import { LoadingButton } from '@mui/lab';
import { Grid, Typography, Button, Stack } from '@mui/material';

const PreofferShoppingStepTwo = ({ prevStep, nextStep }) => {
  const { data: { selectedPreofferIds } } = useQuery(preofferSelectedQuery);
  const { data: { getUser } } = useQuery(GET_USER);
  const variables = {
    invoiceIds: selectedPreofferIds,
    email: getUser.mailProvided || getUser.email,
    phoneNumber: getUser.phoneNumber,
    companyRut: getUser.selectedCompany.rut,
  };
  const finishMutation = () => {
    cleanPreOfferShoppingCart();
    nextStep();
  };
  const [requestPreoffer, { loading }] = useMutation(REQUEST_PRE_OFFERS_EVALUATION, {
    onCompleted: finishMutation,
    variables,
    onError: finishMutation,
    refetchQueries: [DOCUMENTS],
    awaitRefetchQueries: true,
  });
  return (
    <Stack spacing={2}>
      <Typography variant="body2" paragraph gutterBottom align="justify" sx={{ width: '100%' }}>
        Evaluaremos el riesgo de tu empresa para determinar las tasas finales de cada factura.
      </Typography>
      {/* <Typography variant="body2" paragraph gutterBottom align="justify" sx={{ width: '100%' }}>
        Acelera tu proceso de evaluación cargando tus documentos asociados!
      </Typography>
      <Typography variant="body2" paragraph gutterBottom align="justify" sx={{ width: '100%' }}>
        Tipo de documento
      </Typography> */}
      <Typography variant="body2" paragraph gutterBottom align="justify" sx={{ width: '100%' }}>
        Seleccione avanzar para enviar las facturas a evaluar
      </Typography>
      <Grid container justifyContent="space-between">
        <Button variant="link" color="primary" size="small" onClick={prevStep} id="cart-preoffer-back">Atrás</Button>
        <LoadingButton
          variant="contained"
          color="primary"
          size="small"
          loading={loading}
          onClick={() => requestPreoffer()}
          id="cart-preoffer-confirm"
        >
          Avanzar
        </LoadingButton>
      </Grid>
    </Stack>
  );
};

PreofferShoppingStepTwo.propTypes = {
  prevStep: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
};

export default PreofferShoppingStepTwo;
