import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useBooleanState } from '@fingo/lib/hooks';
import { Button, Divider, ListItemIcon, ListItemText, Menu, MenuItem, Stack } from '@mui/material';
import { Check, Send } from '@mui/icons-material';
import TransferBankDialog from './dialogs/TransferBankDialog';
import AddInvoicesToPurchaseOrdersDialog from './dialogs/AddInvoicesToPurchaseOrdersDialog';

const OrderingOperationActions = ({ purchaseOrders, companyRut }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openExternalCession, toggleExternalCession] = useBooleanState(false);
  const [openTransfer, toggleTransfer] = useBooleanState(false);

  const menuItemPush = useCallback((runFunction) => () => {
    setAnchorEl(null);
    runFunction();
  }, [setAnchorEl]);

  return (
    <>
      <Stack
        alignItems="center"
        width="100%"
        borderRadius={2}
        py={1}
        ml={0}
        mt={3}
        mb={3}
        spacing={1}
        direction="row"
        bgcolor="background.light"
        sx={{
          px: { md: 2, xs: 0 },
          bgcolor: { md: 'background.light', xs: 'transparent' },
          justifyContent: { md: 'flex-end', xs: 'space-around' },
        }}
      >
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={({ currentTarget }) => setAnchorEl(currentTarget)}
          id="purchaseOrderPay"
          disabled={!purchaseOrders.length}
        >
          Pagar múltiples OC
        </Button>
      </Stack>
      <Menu
        onClose={() => setAnchorEl(null)}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        elevation={1}
        keepMounted={false}
      >
        <MenuItem
          onClick={menuItemPush(toggleExternalCession)}
          id="orderingPayWithInvoice"
        >
          <ListItemIcon>
            <Send fontSize="medium" color="primary" />
          </ListItemIcon>
          <ListItemText>
            Pagar cediendo factura
          </ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={menuItemPush(toggleTransfer)}
          id="orderingPayWithTransfer"
        >
          <ListItemIcon>
            <Check fontSize="medium" color="primary" />
          </ListItemIcon>
          <ListItemText>
            Pagar con transferencia bancaria
          </ListItemText>
        </MenuItem>
      </Menu>
      <AddInvoicesToPurchaseOrdersDialog
        open={openExternalCession}
        setOpen={toggleExternalCession}
        purchaseOrders={purchaseOrders}
        companyRut={companyRut}
      />
      <TransferBankDialog
        open={openTransfer}
        toggleOpen={toggleTransfer}
        purchaseOrders={purchaseOrders}
      />
    </>
  );
};

OrderingOperationActions.propTypes = {
  purchaseOrders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      totalAmount: PropTypes.number,
    }),
  ).isRequired, // eslint-disable-line react/forbid-prop-types
  companyRut: PropTypes.number.isRequired,
};

export default OrderingOperationActions;
