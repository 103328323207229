import React, { useCallback, useState } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { orderingSelectedQuery } from '@fingo/lib/apollo/cache/localQuery';
import { GET_USER } from '@fingo/lib/graphql';
import BaseShoppingCart from '../BaseShoppingCart';
import SentToEvaluationCart from '../SentToEvaluationCart';
import OrderingShoppingStepOne from './OrderingShoppingCartStepOne';
import OrderingSiiCredentials from './OrderingSiiCredentials';

const OrderingShoppingCart = ({ toggleMenu }) => {
  const [step, setStep] = useState(0);
  const nextStep = () => setStep((prev) => prev + 1);
  const { data: { selectedOrderingIds } } = useQuery(orderingSelectedQuery);
  const { data: { getUser: { selectedCompany, hasDummyPassword } } } = useQuery(GET_USER);
  const showSiiScreen = useCallback(() => {
    if (!selectedCompany.hasSiiCredentials) return true;
    if (hasDummyPassword) return true;
    return false;
  }, [selectedCompany, hasDummyPassword]);
  const [showSii] = useState(showSiiScreen());
  const siiScreen = showSii ? [<OrderingSiiCredentials nextStep={nextStep} />] : [];
  const stepElements = [
    ...siiScreen,
    <OrderingShoppingStepOne nextStep={nextStep} />,
    <SentToEvaluationCart nextStep={toggleMenu} />,
  ];
  return (
    <BaseShoppingCart
      stepElements={stepElements}
      title="Ordering"
      id="cart-ordering-header"
      selected={selectedOrderingIds.length}
      step={step}
    />
  );
};

OrderingShoppingCart.propTypes = {
  toggleMenu: PropTypes.func.isRequired,
};

export default OrderingShoppingCart;
