import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import useSelectedCompany from '@fingo/lib/hooks/useSelectedCompany';
import useBooleanState from '@fingo/lib/hooks/useBooleanState';
import { formatDateTime } from '@fingo/lib/helpers/date';
import SiiCredential from '../../components/dialogs/SiiCredential';

const STORAGE_VAR = 'SII_SHOWN';

const PopUpAlertProvider = ({ children }) => {
  const company = useSelectedCompany();
  const { pathname } = useLocation();
  const [open, , setTrue, _setFalse] = useBooleanState(false);
  const getLastTime = useCallback(() => {
    const lastShown = localStorage.getItem(STORAGE_VAR);
    return moment(lastShown);
  }, []);
  const setLastTime = useCallback((lastShown) => {
    localStorage.setItem(STORAGE_VAR, formatDateTime(lastShown));
  }, []);
  const setFalse = useCallback(() => {
    setLastTime(moment());
    _setFalse();
  }, []);
  useEffect(() => {
    const notShowPaths = [
      '/',
      '/app',
      '/app/home',
    ];
    const lastTime = getLastTime();
    if (moment().diff(lastTime, 'hours') < 3) return;
    if (notShowPaths.includes(pathname)) return;
    if (!company) return;
    if (!company.hasSiiCredentials) setTrue();
  }, [company, pathname]);
  return (
    <>
      <SiiCredential open={open} handleClose={setFalse} />
      {children}
    </>
  );
};

PopUpAlertProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PopUpAlertProvider;
