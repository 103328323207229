import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { offerDateToPaySelectedQuery, offerSelectedQuery } from '@fingo/lib/apollo/cache/localQuery';
import { cleanOfferShoppingCart } from '@fingo/lib/apollo/reactive-variables/localUpdates';
import { OFFER_SHOPPING_CART } from '@fingo/lib/graphql';
import { formatYearMonthDay } from '@fingo/lib/helpers';
import BaseSummaryCart from '../BaseSummaryCart';

const OfferShoppingStepOne = ({ nextStep }) => {
  const { data: { selectedOfferIds } } = useQuery(offerSelectedQuery);
  const { data: { selectedOfferDateToPay } } = useQuery(offerDateToPaySelectedQuery);
  const offerJoined = selectedOfferIds.map((invoiceId) => ({
    invoiceId,
    dateToPay: selectedOfferDateToPay[invoiceId]
      ? formatYearMonthDay(selectedOfferDateToPay[invoiceId]) : null,
  }));
  const { data: shoppingCartData, loading: loadingCart } = useQuery(
    OFFER_SHOPPING_CART,
    { variables: { offerSelected: offerJoined } },
  );
  const disabledTitleFunction = () => {
    if (selectedOfferIds.length === 0) {
      return 'Seleccione ofertas en el panel para avanzar';
    }
    if (offerJoined.filter((off) => off.dateToPay === null).length > 0) {
      return 'Seleccione todas las fechas de pago de las ofertas elegidas';
    }
    return '';
  };
  const disabledTitle = disabledTitleFunction();
  const summaryCart = [
    { title: 'Cantidad de facturas', value: shoppingCartData?.offerShoppingCart.invoiceCount, color: 'text.primary', money: false },
    { title: 'Monto total', value: shoppingCartData?.offerShoppingCart.totalAmount, color: 'text.primary', money: true },
    { title: 'Diferencia de precio', value: shoppingCartData?.offerShoppingCart.priceDifference, color: 'text.primary', money: true },
    { title: 'Comisión', value: shoppingCartData?.offerShoppingCart.commission, color: 'text.primary', money: true },
    { title: 'Costos totales', value: shoppingCartData?.offerShoppingCart.totalCosts, color: 'text.primary', money: true },
    { title: 'Monto retenido', value: shoppingCartData?.offerShoppingCart.retainedAmount, color: 'text.primary', money: true },
    { title: 'Monto a depositar', value: shoppingCartData?.offerShoppingCart.depositAmount, color: 'primary.main', money: true },
  ];
  return (
    <BaseSummaryCart
      cartType="offer"
      summaryCart={summaryCart}
      cleanFunction={cleanOfferShoppingCart}
      principalActionFunction={nextStep}
      disabledPrincipalAction={!!disabledTitle.length}
      loadingButton={false}
      loading={loadingCart}
      tooltipDisabledTitle={disabledTitle}
      principalActionTitle="Ceder y girar"
      principalActionId="cart-offer-cede-and-operate"
    />
  );
};

OfferShoppingStepOne.propTypes = {
  nextStep: PropTypes.func.isRequired,
};

export default OfferShoppingStepOne;
