import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { PasswordInput } from '@fingo/lib/components/inputs';
import { Button, FormControl, Grid, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useCreateOperation from '../../../hooks/useCreateOperation';
import SelectBankAccount from '../../factoring/SelectBankAccount';

const FingoOfferShoppingCartStepOne = ({ prevStep, nextStep }) => {
  const [bankId, setBankId] = useState('');
  const [certificatePassword, setCertificatePassword] = useState('');
  const [createOperation, { loading }] = useCreateOperation(
    false,
    bankId,
    certificatePassword,
    nextStep,
  );
  return (
    <Grid container rowSpacing={2}>
      <Grid item xs={12}>
        <Typography variant="h2" sx={{ width: '100%' }} align="center">
          ¡Quiero que Fingo lo haga por mi!
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <SelectBankAccount setBankId={setBankId} bankId={bankId} />
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth margin="dense">
          <Typography variant="h2">Ingresa la clave de tu certificado digital</Typography>
          <PasswordInput
            fullWidth
            value={certificatePassword}
            onChange={(event) => setCertificatePassword(event.target.value)}
            sx={{ mt: 1 }}
          />
        </FormControl>
      </Grid>
      <Grid item container xs={12} justifyContent="space-between">
        <Button
          variant="link"
          color="primary"
          size="small"
          onClick={prevStep}
        >
          Atrás
        </Button>
        <LoadingButton
          variant="contained"
          color="primary"
          size="small"
          onClick={() => createOperation()}
          disabled={(bankId === '' || certificatePassword === '')}
          loading={loading}
          id="cart-offer-fingo-confirm"
        >
          ¡Operar con Fingo!
        </LoadingButton>
      </Grid>
    </Grid>
  );
};

FingoOfferShoppingCartStepOne.propTypes = {
  prevStep: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
};

export default FingoOfferShoppingCartStepOne;
