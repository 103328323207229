import React, { useCallback } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useApolloClient } from '@apollo/client';
import { logOut } from '@fingo/lib/apollo/reactive-variables/localUpdates';
import { Collapse, IconButton, List, ListItemButton, ListItemIcon, ListItemText, Stack } from '@mui/material';
import { Logout, Remove } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  logo: {
    width: 90,
  },
  activeStyle: {
    '& .MuiTypography-root': {
      fontWeight: 700,
    },
  },
}));

const LoggedInMobileNavItems = ({ closeDrawer, productLinks }) => {
  const client = useApolloClient();
  const handleLogout = useCallback(() => {
    logOut(client);
    closeDrawer();
  }, [client, closeDrawer]);
  const classes = useStyles();
  const routes = [
    ...productLinks,
    {
      id: 'nav-profile',
      label: 'Mi perfil',
      path: '/app/profile',
      subRoutes: [
        { id: 'profile-credentials', label: 'Mis credenciales', path: 'credentials' },
        { id: 'profile-info', label: 'Mi información', path: 'info' },
        { id: 'profile-settings', label: 'Configuraciones', path: 'settings' },
        { id: 'profile-terms', label: 'TyC', path: 'terms' },
      ],
    },
  ];
  const location = useLocation();
  const isCollapsed = useCallback((link) => {
    if (location.pathname.includes(link)) {
      return true;
    }
    return false;
  }, [location]);
  const isSubRouteActive = useCallback((subRoute, index) => {
    if (location.pathname.includes(subRoute)) {
      return true;
    }
    if (subRoute.includes(location.pathname) && index === 0) {
      return true;
    }
    return false;
  }, [location]);
  return (
    <>
      <List>
        {routes.map(({ id, label, path, subRoutes }) => (
          <React.Fragment key={label}>
            <ListItemButton
              component={NavLink}
              to={path}
              activeClassName={classes.activeStyle}
              id={`mobile-${id}`}
            >
              <ListItemIcon sx={{ minWidth: 0, mr: 1 }}>
                <Remove color="fingoWhite" />
              </ListItemIcon>
              <ListItemText
                primary={label}
                primaryTypographyProps={{
                  color: 'white',
                  variant: 'h6',
                }}
              />
            </ListItemButton>
            {subRoutes.length > 0 && (
            <Collapse in={isCollapsed(path)}>
              <List>
                {subRoutes.map(({ id: subId, label: subLabel, path: subPath }, index) => (
                  <ListItemButton
                    component={NavLink}
                    to={`${path}/${subPath}`}
                    activeClassName={classes.activeStyle}
                    key={subLabel}
                    isActive={() => isSubRouteActive(`${path}/${subPath}`, index)}
                    onClick={closeDrawer}
                    id={`mobile-${subId}`}
                  >
                    <ListItemIcon sx={{ minWidth: 0, mr: 1 }}>
                      <Remove color="fingoWhite" sx={{ opacity: 0 }} />
                    </ListItemIcon>
                    <ListItemText
                      primary={subLabel}
                      primaryTypographyProps={{
                        color: 'white',
                        variant: 'body1',
                        fontWeight: '300',
                      }}
                    />
                  </ListItemButton>
                ))}
              </List>
            </Collapse>
            )}
          </React.Fragment>
        ))}
      </List>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
        mt="auto"
        px={2}
      >
        <IconButton onClick={handleLogout}>
          <Logout color="fingoWhite" fontSize="large" />
        </IconButton>
      </Stack>
    </>
  );
};

LoggedInMobileNavItems.propTypes = {
  closeDrawer: PropTypes.func.isRequired,
  productLinks: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      path: PropTypes.string,
      shouldShow: PropTypes.func,
      action: PropTypes.string,
    }),
  ).isRequired,
};

export default LoggedInMobileNavItems;
