import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Paper, TableContainer, Table, TablePagination } from '@mui/material';
import FingoTableHead from './FingoTableHead';
import FingoTableBody from './FingoTableBody';

const FingoTable = (props) => {
  const {
    headers,
    rows,
    setRows,
    loading,
    orderBy,
    setOrderBy,
    selectable,
    collapsible,
    paginable,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    rowCount,
    dense,
  } = props;
  const selectedRowsCount = useMemo(() => rows.filter((row) => row.selected).length, [rows]);
  const selectableRowsCount = useMemo(
    () => rows.filter((row) => row.selectable && !row.selectDisabled).length,
    [rows],
  );

  return (
    <Paper sx={{ width: '100%', mb: 2 }}>
      <TableContainer component={Paper}>
        <Table aria-label="Fingo table" size={dense ? 'small' : 'medium'}>
          <FingoTableHead
            headers={headers}
            setRows={setRows}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            selectable={selectable}
            selectedRowsCount={selectedRowsCount}
            selectableRowsCount={selectableRowsCount}
            collapsible={collapsible}
          />
          <FingoTableBody
            headers={headers}
            rows={rows}
            loading={loading}
            setRows={setRows}
            selectable={selectable}
            collapsible={collapsible}
            page={page}
            rowsPerPage={rowsPerPage}
          />
        </Table>
      </TableContainer>
      {!loading && paginable && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rowCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(e, newPage) => { setPage(newPage); }}
          onRowsPerPageChange={(e) => { setRowsPerPage(parseInt(e.target.value, 10)); setPage(0); }}
        />
      )}
    </Paper>
  );
};

FingoTable.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      component: PropTypes.func.isRequired,
      label: PropTypes.string,
      sortable: PropTypes.bool,
    }),
  ).isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      selectable: PropTypes.bool,
      selected: PropTypes.bool,
      selectDisabled: PropTypes.bool,
      collapsible: PropTypes.bool,
      open: PropTypes.bool,
      collapseComponent: PropTypes.element,
    }),
  ).isRequired,
  setRows: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  orderBy: PropTypes.string.isRequired,
  setOrderBy: PropTypes.func.isRequired,
  selectable: PropTypes.bool,
  collapsible: PropTypes.bool,
  paginable: PropTypes.bool,
  page: PropTypes.number,
  setPage: PropTypes.func,
  rowsPerPage: PropTypes.number,
  setRowsPerPage: PropTypes.func,
  rowCount: PropTypes.number,
  dense: PropTypes.bool,
};

FingoTable.defaultProps = {
  selectable: false,
  collapsible: false,
  paginable: false,
  page: 0,
  setPage: () => {},
  rowsPerPage: 0,
  setRowsPerPage: () => {},
  rowCount: 0,
  dense: true,
};

export default FingoTable;
