import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  IconButton,
  Typography,
} from '@mui/material';
import { Close, CloudUpload } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useMutation } from '@apollo/client';
import UploadContainer from '../utils/UploadContainer';

const UploadDropzone = ({
  open,
  setOpen,
  file,
  setFile,
  message,
  UPLOAD_MUTATION,
  configureSnackBar,
  downloadTemplate,

}) => {
  const [uploadMutation, { loading: loadingMutation, error: errorMutation }] = useMutation(
    UPLOAD_MUTATION,
    {
      onCompleted: (data) => {
        setOpen(false);
        setFile({ name: '' });
        configureSnackBar(data.uploadContactsExcel.type, data.uploadContactsExcel.message);
      },
      onError: () => {},
    },
  );
  const [error, setError] = useState(errorMutation);
  useEffect(() => {
    setError(errorMutation && errorMutation.message);
  }, [errorMutation]);
  const onClose = () => { setOpen(false); setFile({ name: '' }); setError(''); };
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle
        sx={{ background: 'primary' }}
      >
        Carga de Documentos
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'white',
            }}
          >
            <Close />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent sx={{ width: '440px', height: '320px', paddingBottom: 0 }}>
        <Typography paddingY="18px" variant="caption" component="div">{message}</Typography>
        <UploadContainer
          file={file}
          setFile={setFile}
          errorMutation={error}
          setErrorMutation={setError}
        />
      </DialogContent>
      <DialogActions sx={{ flexDirection: 'column' }}>
        {file.size
          ? (
            <LoadingButton
              variant="contained"
              onClick={() => uploadMutation({
                variables: { inputUploadFiles: file, companyId: 76003885 },
              })}
              loading={loadingMutation}
              loadingPosition="start"
              startIcon={<CloudUpload />}
            >Subir carga masiva
            </LoadingButton>
          )
          : (
            <Button onClick={() => { downloadTemplate(); }}>
              Descarga la plantilla en .XLSX
            </Button>
          )}
      </DialogActions>
    </Dialog>
  );
};

UploadDropzone.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  file: PropTypes.object.isRequired,
  setFile: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  UPLOAD_MUTATION: PropTypes.object.isRequired,
  configureSnackBar: PropTypes.func.isRequired,
  downloadTemplate: PropTypes.func.isRequired,
};

export default UploadDropzone;
