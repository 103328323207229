import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { FingoNavbar } from '@fingo/lib/components/layout';
import { GET_USER } from '@fingo/lib/graphql';
import { FingoLogo } from '@fingo/lib/assets';
import { useBooleanState, useIsLogged } from '@fingo/lib/hooks';
import {
  AppBar,
  Box,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Toolbar,
} from '@mui/material';
import { Close, Menu, Remove } from '@mui/icons-material';
import { makeStyles, styled } from '@mui/styles';
import LoggedInMobileNavItems from './LoggedInMobileNavItems';
import SelectCompanyNavItem from './SelectCompanyNavItem';
import ShoppingCart from '../../shopping/shoppingCart';
import useFingoRoutes from '../../../hooks/useFingoRoutes';

const useStyles = makeStyles(() => ({
  logo: {
    width: 90,
  },
  activeStyle: {
    '& .MuiTypography-root': {
      fontWeight: 700,
    },
  },
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const MobileNavbar = () => {
  const appLinks = useFingoRoutes();
  const { data: userData } = useQuery(GET_USER);
  const isLogged = useIsLogged();
  const [drawerOpen, toggleDrawer] = useBooleanState(false);
  const classes = useStyles();
  return (
    <>
      <FingoNavbar>
        <Stack direction="row" width="100%" height="100%" alignItems="center">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            edge="start"
            id="mobile-nav-toggle"
          >
            <Menu color="primary" />
          </IconButton>
          {userData?.getUser?.selectedCompany && (
            <SelectCompanyNavItem ml={2} />
          )}
          <Box component={Link} to="/" sx={{ ml: 'auto' }}>
            <img src={FingoLogo} alt="logo" className={classes.logo} />
          </Box>
        </Stack>
      </FingoNavbar>
      <Drawer
        variant="temporary"
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer}
        sx={{
          '& .MuiPaper-root': {
            minWidth: 280,
            bgcolor: 'text.main',
            pb: 4,
          },
        }}
      >
        <DrawerHeader>
          <IconButton onClick={toggleDrawer} id="mobile-nav-close">
            <Close color="fingoWhite" />
          </IconButton>
        </DrawerHeader>
        {isLogged && userData?.getUser && (
          <LoggedInMobileNavItems closeDrawer={toggleDrawer} productLinks={appLinks} />
        )}
        {!isLogged && (
          <List>
            <ListItemButton
              component={NavLink}
              to="/login"
              activeClassName={classes.activeStyle}
              id="mobile-nav-login"
            >
              <ListItemIcon sx={{ minWidth: 0, mr: 1 }}>
                <Remove color="fingoWhite" />
              </ListItemIcon>
              <ListItemText
                primary="Ingresar"
                primaryTypographyProps={{
                  color: 'white',
                  variant: 'h6',
                }}
              />
            </ListItemButton>
            <ListItemButton
              component={NavLink}
              to="/register"
              activeClassName={classes.activeStyle}
            >
              <ListItemIcon sx={{ minWidth: 0, mr: 1 }}>
                <Remove color="fingoWhite" />
              </ListItemIcon>
              <ListItemText
                primary="Registrarse"
                primaryTypographyProps={{
                  color: 'white',
                  variant: 'h6',
                }}
              />
            </ListItemButton>
          </List>
        )}
      </Drawer>
      {isLogged && userData?.getUser && (
        <AppBar elevation={0} position="fixed" color="transparent" sx={{ top: 'auto', bottom: 0 }}>
          <Toolbar>
            <Stack
              width="100%"
              direction="row"
              spacing={2}
              alignItems="center"
              justifyContent="center"
            >
              <ShoppingCart />
            </Stack>
          </Toolbar>
        </AppBar>
      )}
    </>
  );
};

export default MobileNavbar;
