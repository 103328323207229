import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { FingoDivider } from '@fingo/lib/components/dividers';
import { PaperHeader } from '@fingo/lib/components/headers';
import { FingoPaper } from '@fingo/lib/components/layout';
import { GET_USER } from '@fingo/lib/graphql';
import { Grid, Typography } from '@mui/material';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import UploadSiiCredentialsForm from '@fingo/lib/components/forms/UploadSiiCredentialsForm';
import UploadCertificateForm from '../../../components/credentials/UploadCertificateForm';
import UploadBankInfo from '../../../components/credentials/UploadBankInfo';
import FingoDialog from '../../../components/dialogs/FingoDialog';
import CredentialProvider from '../../../components/credentials/CredentialProvider';

const DocumentGrid = ({ id, title, subtitle, dialogComponent, isOk }) => {
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen((prev) => !prev);
  return (
    <>
      <Grid
        container
        item
        xs={6}
        sx={{ p: 4, cursor: 'pointer', '&:hover': { boxShadow: 1, borderRadius: 2 } }}
        onClick={toggle}
        id={id}
      >
        <Grid container item alignItems="center" justifyContent="space-between">
          <Grid item display="flex" alignItems="center">
            <Typography variant="h6" fontWeight={700}>{title}</Typography>
            <FileUploadOutlinedIcon fontSize="medium" sx={{ ml: 1 }} />
          </Grid>
          {isOk ? <TaskAltIcon fontSize="medium" color="success" /> : <CancelOutlinedIcon fontSize="medium" color="error" />}
        </Grid>
        <Grid container item sx={{ mt: 2 }}>
          <Typography>{subtitle}</Typography>
        </Grid>
      </Grid>
      <FingoDialog
        open={open}
        handleClose={toggle}
        maxWidth="sm"
        fullWidth
        title={title}
      >
        {dialogComponent}
      </FingoDialog>
    </>
  );
};

DocumentGrid.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  dialogComponent: PropTypes.element.isRequired,
  isOk: PropTypes.bool.isRequired,
};

const Credentials = () => {
  const { data: userData } = useQuery(GET_USER);
  const documents = [
    {
      id: 'credentials-sii',
      title: 'Credenciales SII',
      subtitle: 'Sube tus credenciales del SII en nuestra plataforma para que tengas la experiencia Fingo.',
      isOk: userData?.getUser?.selectedCompany?.hasSiiCredentials,
      dialogComponent: <UploadSiiCredentialsForm />,
    },
    {
      id: 'credentials-digital-certificate',
      title: 'Certificado digital',
      subtitle: 'Sube tu certificado digital y así verificaremos tu factura.',
      isOk: userData?.getUser?.selectedCompany?.hasDigitalCertificate,
      dialogComponent: <UploadCertificateForm />,
    },
    {
      id: 'credentials-invoice-provider',
      title: 'Credenciales del facturador',
      subtitle: 'Sube tus credenciales del facturador para que podamos evaluar tu factura.',
      isOk: userData?.getUser?.selectedCompany?.hasInvoiceProviderCredentials,
      dialogComponent: <CredentialProvider viewType="electronic_invoices" />,
    },
    {
      id: 'credentials-supplier-portal',
      title: 'Portal de proveedores',
      subtitle: 'Sube las credenciales de tus portales para obtener el estado de pago de tus facturas.',
      dialogComponent: <CredentialProvider viewType="supplier_platform" />,
      isOk: userData?.getUser?.selectedCompany?.hasSupplierPortalCredentials,
    },
    {
      id: 'credentials-bank-account',
      title: 'Cuentas bancarias',
      subtitle: 'Ingresa tus datos bancarios para que podamos depositarte.',
      isOk: userData?.getUser?.selectedCompany?.hasBankAccount,
      dialogComponent: <UploadBankInfo />,
    },
  ];
  return (
    <Grid container rowSpacing={4} sx={{ mt: 0 }} justifyContent="space-between">
      <PaperHeader viewTitle="Mis credenciales" />
      <FingoDivider />
      <Grid container sx={{ py: 2 }}>
        <Typography>
          Acelera el proceso de cesión completando
          los documentos solicitados para mejorar la experiencia Fingo
        </Typography>
      </Grid>
      {documents.map((document) => <DocumentGrid key={document.title} {...document} />)}
    </Grid>
  );
};

export default () => (
  <FingoPaper menuOptions={[
    {
      label: 'Credenciales',
      path: 'credentials',
      component: <Credentials />,
    },
  ]}
  />
);
