import React from 'react';
import { useQuery } from '@apollo/client';
import { preofferSelectedQuery } from '@fingo/lib/apollo/cache/localQuery';
import { preofferSelected } from '@fingo/lib/apollo/reactive-variables';
import { DOCUMENTS } from '@fingo/lib/graphql';
import { DocumentList } from '@fingo/lib/components/lists';
import { useInvoicePreColumns } from '@fingo/lib/constants';
import useNewInvoiceCharged from '../../../../hooks/useNewInvoiceCharged';

const AvailableFactoring = () => {
  const { data: { selectedPreofferIds } } = useQuery(preofferSelectedQuery);
  const [newInvoices, readNewInvoices] = useNewInvoiceCharged();
  return (
    <DocumentList
      trackerId="PREOFFERS"
      type="available-factoring"
      headerTitle="Anticipa facturas"
      queryDocument={DOCUMENTS}
      showFilters
      checkboxSelection
      shoppingCartPreview
      newData={newInvoices}
      readData={readNewInvoices}
      isRowSelectable={({ row }) => row.preofferSelectable.selectable}
      emitter
      includeHeaders={[
        'folio',
        'dteType_Code',
        'dateIssued',
        'receiver_Name',
        'amountWithIva',
        'monthlyRate',
        'defaultRate',
        'factoringStatus',
        'uploadDocuments',
      ]}
      mobileHeaders={[
        'folio',
        'receiver_Name',
        'monthlyRate',
        'defaultRate',
        'amountWithIva',
      ]}
      financingStatusOptions
      initialFilterValue={{
        documentType: [],
        creditNoteStatus: [],
        siiStatus: [],
        documentFinanceStatus: [],
        availableForFinancing: 'AVAILABLE',
      }}
      onSelectionModelChange={(ids) => preofferSelected(ids)}
      selectionModel={selectedPreofferIds}
      keepNonExistentRowsSelected
      preColumns={useInvoicePreColumns()}
    />
  );
};

export default AvailableFactoring;
