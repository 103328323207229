export const HEADERS = [
  'folio',
  'dteType_Code',
  'dateIssued',
  'receiver_Name',
  'amountWithIva',
  'siiStatus',
  'chat',
];
export const MOBILE_HEADERS = [
  'folio',
  'receiver_Name',
  'amountWithIva',
  'siiStatus',
];
