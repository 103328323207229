import { useMutation, useQuery } from '@apollo/client';
import { orderingOfferSelection } from '@fingo/lib/apollo/cache/localQuery';
import { cleanOrderingOfferShoppingCart } from '@fingo/lib/apollo/reactive-variables/localUpdates';
import { ORDERING_PURCHASE_ORDERS, PURCHASE_ORDERS_CONFIRM_OFFER } from '@fingo/lib/graphql';

const useConfirmOrdering = (selectedAccountId, onCompleted, onError) => {
  const { data: { selectedOfferOrderingIds } } = useQuery(orderingOfferSelection);
  const result = useMutation(
    PURCHASE_ORDERS_CONFIRM_OFFER,
    {
      variables: {
        bankAccountId: selectedAccountId,
        purchaseOrders: selectedOfferOrderingIds,
      },
      refetchQueries: [ORDERING_PURCHASE_ORDERS],
      awaitRefetchQueries: true,
      onCompleted: () => {
        onCompleted();
        cleanOrderingOfferShoppingCart();
      },
      onError,
    },
  );
  return result;
};

export default useConfirmOrdering;
