import React, { useEffect } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { logIn } from '@fingo/lib/apollo/reactive-variables/localUpdates';
import { GET_USER, ONBOARDING_AUTH } from '@fingo/lib/graphql';
import { useIsLogged } from '@fingo/lib/hooks';

const OnboardingAuth = () => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const isLogged = useIsLogged();
  const { refetch: refetchUser } = useQuery(
    GET_USER,
    { fetchPolicy: 'network-only' },
  );
  const [onboardingAuth] = useMutation(
    ONBOARDING_AUTH,
    {
      onCompleted: ({ onboardingAuth: auth }) => {
        logIn(auth);
        refetchUser();
      },
    },
  );
  useEffect(() => {
    onboardingAuth({ variables: { activationCode: urlParams.get('activation_code') } });
  }, []);
  return (
    <>
      {isLogged ? (
        <Redirect to={{
          pathname: '/app/sales',
          search: `?company_id=${urlParams.get('company_id')}`,
          state: { referrer: 'supplier-onboarding' },
        }}
        />
      ) : (<></>)}
    </>
  );
};

export default OnboardingAuth;
