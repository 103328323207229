import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { preofferSelectedQuery } from '@fingo/lib/apollo/cache/localQuery';
import { cleanPreOfferShoppingCart } from '@fingo/lib/apollo/reactive-variables/localUpdates';
import { PREOFFER_SHOPPING_CART } from '@fingo/lib/graphql';
import BaseSummaryCart from '../BaseSummaryCart';

const PreofferShoppingStepOne = ({ nextStep }) => {
  const { data: { selectedPreofferIds } } = useQuery(preofferSelectedQuery);
  const { data: shoppingCartData, loading: loadingCart } = useQuery(
    PREOFFER_SHOPPING_CART,
    { variables: { invoicesId: selectedPreofferIds } },
  );
  const summaryCart = [
    { title: 'Cantidad de facturas', value: shoppingCartData?.preofferShoppingCart.invoiceCount, color: 'text.primary', money: false },
    { title: 'Monto total', value: shoppingCartData?.preofferShoppingCart.totalAmount, color: 'text.primary', money: true },
    { title: 'Diferencia de precio', value: shoppingCartData?.preofferShoppingCart.priceDifference, color: 'text.primary', money: true },
    { title: 'Comisión', value: shoppingCartData?.preofferShoppingCart.commission, color: 'text.primary', money: true },
    { title: 'Costos totales', value: shoppingCartData?.preofferShoppingCart.totalCosts, color: 'text.primary', money: true },
    { title: 'Monto retenido', value: shoppingCartData?.preofferShoppingCart.retainedAmount, color: 'text.primary', money: true },
    { title: 'Monto a depositar', value: shoppingCartData?.preofferShoppingCart.depositAmount, color: 'primary.main', money: true },
  ];
  return (
    <BaseSummaryCart
      cartType="preoffer"
      summaryCart={summaryCart}
      cleanFunction={cleanPreOfferShoppingCart}
      principalActionFunction={nextStep}
      disabledPrincipalAction={selectedPreofferIds.length === 0}
      loadingButton={false}
      loading={loadingCart}
      principalActionTitle="Evaluar"
      principalActionId="cart-preoffer-evaluate"
    />
  );
};

PreofferShoppingStepOne.propTypes = {
  nextStep: PropTypes.func.isRequired,
};

export default PreofferShoppingStepOne;
