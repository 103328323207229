import { Button, Stack, Typography } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

const InvoiceProviderCredentialMoreInfo = ({ close }) => (
  <Stack spacing={2} alignItems="center" maxWidth={400} ml="auto" mr="auto">
    <>
      <Typography align="justify" variant="h6">
        Las claves de tu facturador sirven para que puedas realizar tus
        cesiones por dentro de la plataforma, sin necesidad de salir de Fingo.
      </Typography>
      <Typography align="justify" variant="h6">
        Te recomendamos ingresar tus claves para obtener una
        experiencia completa de nuestro sitio web.
      </Typography>
    </>
    <Button
      variant="contained"
      size="small"
      onClick={close}
      sx={{
        maxWidth: 'fit-content',
        mr: 'auto',
        ml: 'auto',
      }}
    >
      Ingresar credenciales
    </Button>
  </Stack>
);

InvoiceProviderCredentialMoreInfo.propTypes = {
  close: PropTypes.func.isRequired,
};

export default InvoiceProviderCredentialMoreInfo;
