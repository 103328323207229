import React from 'react';
import { useQuery } from '@apollo/client';
import { FingoDivider } from '@fingo/lib/components/dividers';
import { PaperHeader } from '@fingo/lib/components/headers';
import { FingoPaper } from '@fingo/lib/components/layout';
import { GET_TERMS } from '@fingo/lib/graphql';
import { Grid, Typography } from '@mui/material';

const Terms = () => {
  const { data } = useQuery(GET_TERMS);
  return (
    <Grid container rowSpacing={4} sx={{ mt: 0 }} justifyContent="space-between">
      <PaperHeader viewTitle="Términos y condiciones" />
      <FingoDivider />
      {data?.getTermsAndConditions.map(({ id, text, sectionType }) => (
        <Typography
          paragraph
          sx={{ width: '100%', mt: sectionType === 'subtitle' ? 2 : 'inherit' }}
          align="justify"
          key={id}
        >{text}
        </Typography>
      ))}
    </Grid>
  );
};

export default () => (
  <FingoPaper menuOptions={[
    {
      label: 'Términos',
      path: 'terms',
      component: <Terms />,
    },
  ]}
  />
);
